import React from 'react';
import CompanyProcessStatus from './CompanyProcessStatus';

import { FinProductContext } from '../../contexts/FinProductContext';
import TabsForReviewingCompany from './TabsForReviewingCompany';
import ApplicationQuestions from './ApplicationQuestions';
import NextStageOptions from './NextStageOptions/NextStageOptions';
import PrivateNotes from './PrivateNotes';
import { ConfigContext } from '../../contexts/ConfigContext';

export default function InboxReader() {
  const { config } = React.useContext(ConfigContext);
  const { selectedProductApplication, isLoadingApplication } =
    React.useContext(FinProductContext);
  const { company } = selectedProductApplication ?? {};

  return (
    <div className="bg-white rounded flex flex-row  ">
      <div
        className={
          ' m-4 h-[80vh] overflow-scroll no-scrollbar' +
          (config?.features?.rightHandSideTab?.visible ? ' w-[66%]' : ' w-full')
        }
      >
        {/* Header */}
        <div className="text-sm text-center items-center flex ">
          {isLoadingApplication ? (
            // a animating bg loading state
            <div
              className={
                'rounded-full text-stone-50 px-6 w-[150px] h-[25px] mx-4 animate-pulse  bg-stone-200 opacity-20'
              }
            ></div>
          ) : (
            <div className="rounded-full bg-stone-400 text-stone-50 px-6 max-h-[25px]">
              {selectedProductApplication?.name}
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="text-left ">
            {isLoadingApplication ? (
              <div className="h-[60px] w-[100px] animate-pulse  bg-stone-200 opacity-20 m-4 rounded"></div>
            ) : company?.logo.length ? (
              <div className="mr-8 mb-4">
                <img
                  key={company?.logo}
                  src={company?.logo}
                  className=" rounded  drop-shadow object-contain"
                  style={{ width: '100px', height: '60px' }}
                  onError={e => {
                    e.currentTarget.hidden = true;
                  }}
                />
              </div>
            ) : (
              <div className="mb-4"></div>
            )}
            {/* <div className="font-light text-sm">{company_location}</div> */}
            {isLoadingApplication ? (
              <div className="h-[20px] w-[100px] animate-pulse  bg-stone-200 opacity-20 m-4 rounded"></div>
            ) : (
              <div className="font-extrabold text-xl ">{company?.name}</div>
            )}
          </div>
          <CompanyProcessStatus />
        </div>

        {/* partner private notes  - a text area for private notes*/}
        {isLoadingApplication ? (
          <div className=" h-[100px] animate-pulse  bg-stone-200 opacity-20 m-4 rounded"></div>
        ) : (
          <PrivateNotes />
        )}

        {/* divider line */}
        <div className="border border-solid border-stone-100 my-2 mt-4" />

        {/* Application questions */}
        <div className="text-left">
          {isLoadingApplication ? (
            [1, 2, 3, 4, 5].map(i => (
              <div
                key={i}
                className="h-[50px] animate-pulse  bg-stone-200 opacity-20 m-4 rounded"
              ></div>
            ))
          ) : (
            <ApplicationQuestions />
          )}
        </div>

        {/* Move the application to the next stage? */}
        {config?.features?.demoNextSteps?.visible && <NextStageOptions />}
      </div>
      {config?.features?.rightHandSideTab?.visible && (
        <TabsForReviewingCompany />
      )}
    </div>
  );
}
