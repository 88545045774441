import React from 'react';
import { question } from '../../../contexts/FinProductContext';
import { MarkdownText } from '../../utils/MarkdownText';

interface Props {
  question: question;
}

export default function LongQuestion(props: Props) {
  const { question } = props;
  return (
    <div className="">
      <div className="p-1 text-sm">
        <MarkdownText content={question.question} />
      </div>
      <div className="relative bg-stone-100 border border-1 border-solid border-stone-300 text-stone-900 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block w-full">
        <div className="block w-full p-2.5 ">{question.answer}</div>
      </div>
    </div>
  );
}
