import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import SmallMenuCard from './SmallMenuCard';
import { ConfigContext } from '../../contexts/ConfigContext';
import { routes } from '../../pages/routes';

export default function Hello() {
  const { user } = useAuth0();
  const { config } = React.useContext(ConfigContext);
  return (
    <div className="max-w-[900px] flex space-x-4 w-full h-full py-4">
      {/* LHS */}
      <div className="flex-1 flex flex-col justify-between rounded-lg bg-white shadow px-8 py-6 w-2/3">
        <div className="w-fit mr-auto">
          <img
            src={config?.customisations?.logo || '/vula-sun.png'}
            alt="logo"
            className="h-10"
          />
        </div>
        <div className="text-left text-xl sm:text-3xl pt-4">
          Hello {user?.name || ''}
        </div>
      </div>

      {/* RHS - Example Content */}
      {(config?.features?.applicationManagement?.visible ||
        config?.features?.applicationsOverview?.visible ||
        config?.features?.navigator?.visible) && (
        <div className="flex-1 flex flex-col space-y-2  justify-between rounded-lg bg-white shadow p-4 w-1/3 max-w-[250px]">
          <div className="mr-auto text-xs pb-1 ">Quick menu</div>
          {config?.features?.applicationManagement?.visible && (
            <SmallMenuCard
              headline="Kanban"
              onClick={() => {
                window.location.href = routes.application_management;
              }}
              icon="📊"
            />
          )}
          {config?.features?.applicationsOverview?.visible && (
            <SmallMenuCard
              headline="Performance"
              onClick={() => {
                window.location.href = routes.application_overview;
              }}
              icon="📈"
            />
          )}
          {config?.features?.navigator?.visible && (
            <SmallMenuCard
              headline="Navigator"
              onClick={() => {
                window.location.href = routes.search;
              }}
              icon="🔍"
            />
          )}
        </div>
      )}
    </div>
  );
}
