import React, { useEffect, useState } from 'react';
import { ResponsiveSankey } from '@nivo/sankey';

export default function Sankey() {
  const [programmeFilter, setProgrammeFilter] = useState('all');
  const [teamFilter, setTeamFilter] = useState('all');
  const [individualFilter, setIndividualFilter] = useState('all');

  const data = {
    nodes: [
      {
        id: 'Submitted',
        nodeColor: 'hsl(198, 70%, 50%)',
        percentage: 100,
      },
      {
        id: 'Reviewing',
        nodeColor: 'hsl(234, 70%, 50%)',
        percentage: 100,
      },
      {
        id: 'KYC',
        nodeColor: 'hsl(184, 70%, 50%)',
        percentage: 62.5,
      },
      {
        id: 'Consultation',
        nodeColor: 'hsl(277, 70%, 50%)',
        percentage: 35,
      },
      {
        id: 'Lending',
        nodeColor: 'hsl(234, 70%, 50%)',
        percentage: 18.75,
      },
      {
        id: 'Rejected',
        nodeColor: 'hsl(113, 70%, 50%)',
        percentage: 81.25,
      },
    ],
    links: [
      {
        source: 'Submitted',
        target: 'Reviewing',
        value: 80,
      },
      {
        source: 'Reviewing',
        target: 'Rejected',
        value: 30,
      },
      {
        source: 'Reviewing',
        target: 'KYC',
        value: 50,
      },

      {
        source: 'KYC',
        target: 'Consultation',
        value: 28,
      },
      {
        source: 'KYC',
        target: 'Rejected',
        value: 20,
      },

      {
        source: 'Consultation',
        target: 'Lending',
        value: 15,
      },
      {
        source: 'Consultation',
        target: 'Rejected',
        value: 15,
      },
      {
        source: 'KYC',
        target: 'Lending',
        value: 2,
      },
    ],
  };
  const program1 = {
    nodes: [
      {
        id: 'Submitted',
        nodeColor: 'hsl(198, 70%, 50%)',
        percentage: 100,
      },
      {
        id: 'Reviewing',
        nodeColor: 'hsl(234, 70%, 50%)',
        percentage: 100,
      },
      {
        id: 'KYC',
        nodeColor: 'hsl(184, 70%, 50%)',
        percentage: 60,
      },
      {
        id: 'Consultation',
        nodeColor: 'hsl(277, 70%, 50%)',
        percentage: 30,
      },
      {
        id: 'Lending',
        nodeColor: 'hsl(234, 70%, 50%)',
        percentage: 15,
      },
      {
        id: 'Rejected',
        nodeColor: 'hsl(113, 70%, 50%)',
        percentage: 85,
      },
    ],
    links: [
      {
        source: 'Submitted',
        target: 'Reviewing',
        value: 30,
      },
      {
        source: 'Reviewing',
        target: 'Rejected',
        value: 10,
      },
      {
        source: 'Reviewing',
        target: 'KYC',
        value: 20,
      },
      {
        source: 'KYC',
        target: 'Consultation',
        value: 12,
      },
      {
        source: 'KYC',
        target: 'Rejected',
        value: 8,
      },
      {
        source: 'Consultation',
        target: 'Lending',
        value: 5,
      },
      {
        source: 'Consultation',
        target: 'Rejected',
        value: 7,
      },
      {
        source: 'KYC',
        target: 'Lending',
        value: 2,
      },
    ],
  };

  const program2 = {
    nodes: [
      {
        id: 'Submitted',
        nodeColor: 'hsl(198, 70%, 50%)',
        percentage: 100,
      },
      {
        id: 'Reviewing',
        nodeColor: 'hsl(234, 70%, 50%)',
        percentage: 100,
      },
      {
        id: 'KYC',
        nodeColor: 'hsl(184, 70%, 50%)',
        percentage: 65,
      },
      {
        id: 'Consultation',
        nodeColor: 'hsl(277, 70%, 50%)',
        percentage: 40,
      },
      {
        id: 'Lending',
        nodeColor: 'hsl(234, 70%, 50%)',
        percentage: 20,
      },
      {
        id: 'Rejected',
        nodeColor: 'hsl(113, 70%, 50%)',
        percentage: 80,
      },
    ],
    links: [
      {
        source: 'Submitted',
        target: 'Reviewing',
        value: 25,
      },
      {
        source: 'Reviewing',
        target: 'Rejected',
        value: 10,
      },
      {
        source: 'Reviewing',
        target: 'KYC',
        value: 15,
      },
      {
        source: 'KYC',
        target: 'Consultation',
        value: 10,
      },
      {
        source: 'KYC',
        target: 'Rejected',
        value: 5,
      },
      {
        source: 'Consultation',
        target: 'Lending',
        value: 5,
      },
      {
        source: 'Consultation',
        target: 'Rejected',
        value: 5,
      },
      {
        source: 'KYC',
        target: 'Lending',
        value: 5,
      },
    ],
  };

  const program3 = {
    nodes: [
      {
        id: 'Submitted',
        nodeColor: 'hsl(198, 70%, 50%)',
        percentage: 100,
      },
      {
        id: 'Reviewing',
        nodeColor: 'hsl(234, 70%, 50%)',
        percentage: 100,
      },
      {
        id: 'KYC',
        nodeColor: 'hsl(184, 70%, 50%)',
        percentage: 63,
      },
      {
        id: 'Consultation',
        nodeColor: 'hsl(277, 70%, 50%)',
        percentage: 35,
      },
      {
        id: 'Lending',
        nodeColor: 'hsl(234, 70%, 50%)',
        percentage: 22,
      },
      {
        id: 'Rejected',
        nodeColor: 'hsl(113, 70%, 50%)',
        percentage: 78,
      },
    ],
    links: [
      {
        source: 'Submitted',
        target: 'Reviewing',
        value: 25,
      },
      {
        source: 'Reviewing',
        target: 'Rejected',
        value: 10,
      },
      {
        source: 'Reviewing',
        target: 'KYC',
        value: 15,
      },
      {
        source: 'KYC',
        target: 'Consultation',
        value: 6,
      },
      {
        source: 'KYC',
        target: 'Rejected',
        value: 7,
      },
      {
        source: 'Consultation',
        target: 'Lending',
        value: 5,
      },
      {
        source: 'Consultation',
        target: 'Rejected',
        value: 3,
      },
      {
        source: 'KYC',
        target: 'Lending',
        value: 2,
      },
    ],
  };
  const [filteredData, setFilteredData] = useState(data);

  // filter data when filters change
  useEffect(() => {
    if (programmeFilter === 'Programme-1') {
      setFilteredData(program1);
    }
    if (programmeFilter === 'Programme-2') {
      setFilteredData(program2);
    }
    if (programmeFilter === 'Programme-3') {
      setFilteredData(program3);
    }
    if (
      programmeFilter === 'all' &&
      teamFilter === 'all' &&
      individualFilter === 'all'
    ) {
      setFilteredData(data);
      return;
    }
  }, [programmeFilter, teamFilter, individualFilter]);

  //    get the window size on load
  const [windowSize, setWindowSize] = useState({
    width: 500,
    height: 500,
  });

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth > 1200 ? 1200 : window.innerWidth,
      height: window.innerHeight > 600 ? 600 : window.innerHeight,
    });
  }, []);

  return (
    <div
      className="w-full m-auto h-full"
      style={{
        height: 0.7 * windowSize.height,
        width: windowSize.width,
      }}
    >
      <div className="text-2xl font-extrabold pt-4">
        Summary of application flow
      </div>
      <div className="text-m py-4">
        This is a visual summary of applications and how they've progressed.
      </div>

      {/* filters */}
      <div className=" grid grid-cols-3 gap-4 p-4 max-w-[1000px] m-auto">
        {/* programme */}
        <select
          className="rounded-full border border-1 border-solid border-stone-300 p-1 px-4"
          onChange={e => setProgrammeFilter(e.target.value)}
        >
          <option value="all">Select Programme</option>
          <option value="Programme-1">Programme 1</option>
          <option value="Programme-2">Programme 2</option>
          <option value="Programme-3">Programme 3</option>
        </select>
        {/* team */}
        <select
          className="rounded-full border border-1 border-solid border-stone-300 p-1 px-4"
          onChange={e => setTeamFilter(e.target.value)}
        >
          <option value="all">Select Team</option>
          <option value="Team-1">Team 1</option>
          <option value="Team-2">Team 2</option>
          <option value="Team-3">Team 3</option>
        </select>
        {/* individuals */}
        <select
          className="rounded-full border border-1 border-solid border-stone-300 p-1 px-4"
          onChange={e => setIndividualFilter(e.target.value)}
        >
          <option value="all">Select Individual</option>
          <option value="Individual-1">Individual 1</option>
          <option value="Individual-2">Individual 2</option>
          <option value="Individual-3">Individual 3</option>
        </select>
      </div>

      <ResponsiveSankey
        data={filteredData}
        margin={{ top: 40, right: 160, bottom: 40, left: 50 }}
        align="justify"
        colors={{ scheme: 'category10' }}
        nodeOpacity={1}
        nodeHoverOthersOpacity={0.25}
        nodeThickness={18}
        nodeSpacing={24}
        nodeBorderWidth={2}
        nodeBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.8]],
        }}
        nodeBorderRadius={3}
        linkOpacity={0.5}
        linkHoverOthersOpacity={0.1}
        linkContract={3}
        enableLinkGradient={true}
        // eslint-disable-next-line
        // @ts-ignore
        label={node => `${node.id} (${node.percentage}%)`}
        labelPosition="inside"
        labelOrientation="horizontal"
        labelPadding={16}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1]],
        }}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            translateX: 130,
            itemWidth: 100,
            itemHeight: 14,
            itemDirection: 'right-to-left',
            itemsSpacing: 2,
            itemTextColor: '#999',
            symbolSize: 14,
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}
