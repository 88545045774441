import React from 'react';

export default function Website() {
  return (
    <div>
      <svg
        height="16px"
        width="16px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 278.752 278.752"
      >
        <g>
          <path
            fill="#010002"
            d="M139.376,0C62.522,0,0,62.522,0,139.376s62.522,139.376,139.376,139.376
		s139.376-62.522,139.376-139.376S216.23,0,139.376,0z M52.634,203.59c4.672,0.31,9.747,0.495,15.191,0.495
		c9.633,0,20.456-0.631,32.156-2.181c2.975-0.397,6.489,1.403,7.898,4.058c1.458,2.747,2.959,5.493,4.531,8.246
		c1.485,2.611,0.343,5.145-2.622,5.629c-14.745,2.399-28.027,3.291-39.281,3.367c-3.002,0.022-7.277-1.637-9.431-3.737
		c-3.818-3.72-7.381-7.696-10.644-11.917C48.593,205.178,49.637,203.394,52.634,203.59z M110.511,34.418
		c1.387,1.49,0.228,3.628-2.589,4.667c-1.55,0.577-3.111,1.131-4.678,1.686c-2.834,0.995-5.075,0.087-4.944-2.002
		c0.125-2.089,2.339-4.607,4.933-5.504C105.822,32.373,109.119,32.928,110.511,34.418z M106.85,98.73
		c-2.883,0.838-5.619-0.946-6.032-3.922c-0.897-6.462-1.545-12.57-1.996-18.248c-0.239-2.997,0.19-5.597,0.908-5.798
		c0.43-0.114,0.859-0.239,1.284-0.375c8.414-2.725,16.562-5.814,24.557-9.11c2.779-1.142,6.451-0.12,8.159,2.35
		c3.225,4.667,6.576,9.796,9.953,15.365c1.556,2.573,0.56,5.7-2.187,6.913c-5.776,2.551-11.841,5.042-18.248,7.424
		C117.663,95.401,112.197,97.174,106.85,98.73z M161.301,112.741c4.46,10.117,8.567,21.049,12.02,32.705
		c0.854,2.883-0.707,6.293-3.437,7.544c-6.766,3.106-13.935,6.124-21.593,8.985c-7.446,2.779-14.702,5.064-21.74,6.94
		c-2.904,0.778-6.217-0.897-7.326-3.693c-4.476-11.313-8.05-22.393-10.884-33.042c-0.772-2.904,1.006-5.874,3.894-6.69
		c6.663-1.876,13.5-4.085,20.478-6.679c7.528-2.796,14.647-5.744,21.397-8.768C156.852,108.819,160.088,109.994,161.301,112.741z
		 M160.529,44.693c4.455-2.426,8.838-4.928,13.114-7.533c2.567-1.561,6.902-2.045,9.665-0.865
		c5.896,2.518,11.536,5.515,16.867,8.964c2.518,1.632,2.584,4.585,0.19,6.396c-6.853,5.194-15.088,10.878-24.77,16.676
		c-2.578,1.545-5.901,0.642-7.446-1.936c-3.138-5.227-6.266-10.122-9.328-14.653C157.135,49.256,157.891,46.129,160.529,44.693z
		 M48.478,81.624c5.863-0.636,11.727-1.463,17.574-2.502c2.959-0.528,5.515,1.42,5.787,4.411c0.484,5.336,1.126,10.965,1.964,16.845
		c0.424,2.975-1.605,5.733-4.591,6.092c-11.993,1.436-22.762,1.757-31.965,1.507c-3.002-0.082-4.759-2.567-3.769-5.401
		c1.904-5.434,4.226-10.666,6.908-15.686C41.805,84.24,45.492,81.945,48.478,81.624z M74.575,133.148
		c2.981-0.381,5.961,1.615,6.717,4.52c2.741,10.492,6.152,21.386,10.367,32.52c1.061,2.812-0.446,5.357-3.437,5.635
		c-21.849,2.034-39.384,0.446-50.279-1.256c-2.97-0.462-6.119-3.247-6.902-6.146c-2.497-9.268-3.851-18.993-3.851-29.039
		c0-0.789,0.044-1.561,0.098-2.333c0.087-1.284,2.573-2.154,5.57-2.023c3.138,0.141,6.445,0.223,9.959,0.223
		C52.264,135.242,62.963,134.639,74.575,133.148z M134.486,247.418c1.708,2.252,0.696,4.03-2.301,3.829
		c-5.907-0.386-11.689-1.24-17.318-2.507c-2.932-0.664-2.872-1.67,0.087-2.165c3.644-0.609,7.37-1.316,11.161-2.105
		C129.053,243.86,132.773,245.166,134.486,247.418z M161.66,234.804c0.664-0.207,1.327-0.419,2.002-0.653
		c5.14-1.8,10.106-3.655,14.903-5.553c2.79-1.11,4.944,0.397,4.732,3.399c-0.125,1.849-0.283,3.715-0.457,5.58
		c-0.277,2.991-2.79,6.358-5.629,7.354c-0.794,0.277-1.588,0.544-2.388,0.805c-2.856,0.925-6.831-0.245-8.784-2.529
		c-1.001-1.169-1.991-2.35-2.964-3.525C161.159,237.355,160.55,235.147,161.66,234.804z M148.285,210.579
		c-2.866,0.897-6.516-0.468-8.077-3.035c-1.512-2.486-2.975-4.971-4.384-7.462c-1.479-2.611-0.283-5.39,2.605-6.222
		c6.315-1.822,12.776-3.943,19.374-6.407c6.271-2.344,12.211-4.792,17.916-7.294c2.752-1.207,5.357,0.179,5.765,3.16
		c0.381,2.79,0.718,5.613,1.012,8.463c0.305,2.991-1.724,6.418-4.482,7.604c-7.294,3.138-15.061,6.195-23.334,9.089
		C152.522,209.23,150.396,209.921,148.285,210.579z M210.965,162.356c14.783-8.61,26.782-17.149,36.121-24.617
		c2.344-1.876,4.281-2.312,4.368-0.908c0.054,0.843,0.103,1.686,0.103,2.545c0,3.949-0.267,7.832-0.707,11.667
		c-0.343,2.981-2.605,6.94-4.971,8.795c-7.854,6.157-18.515,13.701-32.014,21.55c-2.594,1.507-5.004,0.277-5.412-2.703
		c-0.381-2.79-0.794-5.548-1.256-8.278C206.701,167.447,208.371,163.868,210.965,162.356z M241.435,106.753
		c-7.767,7.103-20.13,17.22-37.214,27.843c-2.551,1.588-5.33,0.495-6.239-2.371c-3.606-11.406-7.762-22.121-12.232-32.101
		c-1.229-2.741-0.147-6.152,2.437-7.68c13.081-7.74,23.894-15.376,32.466-22.093c2.366-1.855,5.831-1.556,7.658,0.827
		c6.228,8.115,11.346,17.106,15.191,26.744C244.611,100.71,243.648,104.724,241.435,106.753z M210.987,220.342
		c0.005-0.332,0.011-0.669,0.016-1.001c0.044-3.002,2.187-6.549,4.841-7.952c4.155-2.203,8.11-4.411,11.868-6.592
		c2.6-1.512,3.329-0.745,1.528,1.659c-4.264,5.695-9.056,10.971-14.299,15.757C212.722,224.236,210.932,223.344,210.987,220.342z"
          />
        </g>
      </svg>
    </div>
  );
}
