import React from 'react';
import HomeNavBar from '../components/Home/HomeNavBar';
import ProductInbox from '../components/Products/ProductInbox';

export default function Product() {
  return (
    <div className="h-full min-h-[100vh]">
      <HomeNavBar />

      <ProductInbox />
    </div>
  );
}
