import React from 'react';
import HomeSearchBar from './HomeSearchBar';
import { ConfigContext } from '../../contexts/ConfigContext';

export default function HomePageContent() {
  const { config } = React.useContext(ConfigContext);

  return (
    <div className=" max-w-[900px]  m-auto w-full ">
      {config.features?.searchBar?.visible ? <HomeSearchBar /> : null}
    </div>
  );
}
