import React, { useState } from 'react';
import UploadMultiZone from '../../../utils/UploadMultiZone';

interface FileUploadProps {
  label: string;
  placeholder?: string;
  initialValue?: string;
}

// delete in future?
export const FileUpload: React.FC<FileUploadProps> = ({
  placeholder,
  initialValue,
}) => {
  return (
    <div className="relative">
      <div className="font-bold pl-2 py-2">{initialValue || placeholder}</div>
      <UploadMultiZone
        label="false"
        afterUpload={url => console.log(url)}
        fileTypes={['pdf']}
      />
    </div>
  );
};
