import React from 'react';
import Spinner from './Spinner';

interface Props {
  text: string;
  action?: () => void;
  asyncAction?: () => Promise<null | undefined | void>;
  buttonType?: 'secondary';
  rounded?: boolean;
  isDisabled?: boolean;
  showLoading?: boolean;
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
}

export default function Button(props: Props) {
  // show loading spinner if showLoading is true
  const [showLoading, setShowLoading] = React.useState(false);

  const whenClicked = async () => {
    if (props.action) {
      props.action();
    }

    if (props.asyncAction) {
      setShowLoading(true);
      setTimeout(async () => {
        if (props.asyncAction) {
          await props.asyncAction();
        }
        setShowLoading(false);
      }, 1000);
    }
  };

  return (
    <div
      className={
        props.size === 'small'
          ? ' scale-[0.90]'
          : props.size === 'large'
          ? ' scale-[1.10]'
          : ' scale-[1.00]'
      }
    >
      <button
        disabled={props.isDisabled}
        onClick={async () => await whenClicked()}
        className={
          'overflow-hidden flex justify-center align-center inline border border-solid border-1  border-stone-50 active:scale-[0.98]' +
          (props.buttonType === 'secondary'
            ? ' text-stone-900 bg-stone-50  border-stone-300 hover:bg-stone-100             '
            : ' text-white bg-stone-900 ') +
          (props.rounded ? ' rounded-full ' : ' rounded-lg ') +
          (props.fullWidth ? ' w-full ' : ' w-max ')
        }
      >
        <div className="p-0.5 ">
          <div
            className={
              'p-2 px-4 sm:p-2 sm:px-8 font-extrabold text-sm sm:text-lg w-max w-full h-full bg-stone-900 inline-block bg-gradient-to-r from-blue-200 via-violet-300 to-red-300 hover:from-blue-200 hover:via-orange-300 hover:to-red-300 bg-clip-text text-transparent ' +
              (props.buttonType === 'secondary'
                ? ' text-stone-900 bg-stone-50  '
                : ' text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600') +
              (props.rounded ? ' rounded-full ' : ' rounded-md ')
            }
          >
            {showLoading ? <Spinner /> : props.text}
          </div>
        </div>
      </button>
    </div>
  );
}
