import React from 'react';
import { question, section } from '../../contexts/FinProductContext';
import ApplicationQuestion from './ApplicationQuestion';
import { capitalizeFirstLetter } from '../../methods/UIHelpers';

interface Props {
  section: section;
}

export default function ApplicationRepeatingQuestion(props: Props) {
  const { section } = props;
  // first find all the repeating label questions
  const repeatingLabelQuestions = section.questions.filter(
    question => question.repeating_label,
  );

  // group them on their label
  const groupedRepeatingLabelQuestions = repeatingLabelQuestions.reduce(
    (acc: { [key: string]: question[] }, question) => {
      if (!question.repeating_label) return acc;
      if (acc[question.repeating_label]) {
        acc[question.repeating_label].push(question);
      } else {
        acc[question.repeating_label] = [question];
      }
      return acc;
    },
    {},
  );

  // sub group each group on their repeating index
  const sortedRepeatingQuestionGroups = Object.keys(
    groupedRepeatingLabelQuestions,
  ).reduce(
    (
      acc: {
        [key: string]: {
          [key: string]: question[];
        };
      },
      label,
    ) => {
      acc[label] = groupedRepeatingLabelQuestions[label].reduce(
        (acc: { [key: string]: question[] }, question) => {
          if (!question.repeating_index) return acc;
          if (acc[question.repeating_index]) {
            acc[question.repeating_index].push(question);
          } else {
            acc[question.repeating_index] = [question];
          }
          return acc;
        },
        {},
      );
      return acc;
    },
    {},
  );

  // order each group by their order
  Object.keys(sortedRepeatingQuestionGroups).forEach(label => {
    Object.keys(sortedRepeatingQuestionGroups[label]).forEach(index => {
      sortedRepeatingQuestionGroups[label][index].sort(
        (a, b) => a.order - b.order,
      );
    });
  });

  return (
    <>
      {Object.keys(sortedRepeatingQuestionGroups).map(label => (
        <div key={label}>
          <div className="text-xl font-bold pt-8">
            {capitalizeFirstLetter(label)}
          </div>
          {Object.keys(sortedRepeatingQuestionGroups[label]).map((index, i) => (
            <div
              key={index}
              className="p-2 my-2 drop-shadow rounded bg-white border border-1 border-solid border-stone-100"
            >
              <div className="text-lg font-bold pb-2">
                {capitalizeFirstLetter(
                  sortedRepeatingQuestionGroups[label][index][0]
                    .repeating_label +
                    ' ' +
                    (i + 1),
                )}
              </div>

              {sortedRepeatingQuestionGroups[label][index].map(
                (question, i) => (
                  <ApplicationQuestion
                    key={question.id}
                    question={question}
                    index={i}
                  />
                ),
              )}
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
