import React from 'react';
import Options from './Options';
import Reject from './Reject';
import RequestInfo from './RequestInfo';
import SendOffer from './SendOffer';

export default function NextStageOptions() {
  const [selected, setSelected] = React.useState('');

  return (
    <div className="relative rounded-xl mx-2 my-8 text-left bg-white  drop-shadow-xl border border-solid border-1 border-stone-100 p-4">
      {selected.length ? (
        <div className="absolute top-0 right-0">
          <button
            className="rounded-xl hover:bg-stone-200 p-2"
            onClick={() => setSelected('')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#000000"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      ) : null}
      {selected === 'reject' ? (
        <Reject />
      ) : selected === 'request' ? (
        <RequestInfo />
      ) : selected === 'send' ? (
        <SendOffer />
      ) : (
        <Options
          setSelected={(string: string) => {
            setSelected(string);
          }}
        />
      )}
    </div>
  );
}
