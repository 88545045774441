import React from 'react';

interface Props {
  children: React.ReactNode;
  backgroundColor?: string;
  fullHeight?: boolean;
}
export default function GridBackground(props: Props) {
  return (
    <div
      className={'w-full h-full ' + (props.fullHeight ? 'min-h-screen' : '')}
      style={{
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : '#f5f5f5',
        // Set background image to the dot.svg file
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><circle cx="10" cy="10" r="0.5" fill="%238f8f8f" /></svg>')`,
        /* Set background repeat to repeat both horizontally and vertically */
        backgroundRepeat: 'repeat',
      }}
    >
      {props.children}
    </div>
  );
}
