import React from 'react';
import HomeNavBar from '../components/Home/HomeNavBar';
import Kanban from '../components/ApplicationOverview/Kanban';

export default function ApplicationsManagement() {
  return (
    <div className="h-full bg-stone-50 ">
      <HomeNavBar />
      <Kanban />
    </div>
  );
}
