import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from './LogoutButton';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { routes } from '../../pages/routes';

export const OnLogin = () => {
  const { user } = useAuth0();
  const { isLoading, isPartner } = React.useContext(LoggedInContext);

  if (isPartner) {
    // redirect to home
    window.location.href = routes.home;
  }

  return (
    <>
      <div>{isLoading ? 'loading' : user ? user.name : 'Not logged in'}</div>
    </>
  );
};
