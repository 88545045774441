import React from 'react';
import HomeNavBar from '../Home/HomeNavBar';
import InitialData from './InitialData';
import ReviewProgrammeDefinition from './ReviewProgrammeDefinition';
import EstimatedEligibility from './EstimatedEligibility';
import WebsiteDesign from './WebsiteDesign';
import AutomationsHome from './AutomationsHome';
import ScoreCardHome from './ScoreCardHome';
import ApplicationQuestionsHome from './ApplicationQuestions/ApplicationQuestionsHome';
import ApplicationQuestionsProvider from './ApplicationQuestions/ApplicationQuestionsContext';
import Views from './Views';
import Button from '../utils/Button';
import VulaLogo from '../utils/VulaLogo';
import { routes } from '../../pages/routes';

export default function CreateProgrammeHome() {
  const [showInitialData, setShowInitialData] = React.useState(true);
  const [step, setStep] = React.useState(0);

  const steps = [
    {
      title: 'Create new',
      icon: '📊',
      component: () => (
        <InitialData nextStep={() => setStep(prevStep => prevStep + 1)} />
      ),
    },
    {
      title: 'Definition',
      icon: '🔎',
      component: () => (
        <ReviewProgrammeDefinition
          nextStep={() => setStep(prevStep => prevStep + 1)}
        />
      ),
    },
    {
      title: 'Eligibility',
      icon: '🔬',
      component: () => (
        <EstimatedEligibility
          nextStep={() => setStep(prevStep => prevStep + 1)}
        />
      ),
    },
    {
      title: 'Website design',
      icon: '🎨',
      component: () => (
        <WebsiteDesign nextStep={() => setStep(prevStep => prevStep + 1)} />
      ),
    },
    {
      title: 'Questions',
      icon: '📝',
      component: () => (
        <ApplicationQuestionsProvider>
          <ApplicationQuestionsHome />
        </ApplicationQuestionsProvider>
      ),
    },

    {
      title: 'Score cards',
      icon: '🔟',
      component: () => <ScoreCardHome />,
    },

    {
      title: 'Automations',
      icon: '🤖',
      component: () => <AutomationsHome />,
    },
    // {
    //   title: 'Key documents',
    //   icon: '📄',
    //   component: () => <div>Key documents</div>,
    // },

    {
      title: 'Views',
      icon: '👀',
      component: () => <Views />,
    },
  ];
  return (
    <div>
      <div className="w-full flex ">
        <div className="w-1/5 h-full min-h-[100vh] border border-y-0 border-l-0 border-r-1 border-stone-200 border-solid flex justify-between flex-col">
          <div className="h-full">
            <div className="flex justify-start p-4">
              <a href={routes.home}>
                <VulaLogo size="sm" />
              </a>
            </div>
            <div
              className="grid grid-cols-1 gap-1 mt-12 p-4 
          "
            >
              {steps.map((possible_step, index) => {
                const shouldBeDisabled = () => {
                  return step === 0
                    ? // if step =0
                      //only the index 0 step should be enabled
                      index === 0
                      ? false
                      : //, otherwise all are enabled
                        true
                    : // if step is not 0, then the first step should be disabled
                    index === 0
                    ? true
                    : false;
                };
                return (
                  <button
                    disabled={shouldBeDisabled()}
                    onClick={() => setStep(index)}
                    key={possible_step.title}
                    className={`flex transition-all duration-500 rounded-xl p-2 ${
                      index === step
                        ? 'border border-stone-300 border-1 border-solid shadow '
                        : ''
                    } ${
                      // if disabled add a grey background, grey text, no hover and cursor not-allowed
                      shouldBeDisabled()
                        ? ' bg-stone-50 text-stone-200 cursor-not-allowed '
                        : ' hover:bg-stone-200 '
                    }`}
                  >
                    <div className="font-bold pr-2">{possible_step.icon}</div>
                    <div className="">{possible_step.title}</div>
                  </button>
                );
              })}
            </div>
          </div>
          {step === 0 ? null : (
            <div className="p-4">
              <Button
                fullWidth
                text="Next"
                action={() => setStep(prevStep => prevStep + 1)}
              />
              <Button
                fullWidth
                text="Save as draft"
                action={() => console.log('save')}
              />
              <a href={routes.home} className="block">
                <Button
                  fullWidth
                  text="Publish"
                  action={() => console.log('publish')}
                />
              </a>
            </div>
          )}
        </div>

        <div className="relative h-full w-full ">{steps[step].component()}</div>
      </div>
    </div>
  );
}
