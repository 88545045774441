import React, { useState } from 'react';
import MultiSelectDropdown from '../../../utils/MultiSelectDropdown';

interface Props {
  placeholder?: string;
  initialValue?: string;
}

export default function TextInput(props: Props) {
  const [editMode, setEditMode] = useState(false);

  if (editMode) {
    return (
      <div onClick={() => setEditMode(true)}>
        <div className="relative pt-4">
          {/* add the label on the border of the input */}
          <label className=" absolute top-2 right-8 bg-stone-50 w-[80px] text-center text-xs font-bold">
            Question
          </label>
          <input
            id="question"
            type="text"
            placeholder="Enter your question here"
            defaultValue="What is your name?"
            className="w-full p-2 pt-3 px-4 border border-solid border-1 font-bold border-stone-200 rounded-lg"
          />
        </div>
        {/* // the answer input */}
        <div className="relative pt-4">
          {/* add the label on the border of the input */}
          <label className=" absolute top-2 right-8 bg-stone-50 w-[80px] text-center text-xs font-bold">
            Answer placeholder
          </label>
          <input
            id="answer"
            type="text"
            placeholder="Enter your answer here"
            defaultValue="John Doe"
            className="w-full p-2 pt-3 px-4 border border-1 border-solid border-stone-200 rounded-lg"
          />
        </div>
        {/* // the buttons for adding a validation (select drop down with required, max length, valid email ) */}
        <div className="w-full flex  justify-between items-center z-10">
          {/* design this to be a click to see options and then a check box to select the options */}
          <MultiSelectDropdown
            options={[
              'Required',
              'Max length: 250 characters',
              'Max length: 250 words',
              'Valid Email',
              'Valid URL',
              'Valid South African Phone Number',
            ]}
            prompt="Add a validation"
            formFieldName="validations"
            onChange={selectedOptions => {
              console.log(selectedOptions);
            }}
          />

          {/* add translation feature */}
          <button
            className="ml-4 mt-4 w-fit m-auto border border-solid border-1 border-stone-200 hover:bg-stone-100  font-bold p-2 px-4 rounded-lg mt-2 whitespace-nowrap
          "
          >
            Create a translation
          </button>
        </div>
      </div>
    );
  }

  return (
    <div onClick={() => setEditMode(true)}>
      <div className="relative">
        {/* add the label on the border of the input */}
        <div className="font-bold pl-2 py-2">{props.initialValue}</div>
        <div className="w-full p-2  border border-solid border-1 border-stone-200 rounded-lg">
          {props.placeholder}
        </div>
      </div>
    </div>
  );
}
