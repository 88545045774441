import React from 'react';
interface Props {
  automation: {
    id: string;
    title: string;
    longDescription: string;
    comingSoon?: true;
    partner_1?: {
      id: string;
      name: string;
      logo: string;
      blurb: string;
    };
    partner_2?: {
      id: string;
      name: string;
      logo: string;
      blurb: string;
    };
  };
}
export default function AutomationCard(props: Props) {
  const { id, title, longDescription, partner_1, partner_2, comingSoon } =
    props.automation;

  const [showModal, setShowModal] = React.useState(false);
  const [isConnected, setIsConnected] = React.useState(
    typeof comingSoon === 'undefined'
      ? // only a 15% chance of being connected
        Math.random() < 0.15
      : false,
  );
  const [isConnecting, setIsConnecting] = React.useState(false);

  const connect = () => {
    setIsConnecting(true);
    setTimeout(() => {
      setIsConnected(true);
      setIsConnecting(false);
    }, 2000);
    setTimeout(() => {
      setShowModal(false);
    }, 2500);
  };

  const disconnect = () => {
    setIsConnected(false);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  if (showModal) {
    return (
      // if click outside of the card in the modal (ie the black background), close the modal

      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        onClick={toggleModal}
      >
        <div
          className=" bg-white p-4 rounded-xl max-w-[500px]  "
          onClick={e => e.stopPropagation()}
        >
          <div className="text-center rounded-xl p-4 shadow-lg bg-stone-50 border border-stone-200 border-solid border-1">
            <div className="flex justify-center py-4">
              {/* logos */}
              {partner_1 && (
                <img
                  src={partner_1.logo}
                  alt="logo left"
                  className="max-h-[70px] max-w-[140px]  object-contain "
                />
              )}
              {partner_2 && (
                <img
                  src={partner_2.logo}
                  alt="logo right"
                  className="max-h-[70px] max-w-[140px]  object-contain"
                />
              )}
            </div>

            <h1 className="text-2xl font-bold">{title}</h1>
            <p className="pt-2">{longDescription}</p>

            <div className="flex justify-center mt-4">
              <button
                disabled={comingSoon}
                onClick={isConnected ? disconnect : connect}
                className={
                  'text-white rounded-lg px-4 py-2   ' +
                  (comingSoon
                    ? 'bg-stone-400'
                    : isConnected
                    ? 'bg-red-700'
                    : 'bg-green-700')
                }
              >
                {comingSoon
                  ? 'Coming soon'
                  : isConnected
                  ? 'Disconnect'
                  : isConnecting
                  ? 'Connecting...'
                  : 'Connect'}
              </button>
            </div>
          </div>

          <div className=" overflow-scroll h-[50vh] no-scrollbar           ">
            <div className="text-sm">
              {/* explanation of partner section */}
              <h2 className="text-lg font-bold mt-4">
                Who are {partner_1?.name} and {partner_2?.name}?
              </h2>
              <p className="pt-2">
                {/* explanation of partner section */}
                {partner_1?.blurb} <br />
                {partner_2?.blurb}
              </p>

              {/* explanation of how the service works */}
              <h2 className="text-lg font-bold mt-4">How it works</h2>
              <p className="pt-2">
                {/* explanation of how the service works */}
                The service is available to all South Africans and foreign
                nationals who operate in South Africa. <br />
                When this applications scorecard has a value greater than the
                threshold value defined below, the automation will be triggered
                to collect this information as to reduce the processing time of
                the business. <br />
                When applications have a value below this scorecard threshold,
                the automation will not be triggered and the application will be
                processed manually.
              </p>

              {/* simple section showing that the scorecard must have a score greater than 600 to be considered. use an input field to show the score but dont allow changes */}
              <h2 className="text-lg font-bold mt-4">Scorecard threshold</h2>
              <div className="flex items-center">
                <input
                  type="number"
                  value="600"
                  className="border border-stone-200 border-solid rounded-lg p-2 w-16"
                  disabled
                />
                <p className="pl-2">
                  or above, the automation will be triggered.
                </p>
              </div>

              <p className="pt-2 pb-4">
                If you would like to change your threshold, please{' '}
                <a
                  // email should include details of this automation
                  href={`mailto:nic@vula.vc?subject=Change%20automation%20threshold%20for%20Connect%20to%20bank&body=Please%20change%20my%20threshold%20to%20%3Cnew%20threshold%3Efor%20the%20automation%20${title
                    .toLowerCase()
                    .replace(' ', '%20')}%20(
                    id%3A%20${id.replace(' ', '%20')}
                ).`}
                  className="text-blue-500 underline"
                >
                  email your Vula account manager.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={toggleModal}
      className="hover:scale-[1.02] transition-scale duration-300     mx-2 p-4 rounded-xl overflow-hidden bg-stone-50 shadow-lg h-full text-left border border-stone-200 border-solid border-1"
    >
      {/* two small logos side by side */}
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="flex ">
            {/* logos */}
            {partner_1 && (
              <img
                src={partner_1.logo}
                alt="logo left"
                className="max-h-[40px] max-w-[70px] pr-2 object-contain"
              />
            )}
            {partner_2 && (
              <img
                src={partner_2.logo}
                alt="logo right"
                className="max-h-[40px] max-w-[70px] object-contain"
              />
            )}
          </div>
          {/* title */}
          <h1 className="text-2xl font-bold pt-2">{title}</h1>
        </div>

        <div className="pt-6">
          {/* if connected show it */}
          {isConnected && (
            <div className="flex items-center">
              <div className="bg-green-500 w-2 h-2 rounded-full mr-2"></div>
              <p className="text-sm text-green-500">Connected</p>
            </div>
          )}
          <div className="flex items-center">
            {/* read more button */}
            <button
              onClick={toggleModal}
              className="text-sm underline hover:no_underline  text-left"
            >
              Read More
            </button>
          </div>
        </div>
        {/* coming soon pill */}
        {comingSoon && (
          <div className="bg-stone-400 rounded-full w-fit text-white text-xs px-2 mt-2">
            Coming Soon
          </div>
        )}
      </div>
    </div>
  );
}
