import React from 'react';
interface Props {
  isOpen: boolean;
}
export default function ClosedCaretIcon(props: Props) {
  return (
    <div
      className={`transform ${
        props.isOpen ? 'rotate-180' : 'rotate-0'
      } transition-transform duration-300 ease-in-out`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11 7"
        height="12"
        width="12"
      >
        <path
          d="M4.77219 6.39684L0.235851 1.48543C-0.299506 0.907563 0.13559 3.29433e-07 0.948083 3.29433e-07H10.0208C10.2026 -0.000147342 10.3806 0.049354 10.5335 0.142577C10.6864 0.2358 10.8077 0.368792 10.8829 0.525627C10.958 0.682463 10.9839 0.856494 10.9573 1.02688C10.9307 1.19726 10.8528 1.35678 10.733 1.48632L6.19666 6.39594C6.10788 6.49217 5.99839 6.5693 5.87555 6.62214C5.7527 6.67498 5.61935 6.70233 5.48443 6.70233C5.34951 6.70233 5.21615 6.67498 5.09331 6.62214C4.97046 6.5693 4.86097 6.49217 4.77219 6.39594V6.39684Z"
          fill="black"
        />
      </svg>
    </div>
  );
}
