import React from 'react';
import Button from './Button';

interface Props {
  preselectedName?: string;
  preselectedId?: string;
  type?: 'multiple' | 'single'; // todo
}
export default function TeamMembersSelectComponent(props: Props) {
  const teamMembers = [
    {
      id: '',
      name: 'Bongani',
      image:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Bongani.jpeg',
    },
    {
      id: '',
      name: 'Connor',
      image:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Connor.jpeg',
    },
    {
      id: '',
      name: 'Naseerah',
      image:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Naseerah.jpeg',
    },
    {
      id: '',
      name: 'Ntokozo',
      image:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Ntokozo.jpeg',
    },
    {
      id: '',
      name: 'Rayna',
      image:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Rayna.jpeg',
    },
    {
      id: '',
      name: 'Sinesipo',
      image:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Sinesipo.jpeg',
    },
    {
      id: '',
      name: 'Xhoba',
      image:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Xhoba.jpeg',
    },
  ];
  const findPreSelectedFromTeamMembers = () => {
    if (props.preselectedName) {
      return teamMembers.find(
        teamMember => teamMember.name === props.preselectedName,
      );
    } else if (props.preselectedId) {
      return teamMembers.find(
        teamMember => teamMember.id === props.preselectedId,
      );
    }
    return teamMembers[0];
  };

  const [selectedTeamMember, setSelectedTeamMember] = React.useState<
    | {
        id: string;
        name: string;
        image: string;
      }
    | undefined
  >(findPreSelectedFromTeamMembers());

  //   if (props.type === 'multiple') { // todo
  //   }

  //   return a drop down component with the team members images and names as options
  return (
    <div className="flex items-center">
      <img
        src={selectedTeamMember?.image || teamMembers[0].image}
        alt="team member"
        className="h-10 w-10 rounded-full mr-4"
      />
      <select
        onChange={event => {
          if (event.target.value) {
            setSelectedTeamMember(teamMembers[Number(event.target.value)]);
          }
        }}
        className="
            w-full bg-stone-100 rounded-xl px-4  py-2 text-stone-900
            "
      >
        {selectedTeamMember ? (
          <option value={teamMembers.indexOf(selectedTeamMember)}>
            {selectedTeamMember.name}
          </option>
        ) : null}
        {teamMembers.map((teamMember, index) => (
          <option value={index} key={index}>
            {teamMember.name}
          </option>
        ))}
      </select>
    </div>
  );
}
