import React from 'react';

interface Props {
  setSelected: (selected: string) => void;
}

export default function Options(props: Props) {
  return (
    <>
      <div className="text-lg font-bold">
        Move the application to the next stage
      </div>
      <div className="text-sm ">
        Once you have reviewed the documents and answered the questions, please
        select one of the following options.
      </div>
      {/* a clickable card holding a document and its title */}
      <div className="grid grid-cols-2 gap-1 w-full pt-2">
        {[
          {
            title: 'Reject',
            icon: 'https://vula-assets.s3.eu-west-2.amazonaws.com/icons/reject-cross-delete.svg',
            action: () => props.setSelected('reject'),
          },
          {
            title: 'Arrange initial meeting',
            icon: 'https://vula-assets.s3.eu-west-2.amazonaws.com/icons/calendar-success.svg',
          },
          {
            title: 'Request additional KYB',
            icon: 'https://vula-assets.s3.eu-west-2.amazonaws.com/icons/info-1.svg',
            action: () => props.setSelected('request'),
          },
          {
            title: 'Send offer agreement',
            icon: 'https://vula-assets.s3.eu-west-2.amazonaws.com/icons/signature-solid.svg',
            action: () => props.setSelected('send'),
          },
        ].map(doc => (
          <button
            key={doc.title}
            className="bg-white border border-solid border-1 border-stone-200 rounded p-2 text-left w-full"
            onClick={doc.action}
          >
            <div className="flex flex-row items-center">
              <img src={doc.icon} className="w-6 h-6 mr-2" />
              <div className="font-bold">{doc.title}</div>
            </div>
          </button>
        ))}
      </div>
    </>
  );
}
