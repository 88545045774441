import React from 'react';
import {
  FinProductContext,
  question,
} from '../../../contexts/FinProductContext';
import { MarkdownText } from '../../utils/MarkdownText';

interface Props {
  question: question;
}

export default function DocumentUploadQuestion(props: Props) {
  const { setUploadReviewId } = React.useContext(FinProductContext);
  const { question } = props;

  if (question.answer) {
    return (
      <button
        key={question.id}
        className="bg-white border border-solid border-1 border-stone-100 rounded p-2 text-left w-full"
        onClick={() => setUploadReviewId(question.id)}
      >
        <div className="flex flex-row items-center">
          <img
            src="https://vula-assets.s3.eu-west-2.amazonaws.com/icons/file-check-alt.svg"
            className="w-8 h-8 mr-2"
          />
          <div className="font-bold">
            View{' '}
            {question.corpus_label?.includes('_')
              ? question.corpus_label?.replaceAll('_', ' ')
              : question.corpus_label}
          </div>
        </div>
      </button>
    );
  }

  return (
    <>
      <div className="text pl-2 pb-1">
        <MarkdownText content={question.question} />
      </div>
      <div
        key={question.id}
        className="bg-stone-100 border border-solid border-1 border-stone-100 rounded-lg p-2 text-left "
      >
        <div className="flex flex-row items-center">
          <img
            src="https://vula-assets.s3.eu-west-2.amazonaws.com/icons/warning.svg"
            className="w-6 h-6 mr-2"
          />
          <div className="font-bold">No information uploaded</div>
        </div>
      </div>
    </>
  );
}
