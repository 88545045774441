import React from 'react';
import TeamMembersSelectComponent from '../utils/TeamMembersSelectComponent';
import Button from '../utils/Button';
import QuestionIcon from '../Icons/QuestionIcon';
import { demoSMEFunder } from '../../data/demoSMEFunder';

interface Props {
  nextStep: () => void;
}

export default function ReviewProgrammeDefinition(props: Props) {
  // on load set the dates of the programme to 2024-05-01 and 2025-04-31
  React.useEffect(() => {
    const startDate = document.getElementById(
      'programme_start_date',
    ) as HTMLInputElement;
    const endDate = document.getElementById(
      'programme_end_date',
    ) as HTMLInputElement;
    startDate.value = '2024-06-01';
    endDate.value = '2025-04-30';
  }, []);

  return (
    <div className="p-4  text-left">
      <div className="text-3xl ">Review Programme Definition</div>
      <div className="mt-4">
        Check that the programme definition is correct and make any necessary
        changes.
      </div>

      <div className="mt-4 max-w-[1000px]">
        <div className=" flex items-center">
          <div className="text-lg font-bold">Programme Title</div>
          <div className="group pl-2">
            <QuestionIcon />
            <div className="hidden shadow group-hover:block absolute bg-white p-2 rounded-xl w-1/3 mt-2">
              The title of the programme should be clear and concise. It should
              be easy to understand and should be unique, as it will be used to
              identify the programme publicly and internally.
            </div>
          </div>
        </div>
        <input
          type="text"
          className="mt-2 w-full bg-stone-100 p-2 rounded-xl"
          defaultValue={demoSMEFunder.name + ' Programme'}
        />
        <div className="text-lg font-bold mt-4">Programme Description</div>
        <textarea
          className="mt-2 w-full bg-stone-100 p-2 rounded-xl"
          defaultValue={`This is ${demoSMEFunder.name}'s core programme for grant funding. The programme is open to all who meet the eligibility criteria.`}
        ></textarea>
        <div className="text-lg font-bold mt-4">Programme Owner</div>
        <div className="w-1/3">
          <TeamMembersSelectComponent />
        </div>
        <div className="text-lg font-bold mt-4">Programme Manager</div>
        <div className="w-1/3">
          <TeamMembersSelectComponent preselectedName="Rayna" />
        </div>
        <div className="text-lg font-bold mt-4">Programme Start Date</div>

        <input
          id="programme_start_date"
          type="date"
          className="mt-2 w-1/3 bg-stone-100 p-2 rounded-xl"
        />
        <div className="text-lg font-bold mt-4">Programme End Date</div>

        <input
          id="programme_end_date"
          type="date"
          className="mt-2 w-1/3 bg-stone-100 p-2 rounded-xl"
        />

        <div className="text-lg font-bold mt-4">Programme Type</div>
        <select
          className="
        w-1/2 mt-4 bg-stone-100 rounded-xl px-4  py-2 text-stone-900
        "
        >
          <option value="3">Grant</option>
          <option value="0">Select a type</option>
          <option value="4">PO Finance</option>
          <option value="5">Asset Finance</option>
          <option value="6">Invoice Finance</option>
          <option value="2">Incubator</option>
        </select>
        <div className="text-lg font-bold mt-4">Internal reference code</div>
        <input
          type="text"
          className="mt-2 w-1/2 bg-stone-100 p-2 rounded-xl"
          defaultValue={demoSMEFunder.abbreviation + '-123-456'}
        />
      </div>
    </div>
  );
}
