import React from 'react';
import ActiveStatusTab from './Tabs/ActiveStatusTab';
import AskFunder from './Tabs/AskFunder';
import { ConfigContext } from '../../contexts/ConfigContext';

export default function TabsForReviewingCompany() {
  const { config } = React.useContext(ConfigContext);

  const tabs = [
    config.features?.rightHandSideTab?.components.askInvestorX.visible
      ? { title: 'Ask', component: <AskFunder /> }
      : undefined,
    { title: 'Actions', component: <ActiveStatusTab /> },
  ].filter(Boolean) as { title: string; component: JSX.Element }[];

  const [activeTab, setActiveTab] = React.useState(tabs[0].title);

  const getComponentFromActiveTab = () => {
    return (
      tabs.find(tab => tab.title === activeTab)?.component ||
      'Something went wrong'
    );
  };
  return (
    <div className="w-[30%] h-full no-scrollbar py-4 flex items-center ">
      {/* The headers of the tabs which are clickable to change content */}
      <div className="flex flex-col w-full ">
        <div className="flex flex-row justify-between">
          {tabs.map(tab => (
            <div
              key={tab.title}
              className={`${
                tab.title === activeTab ? 'bg-white' : 'bg-stone-100'
              } text-center text-sm py-2 px-4 rounded-t-2xl  cursor-pointer w-full drop-shadow`}
              onClick={() => setActiveTab(tab.title)}
            >
              {tab.title}
            </div>
          ))}
        </div>
        {/* The content of the tabs */}
        <div className="bg-white rounded-b-lg w-full drop-shadow">
          {getComponentFromActiveTab()}
        </div>
      </div>
    </div>
  );
}
