import React from 'react';
import GridBackground from '../components/utils/GridBackground';
import NextStageOptions from '../components/Products/NextStageOptions/NextStageOptions';

export default function MockICView() {
  return (
    <GridBackground fullHeight={true}>
      <div className="p-2">
        <div className="p-2 w-full bg-white rounded-xl shadow flex justify-between items-center">
          {/* back button and next button */}
          <button className="bg-stone-100 text-stone-900 p-2 rounded-lg px-8">
            Back
          </button>
          <button className="bg-stone-100 text-stone-900 p-2 rounded-lg px-8">
            Next
          </button>
        </div>
      </div>
      <div className="flex">
        <div className="w-1/2 overflow-scroll no-scrollbar h-[95vh] bg-white shadow-xl p-4 rounded-xl m-2">
          <div className="mt-4">
            <img
              src="https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/application_profile.png"
              alt="IC view"
              className="h-full"
            />
          </div>
        </div>
        <div className="w-1/2 h-[100vh] text-start ">
          <div className="h-1/2 bg-white shadow-xl p-4 rounded-xl m-2">
            <div className="text-3xl">Matrix</div>
            {/* show a row of their custom credit score value, their cash ratio value, and experian credit score */}
            <div className="grid grid-cols-3 gap-4 p-4">
              <div className="bg-white p-4 rounded-md shadow-md">
                <div className="text-sm text-gray-500">Credit Score</div>
                <div className="text-2xl font-bold">700</div>
              </div>
              <div className="bg-white p-4 rounded-md shadow-md">
                <div className="text-sm text-gray-500">Cash Ratio</div>
                <div className="text-2xl font-bold">1.5</div>
              </div>
              <div className="bg-white p-4 rounded-md shadow-md">
                <div className="text-sm text-gray-500">
                  Experian Credit Score
                </div>
                <div className="text-2xl font-bold">700</div>
              </div>
            </div>

            {/* A table WHICH SHOWS EACH INDIVIDUAL ANALYST AS ROWS, AND columns of "Recommendation" (appprove/reject), "Summary", "Percieved risk"(Low medium high),
             */}

            <div className="mt-4">
              <table className="w-full text-center">
                <thead className="bg-stone-300">
                  <tr>
                    <th>Analyst</th>
                    <th>Recommendation</th>
                    <th>Summary</th>
                    <th>Perceived Risk</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Analyst 1</td>
                    <td>Approve</td>
                    <td>Good company</td>
                    <td>Low</td>
                  </tr>
                  <tr>
                    <td>Analyst 2</td>
                    <td>Reject</td>
                    <td>Bad company</td>
                    <td>High</td>
                  </tr>
                  <tr>
                    <td>Analyst 3</td>
                    <td>Approve</td>
                    <td>Good company</td>
                    <td>Low</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* actions  */}
          </div>
          <div className="h-1/2 bg-white shadow-xl p-4 rounded-xl m-2">
            <div className="text-2xl">Actions</div>
            <div className="flex justify-between items-center mt-2">
              <NextStageOptions />
            </div>
          </div>
        </div>
      </div>
    </GridBackground>
  );
}
