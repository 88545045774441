import React, { createContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { routes } from '../pages/routes';
import { Navigate } from 'react-router-dom';

// type of state
interface LoggedInContextProps {
  isLoading: boolean;
  isPartner: boolean;
}

// Initialise with Context engine
export const LoggedInContext = createContext<LoggedInContextProps>({
  isLoading: true,
  isPartner: false,
});

const LoggedInProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, isLoading, isAuthenticated } = useAuth0();

  // state
  const [isPartner, setIsPartner] = useState(false);

  // onload get the user data
  // we seem to loose this, so on each page change load user to be safer
  useEffect(() => {
    (async () => {
      // this needs to be configured in auth0 actions
      const isPartner = user?.['https://vula.vc/user_metadata.isPartner'];
      if (isPartner) {
        setIsPartner(true);
      }
    })();
  }, [isLoading]);

  return (
    <LoggedInContext.Provider value={{ isLoading, isPartner }}>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="text-2xl font-bold">Loading...</div>
        </div>
      ) : (!isLoading && isPartner) ||
        window.location.pathname === routes.landing ? (
        children
      ) : isAuthenticated ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <img
            className="w-14 h-14 mb-14 rounded-full transform -rotate-45"
            src="/vula-sun.png"
            alt="logo"
          />

          <div className="text-2xl text-center font-bold">
            Something went wrong <br />
            Please contact your Vula support manager.
          </div>
        </div>
      ) : (
        // redirect to landing page
        <Navigate to={routes.landing} />
      )}
    </LoggedInContext.Provider>
  );
};

export default LoggedInProvider;
