import React from 'react';
import GridBackground from '../utils/GridBackground';
import AutomationSection from './AutomationSection';
import { demoSMEFunder } from '../../data/demoSMEFunder';

interface Partner {
  id: string;
  name: string;
  logo: string;
  blurb: string;
}

interface Automation {
  id: string;
  title: string;
  longDescription: string;
  comingSoon?: boolean;
  partner_1?: Partner;
  partner_2?: Partner;
}

interface AutomationSection {
  section_id: string;
  section_title: string;
  section_description: string;
  special_background?: string;
  automations: Automation[];
}

export default function AutomationsHome() {
  const partners: Partner[] = [
    {
      id: '1',
      name: demoSMEFunder.name,
      logo: demoSMEFunder.logo,
      blurb: demoSMEFunder.blurb,
    },
    {
      id: '2',
      name: 'CIPC BizPortal',
      logo: 'https://bizportal.gov.za/images/logo.png',
      blurb:
        'BizPortal is a platform developed by the Companies and Intellectual Property Commission (CIPC) South Africa to offer company services in a simple seamless digital way which is completely paperless. It was developed in response to the quest of improving the ease of doing business in South Africa.',
    },
    {
      id: '3',
      name: 'SARS',
      logo: 'https://bizportal.gov.za/images/logo.png',
      blurb: 'SARS is the South African Revenue Services.',
    },
    {
      id: '4',
      name: 'SmileID',
      logo: 'https://bizportal.gov.za/images/logo.png',
      blurb:
        'SmileID is a platform that helps you verify the identity of your customers.',
    },
    {
      id: '5',
      name: 'KYCDD',
      logo: 'https://bizportal.gov.za/images/logo.png',
      blurb:
        'KYCDD is a platform that helps you verify the identity of your customers.',
    },
    {
      id: '6',
      name: 'Vula',
      logo: 'https://vula-assets.s3.eu-west-2.amazonaws.com/vula_logos/vula-sun.png',
      blurb:
        'Vula is a platform that helps you automate your processes, reducing time and effort, and hence cost of serve, meaning you can help more SMEs access finance and grow their businesses.',
    },
    {
      id: '7',
      name: 'Data Confirm',
      logo: 'https://dataconfirm.co.za/assets/images/data-confirm-logo-blue-2-1-441x122.webp',
      blurb:
        'Data Confirm is a South African data platform that helps you verify the identity of your customers.',
    },
    {
      // whatsapp
      id: '8',
      name: 'WhatsApp',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/2062095_application_chat_communication_logo_whatsapp_icon.svg/2048px-2062095_application_chat_communication_logo_whatsapp_icon.svg.png',
      blurb: 'WhatsApp is a messaging platform.',
    },
    {
      // microsoft outlook
      id: '9',
      name: 'Outlook',
      logo: 'https://m.media-amazon.com/images/I/51tOZiEHNLL.png',
      blurb: 'Microsoft Outlook is an email platform.',
    },
  ];

  const automation_sections: AutomationSection[] = [
    {
      section_id: demoSMEFunder.name + ' custom automations',
      section_title: demoSMEFunder.name + ' automations',
      section_description: `Automations to help you automate ${demoSMEFunder.name} process.`,
      special_background:
        //   diagonal gradient
        'from-blue-50 via-stone-50 to-green-50 bg-gradient-to-br',
      automations: [
        {
          id: '',
          partner_1: partners.find(partner => partner.id === '1'),
          partner_2: partners.find(partner => partner.id === '6'),
          title: demoSMEFunder.name + ' automatic offer calculation',
          longDescription:
            demoSMEFunder.name +
            ' automatic offer calculation (on click see rules defined, and option to ask for changes, show: AAA, A , B and B- personas, the amounts Prime plus variable 3.5%, 5.5%, 7.5%, 10%)',
        },
      ],
    },
    {
      section_id: 'verification',
      section_title: 'Document verification',
      section_description:
        'Automations to help you verify your customers submissions.',
      automations: [
        {
          id: '',
          partner_1: partners.find(partner => partner.id === '2'),
          title: 'Company registration check',
          longDescription:
            'Automatically check if company is registered with CIPC on each application whose scorecard is above a certain threshold.',
          comingSoon: true,
        },
        {
          id: '',
          partner_1: partners.find(partner => partner.id === '7'),
          title: 'Company registration check',
          longDescription:
            'Automatically check if company is registered with CIPC on each application whose scorecard is above a certain threshold.',
          comingSoon: true,
        },
        {
          id: '',
          partner_1: partners.find(partner => partner.id === '2'),
          title: 'Director verification',
          longDescription:
            'Automatically check if the directors are registered with CIPC on each application whose scorecard is above a certain threshold.',
        },
        {
          id: '',
          partner_1: partners.find(partner => partner.id === '7'),
          title: 'Director verification',
          longDescription:
            'Automatically check if the directors are registered with CIPC on each application whose scorecard is above a certain threshold.',
        },
        {
          id: '',
          partner_1: partners.find(partner => partner.id === '3'),
          title: 'Tax Registration',
          longDescription:
            'Automatically check if company is registered with the South African Revenue Services (SARS) on each application whose scorecard is above a certain threshold.',
        },
        {
          id: '',
          partner_1: partners.find(partner => partner.id === '7'),
          title: 'Tax Registration',
          longDescription:
            'Automatically check if company is registered with the South African Revenue Services (SARS) on each application whose scorecard is above a certain threshold.',
        },
        {
          id: '',
          title: 'B-BBEE Certificates',
          partner_1: partners.find(partner => partner.id === '3'),
          longDescription:
            'Automatically find a B-BBEE certificate for each application whose scorecard is above a certain threshold.',
        },
        {
          id: '',
          title: 'B-BBEE Certificates',
          partner_1: partners.find(partner => partner.id === '7'),
          longDescription:
            'Automatically find a B-BBEE certificate for each application whose scorecard is above a certain threshold.',
        },
      ],
    },
    {
      section_id: 'analysis',
      section_title: 'Document analysis',
      section_description:
        'Automations to help you analyse your customers submissions.',
      automations: [
        {
          id: '',
          title: 'Balance sheet analysis',
          partner_1: partners.find(partner => partner.id === '6'),
          longDescription:
            'Vula can read balance sheets, automatically find the base level metrics and pre-calculate the financial ratios, ready for your analyst to review and save their time.',
        },
        {
          id: '',
          title: 'Document validation',
          partner_1: partners.find(partner => partner.id === '6'),
          longDescription:
            'Have Vula check that all the documents are correctly uploaded and legible, saving time of your analysts from chasing SMEs.',
        },
      ],
    },
    {
      section_id: 'reminders',
      section_title: 'Reminders',
      section_description:
        'Reminder automations to help you notify your team and customers.',
      automations: [
        {
          id: '',
          title: 'Email remind SME after 3 days',
          partner_1: partners.find(partner => partner.id === '9'),
          partner_2: partners.find(partner => partner.id === '6'),
          longDescription: 'Email remind SME after 3 days',
        },
        {
          id: '',
          title: 'Email remind SME 1 week before due date',
          partner_1: partners.find(partner => partner.id === '9'),
          partner_2: partners.find(partner => partner.id === '6'),
          longDescription: 'Email remind SME 1 week before due date',
        },
        {
          id: '',
          title: 'Email remind SME 2 days before payment is due',
          partner_1: partners.find(partner => partner.id === '9'),
          partner_2: partners.find(partner => partner.id === '6'),
          longDescription: 'Email remind SME 2 days before payment is due',
        },
        {
          id: '',
          title: 'Email remind SME to accept offer after 3 days',
          partner_1: partners.find(partner => partner.id === '9'),
          partner_2: partners.find(partner => partner.id === '6'),
          longDescription: 'Email remind SME 1 week before due date',
        },
        {
          // all the above but using whatsapp
          id: '',
          title: 'WhatsApp remind SME after 3 days',
          partner_1: partners.find(partner => partner.id === '8'),
          partner_2: partners.find(partner => partner.id === '6'),
          longDescription: 'WhatsApp remind SME after 3 days',
          comingSoon: true,
        },
        {
          id: '',
          title: 'WhatsApp remind SME 1 week before due date',
          partner_1: partners.find(partner => partner.id === '8'),
          partner_2: partners.find(partner => partner.id === '6'),
          longDescription: 'WhatsApp remind SME 1 week before due date',
          comingSoon: true,
        },
        {
          id: '',
          title: 'WhatsApp remind SME 2 days before payment is due',
          partner_1: partners.find(partner => partner.id === '8'),
          partner_2: partners.find(partner => partner.id === '6'),
          longDescription: 'WhatsApp remind SME 2 days before payment is due',
          comingSoon: true,
        },
        {
          id: '',
          title: 'WhatsApp remind SME to accept offer after 3 days',
          partner_1: partners.find(partner => partner.id === '8'),
          partner_2: partners.find(partner => partner.id === '6'),
          longDescription: 'WhatsApp remind SME 1 week before due date',
          comingSoon: true,
        },
      ],
    },

    // {
    //   id: '',
    //   title: 'Connect to bank',
    //   logoLeft: 'https://bizportal.gov.za/images/logo.png',
    //   longDescription:
    //     'Connect to bank  - get bank statements and Vula analysis',
    // },
    // {
    //   id: '',
    //   logoLeft: 'https://bizportal.gov.za/images/logo.png',
    //   title: 'SmileID',
    //   longDescription:
    //     'Automatically check if the applicant is a real person using SmileID.',
    // },
    // {
    //   id: '',
    //   title: 'KYCDD',
    //   logoLeft: 'https://bizportal.gov.za/images/logo.png',

    //   longDescription:
    //     'Automatically check if the applicant is a real person using KYCDD.',
    // },
  ];

  const [searchText, setSearchText] = React.useState('');

  const [filteredAutomations, setFilteredAutomations] =
    React.useState(automation_sections);

  const filterAutomations = (
    automation_sections: AutomationSection[],
    searchText: string,
  ) => {
    // given text entered into the search bar, filter the automations based on their title and their long description
    // return the filtered automations in the same structure as above (ie cherry pick or remove non-matching automations)
    //
    return automation_sections.map(section => {
      return {
        ...section,
        automations: section.automations.filter(automation => {
          return (
            automation.title.toLowerCase().includes(searchText.toLowerCase()) ||
            automation.longDescription
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        }),
      };
    });
  };

  React.useEffect(() => {
    setFilteredAutomations(filterAutomations(automation_sections, searchText));
  }, [searchText]);

  return (
    <div className="max-h-[100vh]">
      <GridBackground backgroundColor={undefined}>
        <div className="text-left p-4 overflow-scroll max-h-[100vh]">
          <div className={'rounded-lg p-4 shadow-md mb-4 bg-stone-50'}>
            <h1 className="text-4xl font-bold text-gray-900">
              Vula Automations
            </h1>
            <p className="text-sm text-gray-700 mt-2 max-w-1/2">
              Automations are a way to make your life easier by automating
              repetitive tasks.
              <br />
              You can choose from a library of automations to help you get
              started.
              <br />
              If you need a custom automation, please contact your Vula account
              manager.
            </p>

            <div className="p-4">
              <div
                className={
                  ' w-full rounded-full  bg-white p-4 px-8 flex items-center shadow'
                }
              >
                <input
                  type="text"
                  placeholder="Search for automations..."
                  className="w-full bg-transparent outline-none"
                  value={searchText}
                  onChange={event => setSearchText(event.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Card sections */}
          {filteredAutomations.map(section => (
            <AutomationSection section={section} />
          ))}
        </div>
      </GridBackground>
    </div>
  );
}
