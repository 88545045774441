import React from 'react';
import {
  FinProductContext,
  question,
} from '../../../contexts/FinProductContext';
import Button from '../../utils/Button';

interface Props {
  question: question;
}
export default function StatusChangeButton(props: Props) {
  const { updateApplicationStatus } = React.useContext(FinProductContext);
  const { question } = props;

  if (
    typeof question?.additional_info === 'string' &&
    question?.additional_info !== null
  ) {
    return (
      <div className="pb-2 pl-2">
        <Button
          asyncAction={async () => {
            // eslint-disable-next-line
            // @ts-ignore
            await updateApplicationStatus(question.additional_info); // why wont it recognize this as a string?
          }}
          text={props.question.question}
        />
      </div>
    );
  }

  // show message to say incorrectly configured
  return <div>Incorrectly configured question - please contact support</div>;
}
