import React, { useState } from 'react';

interface SingleSelectProps {
  placeholder?: string;
  initialValue?: string;
}

export const SingleSelect: React.FC<SingleSelectProps> = ({
  placeholder,
  initialValue,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [options, setOptions] = useState<string[]>(
    placeholder ? [placeholder] : [],
  );
  const [newOption, setNewOption] = useState('');

  const handleAddOption = () => {
    if (newOption.trim() !== '') {
      setOptions([newOption.trim()]);
      setNewOption('');
    }
  };

  const handleRemoveOption = () => {
    setOptions([]);
  };

  if (editMode) {
    return (
      <div onClick={() => setEditMode(true)}>
        <div className="relative pt-4">
          <label className="absolute top-2 right-8 bg-stone-50 w-[80px] text-center text-xs font-bold">
            Question
          </label>
          <input
            id="question"
            type="text"
            placeholder="Enter your question here"
            defaultValue="Choose an option"
            className="w-full p-2 pt-3 px-4 border border-solid border-1 font-bold border-stone-200 rounded-lg"
          />
        </div>
        <div className="relative pt-4">
          <label className="absolute top-2 right-8 bg-stone-50 w-[80px] text-center text-xs font-bold">
            Option
          </label>
          <div className="mt-2 w-full bg-stone-100 p-2 rounded-xl">
            {options.length > 0 ? (
              <div className="flex justify-between items-center mb-2">
                <span>{options[0]}</span>
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={handleRemoveOption}
                >
                  Remove
                </button>
              </div>
            ) : (
              <p>No option selected</p>
            )}
            <div className="flex">
              <input
                id="new-option"
                type="text"
                placeholder="Add an option"
                value={newOption}
                onChange={e => setNewOption(e.target.value)}
                className="w-full p-2 border border-solid border-1 border-stone-200 rounded-lg"
              />
              <button
                className="ml-2 bg-stone-500 text-white rounded-lg px-4 py-2"
                onClick={handleAddOption}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div onClick={() => setEditMode(true)}>
      <div className="relative">
        <div className="font-bold pl-2 py-2">{initialValue}</div>
        <div className="w-full p-2 border border-solid border-1 border-stone-200 rounded-lg">
          {options[0] || 'No option selected'}
        </div>
      </div>
    </div>
  );
};
