import React from 'react';
import GridBackground from '../components/utils/GridBackground';
import Mermaid from '../components/CreateProgramme/ScoreCardMermaid';

export default function MockDisbursement() {
  return (
    <GridBackground fullHeight={true}>
      <div className="p-2">
        <div className="p-2 w-full bg-white rounded-xl shadow flex justify-between items-center">
          {/* back button and next button */}
          <button className="bg-stone-100 text-stone-900 p-2 rounded-lg px-8">
            Back
          </button>
          <button className="bg-stone-100 text-stone-900 p-2 rounded-lg px-8">
            Next
          </button>
        </div>
      </div>
      <div className="flex">
        <div className="w-1/2 overflow-scroll no-scrollbar h-[95vh] bg-white shadow-xl p-4 rounded-xl m-2">
          <div className="mt-4">
            <img
              src="https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/application_profile.png"
              alt="IC view"
              className="h-full"
            />
          </div>
        </div>
        <div className="w-1/2 h-[100vh] text-start ">
          <div className="h-full bg-white shadow-xl p-4 rounded-xl m-2">
            <div className="text-3xl">Payment history</div>

            {/* A table WHICH SHOWS EACH INDIVIDUAL ANALYST AS ROWS, AND columns of "Recommendation" (appprove/reject), "Summary", "Percieved risk"(Low medium high),
             */}

            <div className="mt-4">
              <div className="overflow-y-auto mt-4 h-[30vh]">
                <table className="w-full text-sm">
                  <thead className="bg-stone-50">
                    <tr>
                      <th className="p-2">Date</th>
                      <th className="p-2">Amount</th>
                      <th className="p-2">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="p-2">2021-10-01</td>
                      <td className="p-2">R 100 000</td>
                      <td className="p-2">Paid</td>
                    </tr>
                    <tr>
                      <td className="p-2">2021-10-01</td>
                      <td className="p-2">R 100 000</td>
                      <td className="p-2">Paid</td>
                    </tr>
                    <tr>
                      <td className="p-2">2021-10-01</td>
                      <td className="p-2">R 100 000</td>
                      <td className="p-2">Paid</td>
                    </tr>
                    <tr>
                      <td className="p-2">2021-10-01</td>
                      <td className="p-2">R 100 000</td>
                      <td className="p-2">Paid</td>
                    </tr>
                    <tr>
                      <td className="p-2">2021-10-01</td>
                      <td className="p-2">R 100 000</td>
                      <td className="p-2">Paid</td>
                    </tr>
                    <tr>
                      <td className="p-2">2021-10-01</td>
                      <td className="p-2">R 100 000</td>
                      <td className="p-2">Paid</td>
                    </tr>
                    <tr>
                      <td className="p-2">2021-10-01</td>
                      <td className="p-2">R 100 000</td>
                      <td className="p-2">Paid</td>
                    </tr>
                    <tr>
                      <td className="p-2">2021-10-01</td>
                      <td className="p-2">R 100 000</td>
                      <td className="p-2">Disbursement</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="">
                <Mermaid
                  chart={`xychart-beta
                title "Sales Revenue"
                x-axis [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]
                y-axis "Revenue (in $)" 4000 --> 1100000
                bar [1000000, 100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1100000]
                `}
                  id="chart1"
                />
              </div>
            </div>

            {/* actions  */}
          </div>
        </div>
      </div>
    </GridBackground>
  );
}
