import React from 'react';
import DocumentRender from './DocumentRender';
import {
  FinProductContext,
  question,
} from '../../../contexts/FinProductContext';
import {
  convertToReadableNumber,
  takeLetterUnitsAndConvertToNumber,
} from '../../../methods/UIHelpers';
import Spinner from '../../utils/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaPartnerDocumentsAPI } from '../../../api/documents';

interface Props {
  selectedQuestion: question | null;
}

export interface AnalysisData {
  id: string;
  label: string;
  question: string;
  content: string;
  subtype?: string;
  author: {
    email: string;
  };
  attachment_id: string;
  entity_id: string;
  children: {
    // this is filtered to just be the justification
    id: string;
    content: string;
    label: string;
    order: string;
    subtype: string;
    author: {
      email: string;
    };
  }[];
}

export default function BalanceSheetAnalysis(props: Props) {
  const { selectedQuestion } = props;
  const { selectedProductApplication, uploadReviewId } =
    React.useContext(FinProductContext);
  const { getAccessTokenSilently } = useAuth0();

  const [results, setResults] = React.useState<AnalysisData[]>([]);

  const [meta_variables, setMetaVariables] = React.useState<{
    [key: string]: number | null;
  }>({});
  const [showError, setShowError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // on load get the data from the api
  React.useEffect(() => {
    // when an new url is gathered, call the api for analysis
    if (selectedQuestion?.answer) {
      (async () => {
        try {
          setIsLoading(true);
          if (!selectedProductApplication?.company.id) {
            return null;
          }
          const token = await getAccessTokenSilently();
          const api = new VulaPartnerDocumentsAPI({ token });
          const response = await api.getDocumentsAnalysis(
            selectedProductApplication?.company.id,
            selectedQuestion.corpus_label,
          );

          console.log(response.data);

          if (response.data.current_liabilities === null) {
            setShowError(true);
          } else {
            setResults(response.data);

            calculate_meta_variables();
          }
          setIsLoading(false);
        } catch (err) {
          console.log(err);
          setShowError(true);
          setIsLoading(false);
        }
      })();
    }
  }, []);

  // on change of results, recalculate the ratios
  React.useEffect(() => {
    // when an new results are return, recalculate the ratios
    calculate_meta_variables();
  }, [results]);

  function calculate_meta_variables() {
    const meta_variables = {
      current_assets: parseFloat(
        getVariableFromLabel('current_assets')?.content || '0',
      ),
      current_liabilities: parseFloat(
        getVariableFromLabel('current_liabilities')?.content || '0',
      ),
      inventory: parseFloat(getVariableFromLabel('inventory')?.content || '0'),
      cash_assets: parseFloat(
        getVariableFromLabel('cash_assets')?.content || '0',
      ),
      outstanding_debts: parseFloat(
        getVariableFromLabel('outstanding_debts')?.content || '0',
      ),
    };

    const current_ratio =
      meta_variables.current_assets / meta_variables.current_liabilities ||
      null;

    const quick_ratio =
      (meta_variables.current_assets - meta_variables.inventory || 0) /
        meta_variables.current_liabilities || 0;

    const cash_ratio =
      meta_variables.cash_assets / meta_variables.current_liabilities || null;

    const debt_to_equity_ratio =
      meta_variables.outstanding_debts /
        (meta_variables.current_assets - meta_variables?.current_liabilities) ||
      null;

    const working_capital =
      meta_variables?.current_assets - meta_variables?.current_liabilities ||
      null;

    setMetaVariables({
      current_ratio,
      quick_ratio,
      cash_ratio,
      debt_to_equity_ratio,
      working_capital,
    });
  }

  const updateNote = (value: string) => {
    if (!uploadReviewId) return;
    // save to db
    (async () => {
      try {
        // use the selected application and find the question id that equal the uploadReviewId
        const question = selectedProductApplication?.sections
          .map(section => section.questions)
          .flat()
          .find(question => question.id === uploadReviewId);
        console.log(question);
        if (!question?.corpus_id) {
          console.log('No corpus id found');
          return;
        }
        const token = await getAccessTokenSilently();
        const api = new VulaPartnerDocumentsAPI({ token });

        await api.createDocumentNote(question?.corpus_id, value);
      } catch (err) {
        console.log(err);
      }
    })();
  };

  const updateResults = (id: string, value: number | string) => {
    // save to db
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const api = new VulaPartnerDocumentsAPI({ token });

        await api.updateDocumentAnalysis(id, value);
      } catch (err) {
        console.log(err);
      }
    })();

    // update the results array
    const newResults = results.map(result => {
      if (result.id === id) {
        result.content = value.toString();
      }
      return result;
    });
    setResults(newResults);
  };

  const splitAndCapitalise = (str: string) => {
    // takes underscores and capitalises the first letter of each word
    const split = str.split('_');
    const capitalised = split.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalised.join(' ');
  };

  const getVariableFromLabel = (label: string) => {
    return results.find(result => result.label === label);
  };

  return (
    <div className="flex bg-stone-50 p-4 rounded-xl">
      {showError ? (
        <div className="text-5xl font-bold mt-20">
          Error reading your pdf. Please try again.
        </div>
      ) : isLoading ? (
        <div className="flex items-center mt-20">
          <Spinner />
          <div className="text-5xl font-bold ">
            Vula is analysing the document...
          </div>
        </div>
      ) : results ? (
        <div className="w-1/2 flex justify-between overflow-scroll no-scrollbar h-[80vh]">
          {/* i dont like using 80vh but we need to define some window height and I am being lazy by not querying the browser size - sorry */}
          <div className=" p-4">
            <div className="text-5xl font-bold ">Balance sheet</div>
            <div className="text font-bold py-4">
              Here is the balance sheet, analysed by Vula. <br />
              Please review the results and justifications below.
            </div>
            {/* notes */}
            <div className="py-4 ">
              <textarea
                className="w-full h-24 text-sm p-4 border border-solid border-stone-200 rounded-lg bg-white shadow"
                placeholder="Add a comment here..."
                defaultValue={getVariableFromLabel('investor_notes')?.content}
                onBlur={e => {
                  updateNote(e.currentTarget.value);
                }}
              ></textarea>
            </div>
            {/* tables */}
            <table className="table-auto text-left text-sm pt-12">
              <tbody>
                <tr key={'current_ratio'}>
                  <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2">
                    Current Ratio
                  </td>
                  <td
                    className={
                      ' bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2' +
                      ((meta_variables?.current_ratio || 0) > 1.5
                        ? ' text-green-500'
                        : '') +
                      ((meta_variables?.current_ratio || 0) < 0.7
                        ? ' text-red-500'
                        : '')
                    }
                  >
                    {meta_variables?.current_ratio?.toFixed(3)}
                  </td>
                </tr>
                <tr key={'quick_ratio'}>
                  <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2">
                    Quick Ratio
                  </td>
                  <td
                    className={
                      ' bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2' +
                      ((meta_variables?.quick_ratio || 0) > 1
                        ? ' text-green-500'
                        : '') +
                      ((meta_variables?.quick_ratio || 0) < 0.7
                        ? ' text-red-500'
                        : '')
                    }
                  >
                    {meta_variables?.quick_ratio?.toFixed(3)}
                  </td>
                </tr>
                <tr key={'cash_ratio'}>
                  <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2">
                    Cash Ratio
                  </td>
                  <td
                    className={
                      ' bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2' +
                      ((meta_variables?.cash_ratio || 0) > 1
                        ? ' text-green-500'
                        : '') +
                      ((meta_variables?.cash_ratio || 0) < 0.5
                        ? ' text-red-500'
                        : '')
                    }
                  >
                    {meta_variables?.cash_ratio?.toFixed(3)}
                  </td>
                </tr>
                <tr key={'debt_to_equity_ratio'}>
                  <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2">
                    Debt to Equity Ratio
                  </td>
                  <td
                    className={
                      ' bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2' +
                      ((meta_variables?.debt_to_equity_ratio || 0) > 2
                        ? ' text-green-500'
                        : '') +
                      ((meta_variables?.debt_to_equity_ratio || 0) < 2
                        ? ' text-red-500'
                        : '')
                    }
                  >
                    {meta_variables?.debt_to_equity_ratio?.toFixed(3)}
                  </td>
                </tr>
                <tr key={'working_capital'}>
                  <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2">
                    Working Capital
                  </td>
                  <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2">
                    {Math.round(meta_variables?.working_capital || 0)}
                  </td>
                </tr>

                <tr>
                  <td className=" bg-stone-50  px-4 py-2"></td>
                  <td className=" bg-stone-50  px-4 py-2"></td>
                </tr>

                {/* Table details - the extracted variables */}

                <tr>
                  <td className=" bg-stone-50 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2"></td>
                  <td className=" bg-stone-50 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 text-xs">
                    {getVariableFromLabel('document_currency')?.content}{' '}
                    {getVariableFromLabel('document_currency')?.subtype?.split(
                      'units:',
                    )[0] + 's'}
                  </td>
                </tr>
                {/* for each key of the results object */}
                {results.map(item => {
                  if (
                    item.label === 'document_currency' ||
                    item.label === 'investor_notes'
                  ) {
                    return null;
                  }
                  return (
                    <>
                      <tr key={item.id}>
                        <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 w-[80%]]">
                          {splitAndCapitalise(item.label)}
                        </td>
                        <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 w-[20%]">
                          <input
                            type="text"
                            onBlur={e => {
                              updateResults(
                                item.id,
                                takeLetterUnitsAndConvertToNumber(
                                  e.currentTarget.value,
                                ),
                              );
                              calculate_meta_variables();
                            }}
                            defaultValue={convertToReadableNumber(item.content)}
                          />
                        </td>
                      </tr>
                      {item.children && (
                        <tr
                          key={item.id + '_justification'}
                          className="bg-stone-50 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 text-xs w-full"
                        >
                          <td colSpan={2} className="w-full p-1">
                            {item?.author?.email
                              ? `Modified by ${item.author?.email}`
                              : item.children[0]?.content}
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      <div className={' h-auto  p-4  w-1/2 '}>
        <DocumentRender answer={selectedQuestion?.answer} />
      </div>
    </div>
  );
}
