import React from 'react';

export default function RequestInfo() {
  // allows the user to request any thing, and our AI will search for it
  // if it cannot find the information, or the information it retrieves isn't good enough
  // it will send the company a message asking for the information - asking the user to proof read it

  // state
  const [messageSent, setMessageSent] = React.useState(false);

  // when the message is send show an image of a letter being sent
  if (messageSent) {
    return (
      <div className=" ">
        <div className="text-lg font-bold">Message Sent</div>
        <div className="flex items-center">
          <div className="py-2 ">
            Vula did not find the information you requested. Vula has sent a
            message to the company asking for the information. You will be
            notified when they respond.
          </div>
          <img
            src="https://vula-assets.s3.eu-west-2.amazonaws.com/icons/sent.svg"
            className="w-32 h-32"
          />
        </div>
      </div>
    );
  }

  return (
    <div className=" ">
      <div className="text-lg font-bold">Request additional KYB</div>
      <div className="py-2 ">
        Please write a message to the company for the information you would like
        to recieve.
      </div>
      <div className="text-sm pb-2">
        This will be read by Vula and Vula will then search the company's data.
        If it cannot be found a request will be sent to the customer for them to
        provide.
      </div>
      <div className="text-sm ">
        <textarea className="border border-solid border-1 border-stone-200 rounded p-2 text-left w-full" />
      </div>
      <div className="flex flex-row justify-end pt-4 text-center">
        <button
          onClick={() => setMessageSent(true)}
          className="bg-white border border-solid border-1 border-stone-200 rounded p-2 text-left w-32"
        >
          Send
        </button>
      </div>
    </div>
  );
}
