import React from 'react';
import Button from '../utils/Button';
import UploadZone from '../utils/UploadZone';
import { demoSMEFunder } from '../../data/demoSMEFunder';

interface Props {
  nextStep: () => void;
}

export default function WebsiteDesign(props: Props) {
  const [backgroundColor, setBackgroundColor] = React.useState('#d6d3d1');

  return (
    <div className="flex w-full h-full text-left">
      {/* content editor */}
      <div className="w-1/3 border border-r-1 border-stone-300 border-solid border-l-0 border-y-0 min-h-full flex flex-col justify-between">
        <div className="w-full  px-4">
          {/* header */}
          <h1 className="text-3xl font-bold  py-4 ">Content editor</h1>
          <div className="">
            <h2 className=" text-sm">
              This is how your programme website will look publicly.
            </h2>
          </div>
          {/* background color */}
          {/* <div className="mt-4">
            <h2 className="text-sm font-bold">Background color</h2>

            <div className=" rounded-lg mt-1 overflow-hidden w-20 h-8 flex items-center justify-center border border-stone-200 border-solid">
              <input
                type="color"
                id="head"
                name="head"
                value={backgroundColor}
                onChange={e => setBackgroundColor(e.target.value)}
                // make slightly larger and rounded
                className="w-24 h-12"
              />
            </div>
          </div>

          <div className="mt-4">
            <h2 className="text-sm font-bold">Header type</h2>
            <div className="flex items-center mt-1">
              <input
                type="radio"
                id="headerType"
                name="headerType"
                value="image"
                className="mr-2"
              />
              <label htmlFor="headerType">Image</label>
            </div>
            <div className="flex items-center mt-1">
              <input
                type="radio"
                id="headerType"
                name="headerType"
                value="text"
                className="mr-2"
              />
              <label htmlFor="headerType">Text</label>
            </div>
          </div> */}
          <div className="mt-4 p-2 rounded-xl bg-white shadow">
            <div className="text-xs rounded-full bg-stone-400 p-2 px-4 w-fit m-auto border border-1 border-stone-200 border-solid bg-gradient-to-br from-stone-200 to-stone-400">
              Coming soon
            </div>
            <div className="text-xs mt-2">
              This feature is coming soon. You will be able to customise your
              application form visually. Until then, you can customise the
              content of your application form by{' '}
              <a
                href="mailto:nic@vula.vc"
                className="underline hover:no-underline"
              >
                speaking to your Vula account manager.
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* APPLICATION WEBSITE PREVIEW  */}
      <div
        className="w-full m-auto overflow-scroll h-[100vh]"
        style={{
          backgroundColor: backgroundColor,
          // Set background image to the dot.svg file
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><circle cx="10" cy="10" r="0.5" fill="%238f8f8f" /></svg>')`,
          /* Set background repeat to repeat both horizontally and vertically */
          backgroundRepeat: 'repeat',
        }}
      >
        <div className=" ">
          {/* a false url search section like a browser where we show the url of the website  */}
          <div className="bg-white shadow p-2 ">
            <div className="flex items-center w-fit m-auto justify-center rounded-full px-48 bg-stone-100">
              <div className=" p-2 text-sm">🔎</div>
              <div className=" pl-4 p-2 text-sm">
                {demoSMEFunder.rootURL + '/innovation-fund/apply'}
              </div>
            </div>
          </div>
          {/* HEADER SECTION WITH IMAGE AND TITLE */}
          <div className="max-w-[1000px] m-auto  ">
            <div className="mx-4 shadow rounded-xl mt-4">
              <img
                className="w-full max-h-[400px] object-cover overflow-hidden rounded-t-xl"
                src="https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/website-image.png"
                alt="website design"
              />
              <div className="text-left bg-white p-4 rounded-b-xl">
                <h1 className="text-3xl">
                  {' '}
                  {demoSMEFunder.name + ' Programme'}
                </h1>
                <p>
                  {demoSMEFunder.name} accelerates digital tech solutions
                  addressing key global challenges.
                </p>
                <p className="text-xs pt-2">
                  Estimated time to complete is 7 minutes
                </p>
              </div>
            </div>

            {/* SECTION 1 */}
            <div className="mx-4 mt-4 shadow rounded-xl overflow-hidden bg-white p-4">
              <h2 className="text-xl font-bold text-center mt-4">
                Have a Vula account already?
              </h2>
              <button
                className="flex m-auto items-center rounded-full w-fit p-2 px-4 shadow-xl bg-white hover:bg-stone-50 hover:scale-[0.98] my-8 border border-stone-200 border-solid
            "
              >
                <img src="/logo.svg" alt="logo" className="w-8 h-8" />
                <div className="font-bold pl-4">apply with vula </div>
              </button>
              <div className="flex items-center w-full ">
                <div className="w-full mx-12 border border-1 border-stone-100 border-solid rounded-xl " />
                <div className="flex items-center">or</div>
                <div className="w-full mx-12 border border-1 border-stone-100 border-solid rounded-xl " />
              </div>
              <h2 className="text-xl font-bold text-center mt-4">
                Start by telling Vula what you do
              </h2>
              <div className="text-sm text-center ">
                Vula can then help drafting the best answers.
              </div>
              <div className="flex flex-col justify-center bg-white p-3 rounded-xl max-w-[80%] m-auto">
                <UploadZone
                  instructions="Upload any data to help Vula understand your business"
                  label="Upload data"
                  afterUpload={url => console.log(url)}
                />
                <div className="flex flex-row justify-center items-center">
                  <div className="w-full mx-12 border border-1 border-stone-100 border-solid rounded-xl " />
                  <div className="flex items-center">or</div>
                  <div className="w-full mx-12 border border-1 border-stone-100 border-solid rounded-xl " />
                </div>

                <div className="flex flex-row justify-center items-center mt-4">
                  <input
                    type="text"
                    placeholder="your-website.com"
                    className="shadow text-center bg-stone-100  mr-4 w-1/2 p-2 border border-stone-200 border-solid rounded-xl "
                  />
                  <button
                    className="flex items-center rounded-xl w-fit p-2 px-4 bg-white hover:bg-stone-50 hover:scale-[0.98] border border-stone-200 border-solid
            "
                  >
                    <div className="">Submit</div>
                  </button>
                </div>
              </div>
            </div>

            {/* section 2: Read more about the programme here  */}
            <div className="mx-4 my-4 shadow rounded-xl overflow-hidden bg-white p-4">
              <h2 className="text-xl font-bold text-center mt-4">
                Read more about the programme
              </h2>
              <div className="text-sm text-center ">
                Learn more about the programme and what it offers.
              </div>
              <div className="flex flex-row justify-center pt-2">
                <Button text="Read more" size="small" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
