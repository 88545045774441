import React from 'react';

import {
  FinProductContext,
  question,
} from '../../../contexts/FinProductContext';
import BalanceSheetAnalysis from './BalanceSheetAnalysis';
import DocumentRender from './DocumentRender';
import GenericAnalysis from './GenericAnalysis';
import FAKEIDAnalysis from './FAKEIDAnalysis';
import FAKEPLAnalysis from './FAKEPLAnalysis';

export default function DocAnalysis() {
  const { selectedProductApplication, uploadReviewId } =
    React.useContext(FinProductContext);

  const [selectedQuestion, setSelectedQuestion] =
    React.useState<question | null>(null);

  const findQuestion = (questionId: string) => {
    let question: undefined | question = undefined;

    selectedProductApplication?.sections.forEach(section => {
      const found_question = section.questions.find(
        question => question.id === questionId,
      );
      if (found_question) {
        question = found_question;
      }
    });

    if (!question) return null;

    //remove all the undefined values

    return question;
  };

  // on load find the data
  React.useEffect(() => {
    if (!uploadReviewId) return;

    const question = findQuestion(uploadReviewId);

    if (question) {
      setSelectedQuestion(question);
    }
  }, [uploadReviewId, selectedQuestion, selectedProductApplication]);

  if (!selectedQuestion)
    return (
      <div className="flex flex-row bg-white rounded-lg p-6 ">
        <div className="text-2xl font-bold text-center">No document found.</div>
      </div>
    );

  if (selectedQuestion?.corpus_label.includes('balance_sheet')) {
    return <BalanceSheetAnalysis selectedQuestion={selectedQuestion} />;
  }

  if (selectedQuestion?.corpus_label.includes('ID_document_fake_demo')) {
    return <FAKEIDAnalysis selectedQuestion={selectedQuestion} />;
  }
  if (selectedQuestion?.corpus_label.includes('p&l_statement_fake_demo')) {
    return <FAKEPLAnalysis selectedQuestion={selectedQuestion} />;
  }

  return <GenericAnalysis selectedQuestion={selectedQuestion} />;
}
