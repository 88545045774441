import React from 'react';
import VulaLogo from '../utils/VulaLogo';
import LoginButton from '../../interfaces/auth0/LoginButton';
import LogoutButton from '../../interfaces/auth0/LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';

export default function LandingHeader() {
  const { isAuthenticated } = useAuth0();
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return (
    <div className="bg-white flex justify-center text-left">
      <div className="min-h-[100vh] h-auto max-w-[1400px] w-full flex justify-center">
        <div className=" flex flex-col items-center justify-center text-center w-full pb-12">
          <div className="flex flex-col items-center justify-center text-center bg-white shadow-2xl p-20 rounded-2xl">
            <div className="">
              <VulaLogo />
            </div>
            <h1
              className={
                'fade-in m-auto font-extrabold text-5xl sm:text-6xl lg:text-8xl mt-8 '
              }
              style={{ fontWeight: 1900 }}
            >
              <div
                className={
                  'opacity-0 transition-opacity duration-500 ease-in-out' +
                  (show ? ' opacity-100' : ' ')
                }
              >
                Partners
              </div>
              <div
                className={
                  'opacity-0 transition-opacity delay-300 duration-500 ease-in-out py-6' +
                  (show ? ' opacity-100' : ' ')
                }
              >
                of
              </div>
              <div
                className={
                  'animate-text bg-gradient-to-r bg-clip-text  text-transparent to-[#DF988D] via-[#E3AB58] from-[#BCC0CF]  px-4 opacity-0 transition-opacity delay-700 duration-500 ease-in-out' +
                  (show ? ' opacity-100' : ' ')
                }
              >
                Vula
              </div>
            </h1>
            <div className={'my-8 sm:my-20 '}></div>

            <LoginButton type={'login'} />
            <a
              href="mailto:nic@vula.vc"
              className="text-sm text-gray-500 mt-4 underline hover:no-underline"
            >
              or contact us here.
            </a>
            {isAuthenticated && <LogoutButton />}
          </div>
        </div>
      </div>
    </div>
  );
}
