import axios from 'axios';

import { apiUrl } from '../methods/env';

type Props = {
  token?: string;
};

export class VulaPartnersAPI {
  private token?: string;

  constructor(props?: Props) {
    this.token = props?.token;
  }

  async getPartnerConfig() {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.get(`${apiUrl}/partners/config`, config);
  }

  async getPresignedUrl(type: string) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.post(
      `${apiUrl}/partners/attachment/get_presigned_url?`,
      { type },
      config,
    );
  }

  async addToPartnerCorpus({
    content,
    corpus_type,
    corpus_subtype,
    corpus_label,
    relationship_id,
  }: {
    content: string;
    corpus_type: string;
    corpus_subtype: string;
    corpus_label: string;
    relationship_id: string;
  }) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.post(
      `${apiUrl}/partners/corpus`,
      {
        content,
        corpus_type,
        corpus_subtype,
        corpus_label,
        relationship_id,
      },
      config,
    );
  }
}
