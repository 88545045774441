import React from 'react';
import Mermaid from './ScoreCardMermaid';
import GridBackground from '../utils/GridBackground';
import { demoSMEFunder } from '../../data/demoSMEFunder';

export default function ScoreCardHome() {
  const [selectedScoreCard, setSelectedScoreCard] = React.useState(0);

  const scoreCards = [
    {
      name: demoSMEFunder.name + ' score - 2024',
      logo: demoSMEFunder.logo,
      definition: `
      %%{init: { "flowchart": { "curve": "monotoneX" } } }%%
      graph LR;
  A( Current Ratio) --> B(Weighted);
  C( Quick Ratio) --> B;
  D[<span style='min-width: 80px; border-radius:20px; display: block;'><div>Experian Score</div><img src='https://logos-world.net/wp-content/uploads/2021/03/Experian-Logo.png' width='40' height='40' /><span>]--> B;
  P[<span style='min-width: 80px; border-radius:20px; display: block;'><div>Tugende Score</div><img src='https://gotugende.com/wp-content/uploads/2020/01/Tugende_Wordmark_White_RGB_Outline.png' width='40' height='40' /><span>]--> B;
  Q[<span style='min-width: 80px; border-radius:20px; display: block;'><div>Fair Score</div><img src='https://media.licdn.com/dms/image/D4D0BAQGf3UzmWdeYKQ/company-logo_200_200/0/1711347211705/fairscore_logo?e=2147483647&v=beta&t=liBP8dTjSaC9qNeoJ0VPUr3d69lysasc2mc4Z2FcMbA' width='40' height='40' /><span>]--> B;
  E( Working Capital) --> B;
  F( Current Assets) --> B;
  I(Top 5 Customer Concentration) --> J(Customer Concentration Score);
  B --> H[Weighted Financial Score];
  J --> K(Adjusted Customer Concentration Score);
  H --> M[Intermediate Score];
  K --> M;
  M --> N[Composite Score];
  N --> O[Final Score];
  
  
      style A fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style B fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style C fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style D fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style E fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style F fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style H fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style I fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style J fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style K fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style M fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style N fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style O fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style P fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style Q fill:#EaEaE9,stroke:#ddd,stroke-width:1px;

      `,
    },
    {
      name: demoSMEFunder.name + ' score - 2023',
      logo: demoSMEFunder.logo,
      definition: `
      %%{init: { "flowchart": { "curve": "monotoneX" } } }%%
      graph LR;
  A( Current Ratio) --> B(Weighted);
  C( Quick Ratio) --> B;
  F( Current Assets) --> B;
  I(Top 5 Customer Concentration) --> J(Customer Concentration Score);
  B --> H[Weighted Financial Score];
  J --> K(Adjusted Customer Concentration Score);
  H --> M[Intermediate Score];
  K --> M;
  M --> N[Composite Score];
  N --> O[Final Score];
  
  
      style A fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style B fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style C fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style F fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style H fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style I fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style J fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style K fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style M fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style N fill:#EaEaE9,stroke:#ddd,stroke-width:1px;
      style O fill:#EaEaE9,stroke:#ddd,stroke-width:1px;

      `,
    },
    {
      name: 'Experian Score',
      logo: 'https://logos-world.net/wp-content/uploads/2021/03/Experian-Logo.png',
      definition: `
      %%{init: { "flowchart": { "curve": "monotoneX" } } }%%
      graph LR;
      D[<span style='min-width: 80px; border-radius:20px; display: block;'><div>Experian Score</div><img src='https://logos-world.net/wp-content/uploads/2021/03/Experian-Logo.png' width='40' height='40' /><span>]--> B;
      B[FinalScore]
      `,
    },
    {
      name: 'Tugende Score',
      logo: 'https://gotugende.com/wp-content/uploads/2020/01/Tugende_Wordmark_White_RGB_Outline.png',
      definition: `
      %%{init: { "flowchart": { "curve": "monotoneX" } } }%%
      graph LR;
      P[<span style='min-width: 80px; border-radius:20px; display: block;'><div>Tugende Score</div><img src='https://gotugende.com/wp-content/uploads/2020/01/Tugende_Wordmark_White_RGB_Outline.png' width='40' height='40' /><span>]--> B;
      B[FinalScore]
      `,
    },
    {
      name: 'Fair Score',
      logo: 'https://media.licdn.com/dms/image/D4D0BAQGf3UzmWdeYKQ/company-logo_200_200/0/1711347211705/fairscore_logo?e=2147483647&v=beta&t=liBP8dTjSaC9qNeoJ0VPUr3d69lysasc2mc4Z2FcMbA',
      definition: `
      %%{init: { "flowchart": { "curve": "monotoneX" } } }%%
      graph LR;
      Q[<span style='min-width: 80px; border-radius:20px; display: block;'><div>Fair Score</div><img src='https://media.licdn.com/dms/image/D4D0BAQGf3UzmWdeYKQ/company-logo_200_200/0/1711347211705/fairscore_logo?e=2147483647&v=beta&t=liBP8dTjSaC9qNeoJ0VPUr3d69lysasc2mc4Z2FcMbA' width='40' height='40' /><span>]--> B;
      B[FinalScore]
      `,
    },
    {
      name: 'TransUnion Score',
      logo: 'https://www.transunion.co.za/content/dam/transunion/global/common/logos/logo.svg',
      definition: `
      %%{init: { "flowchart": { "curve": "monotoneX" } } }%%
      graph LR;
      Q[<span style='min-width: 80px; border-radius:20px; display: block;'><div>TransUnion Score</div><img src='https://www.transunion.co.za/content/dam/transunion/global/common/logos/logo.svg' width='40' height='40' /><span>]--> B;
      B[FinalScore]
      `,
    },
  ];
  // Use experian, use fairscore, use other
  // custom score definition
  return (
    <div className="max-w-[70vw]">
      <div className="rounded-lg bg-white p-4 shadow-md m-4 text-left ">
        <h1 className="text-4xl font-bold text-gray-900">Score Card</h1>
        <p className="text-sm pt-2">
          The algorithm to calculate application scorecards.
        </p>
      </div>

      <div className="rounded-lg bg-white p-4 shadow-md m-4 text-left flex flex-col      ">
        <div className="flex  overflow-x-scroll no-scrollbar ">
          {
            // a rounded corner card with a title, image and description which on click can be selected (where a small tick appears on the bottom right corner of the card)
            scoreCards.map((scoreCard, index) => (
              <div
                key={index}
                className="p-4"
                onClick={() => setSelectedScoreCard(index)}
              >
                <div
                  className={`relative min-h-full min-w-[300px] max-w-[300px] relative bg-white rounded-xl p-4 border border-stone-300 border-solid ${
                    selectedScoreCard === index
                      ? 'border-green-200 bg-green-50'
                      : ''
                  }`}
                >
                  {scoreCard.logo && (
                    <div className="mt-2">
                      <img
                        src={scoreCard.logo}
                        alt={scoreCard.name}
                        className=" h-6 rounded-xl"
                      />
                    </div>
                  )}
                  <div className="flex items-center justify-between">
                    <div className="text-lg font-bold">{scoreCard.name}</div>
                    {/* tick if selected */}
                    <div className="absolute bottom-2 right-2 bg-stone-100 h-8 w-8 rounded-full"></div>

                    {selectedScoreCard === index ? (
                      <div className="absolute bottom-2 right-2 text-center items-center flex justify-center text-green-500 h-8 w-8 ">
                        ✔
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className="text-sm pt-2 pl-4">
          For any changes,{' '}
          <a className="underline hover:underline" href="mailto:nic@vula.vc">
            please contact your Vula representative.
          </a>
        </div>
      </div>

      <div className="rounded-lg bg-white p-4 shadow-md m-4 text-left max-h-[80vh]">
        <GridBackground backgroundColor="#ffffff" fullHeight={false}>
          <Mermaid
            id="score-card-mermaid"
            chart={scoreCards[selectedScoreCard].definition}
          />
        </GridBackground>
      </div>
    </div>
  );
}
