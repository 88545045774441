import React from 'react';
import Button from '../utils/Button';
import CreateParamPill from '../utils/CreateParam';

interface Props {
  nextStep: () => void;
}
export default function EstimatedEligibility(props: Props) {
  return (
    <div className=" flex justify-between w-full  ">
      {/* LHS */}
      <div className="w-full flex flex-col items-start justify-between text-left  p-4">
        <div>
          <div className="text-3xl">🔬 Eligibility</div>

          <div className="mt-4">
            Please define the eligibility criteria for the programme. This will
            be used to determine which businesses are eligible to apply for the
            programme.
          </div>
          <div className="mt-4 bg-stone-100 p-4 rounded-xl ">
            <div className="text-lg font-bold">Eligible Countries</div>
            {/* <div className="mt-2">
              Kenya, Uganda, Tanzania, Rwanda, Ethiopia, South Sudan, Somalia,
              Burundi, Djibouti, Eritrea, Comoros, Seychelles, Mauritius,
              Madagascar, Mozambique, Malawi, Zambia, Zimbabwe{' '}
            </div> */}
            <CreateParamPill
              type="geography"
              preselected={
                //   [
                //   {
                //     type: 'geography',
                //     text: 'Kenya',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Uganda',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Tanzania',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Rwanda',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Ethiopia',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'South Sudan',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Somalia',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Burundi',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Djibouti',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Eritrea',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Comoros',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Seychelles',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Mauritius',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Madagascar',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Mozambique',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Malawi',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Zambia',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Zimbabwe',
                //   },
                // ]
                // [
                //   {
                //     type: 'geography',
                //     text: 'South Africa',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Lesotho',
                //   },
                //   {
                //     type: 'geography',
                //     text: 'Eswatini',
                //   },
                [
                  {
                    type: 'geography',
                    text: 'Ghana',
                  },
                  {
                    type: 'geography',
                    text: 'Nigeria',
                  },
                  {
                    type: 'geography',
                    text: 'Togo',
                  },
                  {
                    type: 'geography',
                    text: 'Benin',
                  },
                  {
                    type: 'geography',
                    text: 'Cameroon',
                  },
                  {
                    type: 'geography',
                    text: 'Côte d’Ivoire',
                  },
                ]
              }
            />
            <div className="text-lg font-bold mt-4">Eligible Sectors</div>
            <CreateParamPill
              type="vertical"
              preselected={[
                {
                  type: 'vertical',
                  text: 'Agriculture',
                },
                {
                  type: 'vertical',
                  text: 'Health',
                },
                {
                  type: 'vertical',
                  text: 'Education',
                },
                {
                  type: 'vertical',
                  text: 'Finance',
                },
                {
                  type: 'vertical',
                  text: 'Energy',
                },
              ]}
            />

            {/* <div className="text-lg font-bold mt-4">Eligible Organisations</div>

            <CreateParamPill
              type="organisation"
              preselected={[
                // {
                //   type: 'organisation',
                //   text: 'NGOs',
                // },
                {
                  type: 'organisation',
                  text: 'Private Sector',
                },
              ]}
            /> */}
          </div>
        </div>

        {/* state the number of businesses on Vula that are eligible, and the number of businesses that exist that would qualify, and the estimate in marketing spend it will cost to reach these. Put these in 3 square boxes with the number in large and the text below  */}
        <div>
          {/* <div className="mt-4">Vula estimates:</div>
          <div className="flex  w-full mt-2">
            {[
              {

                numberString: '673',
                text: 'Businesses eligible on Vula',
              },
              {

                numberString: '10,000',
                text: 'Businesses that would qualify',
              },
              {

                numberString: 'R 100,000',
                text: 'Estimated marketing spend',
              },
            ].map(({ numberString, text }) => (
              <div className="bg-stone-100 p-2 rounded-xl w-1/4 mr-2 flex justify-between flex-col h-[100px]">
                <div className="text-xl font-bold">{numberString}</div>
                <div className="text-sm">{text}</div>
              </div>
            ))}
          </div> */}
        </div>
      </div>

      {/* RHS */}
      {/* Maybe we can do something with these SVGs in future? https://commons.wikimedia.org/wiki/Category:SVG_labeled_maps_of_administrative_divisions_of_countries_of_Africa_(location_map_scheme)_(monochrome_scheme) */}
      {/* <div className="w-full flex flex-col items-center justify-center">
        <img
          className=" "
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Map_of_East_African_countries.svg/1200px-Map_of_East_African_countries.svg.png"
        />
      </div> */}
    </div>
  );
}
