import React, { useState } from 'react';

interface TextAreaProps {
  placeholder?: string;
  initialValue?: string;
}

export const TextArea: React.FC<TextAreaProps> = ({
  placeholder,
  initialValue,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(initialValue || '');

  if (editMode) {
    return (
      <div onClick={() => setEditMode(true)}>
        <div className="relative pt-4">
          <label className="absolute top-2 right-8 bg-stone-50 w-[80px] text-center text-xs font-bold">
            Question
          </label>
          <input
            id="question"
            type="text"
            placeholder="Enter your question here"
            defaultValue="Write a short description"
            className="w-full p-2 pt-3 px-4 border border-solid border-1 font-bold border-stone-200 rounded-lg"
          />
        </div>
        <div className="relative pt-4">
          <label className="absolute top-2 right-8 bg-stone-50 w-[80px] text-center text-xs font-bold">
            Description
          </label>
          <textarea
            id="description"
            value={value}
            onChange={e => setValue(e.target.value)}
            placeholder={placeholder}
            className="mt-2 w-full bg-stone-100 p-3 rounded-xl h-32"
          />
        </div>
      </div>
    );
  }

  return (
    <div onClick={() => setEditMode(true)}>
      <div className="relative">
        <div className="font-bold pl-2 py-2">{placeholder}</div>
        <textarea
          id="description"
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder={placeholder}
          className="mt-2 w-full bg-stone-100 p-3 rounded-xl h-32"
        />
      </div>
    </div>
  );
};
