import React from 'react';
import InputField from './InputField';

interface Props {
  type: string;
  placeholder?: string;
  initialValue?: string;
}

export default function QuestionItem(props: Props) {
  return (
    // a div which on the top will have a type selector which will go into the inputField component and then the inputField component will have a type prop which will determine the type of input field to render
    // and direction arrows to help reorder the questions
    // these will only be visible when the user focuses on this question and will be hidden otherwise
    <div className="group p-2 px-6">
      {/* <div className="flex justify-between items-center opacity-0 h-0  group-hover:h-2 group-hover:opacity-100 group-hover:pb-2 transition-all duration-500">
        <div className=" flex w-fit items-center">
          icons for the types which on hover show the label
          <button className="mr-2 hover:scale-[1.1] hover:bg-stone-200">
            📝
          </button>
          <button className="mr-2 hover:scale-[1.1] hover:bg-stone-200">
            📅
          </button>
          <button className="mr-2 hover:scale-[1.1] hover:bg-stone-200">
            🔢
          </button>
          <button className="mr-2 hover:scale-[1.1] hover:bg-stone-200">
            🔗
          </button>
          <button className="mr-2 hover:scale-[1.1] hover:bg-stone-200">
            📎
          </button>
          <button className="mr-2 hover:scale-[1.1] hover:bg-stone-200">
            📷
          </button>
          <button className="mr-2 hover:scale-[1.1] hover:bg-stone-200">
            🔒
          </button>
        </div>
        </div> */}
      <InputField
        type={props.type}
        placeholder={props.placeholder}
        initialValue={props.initialValue}
      />
    </div>
  );
}
