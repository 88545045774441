import React, { useContext, useEffect } from 'react';
import Button from '../../utils/Button';
import CopyIcon from '../../Icons/CopyIcon';
import {
  FinProductContext,
  question,
} from '../../../contexts/FinProductContext';
import { VulaPartnerProductsAPI } from '../../../api/products';
import { useAuth0 } from '@auth0/auth0-react';
import { ConfigContext } from '../../../contexts/ConfigContext';

interface Props {
  question: question;
}

export default function PrivateFormLinkActions(props: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const { config } = useContext(ConfigContext);
  const { selectedProductApplication, applications } =
    useContext(FinProductContext);
  const [linkCreated, setLinkCreated] = React.useState(false);
  const [reminderSent, setReminderSent] = React.useState(false);

  useEffect(() => {
    // if the question has an answer then the link has been created
    if (props.question.answer) {
      setLinkCreated(true);
    }
  }, [props.question]);

  const inviteCompanyToNextStage = async () => {
    if (
      selectedProductApplication?.id === undefined ||
      selectedProductApplication?.id === undefined
    ) {
      return;
    }

    // get the application id // todo: add this into the context
    const this_application = applications.find(
      app => app.company.name === selectedProductApplication?.company.name,
    );
    if (!this_application) return;

    // call the api to invite the company to the next stage
    const token = await getAccessTokenSilently();
    const api = new VulaPartnerProductsAPI({ token });
    api.inviteCompanyToNextStage({
      application_id: this_application.id,
      product_id: selectedProductApplication?.id,
      stage: 'dd', // hack TODO: change this to a proper stage
    });
  };

  const renderAlreadyCreatedLink = () => {
    return (
      <>
        <div className=" p-4 ">
          <div className=" font-bold ">Private link sent</div>
          <div className="text-stone-500 text-xs mt-2">
            {props.question.answer}
          </div>
          <button
            className="rounded flex flex-row w-full justify-between mt-4 hover:bg-stone-200  transition-transform duration-200 ease-in-out transform active:scale-[0.99]
        "
          >
            <div className="rounded-lg text-left text-sm w-full text-stone-900  border border-stone-100 border-solid border-1  w-full  items-center flex justify-between       ">
              <div className="p-2 w-full">
                https://www.vula.vc/apply/{config.customisations?.partner_slug}/
                {selectedProductApplication?.slug}
              </div>
              <div className="text-sm text-white p-2 bg-stone-300 rounded-r-lg">
                <CopyIcon />
              </div>
            </div>
          </button>
        </div>
        {/* divider */}
        <div className="border border-stone-100 border-solid border-1 mt-4 "></div>
        <div className=" p-4 bg-stone-50">
          {/* action buttons to send a reminder email or to draft and email manually */}
          <div>
            <div className="font-bold mb-2 ">Actions</div>
            <div className="flex ">
              {/* action cards */}
              {/* <div className="mr-2 bg-white rounded-lg shadow p-4 w-1/2">
                <Button
                  text="Send Reminder"
                  action={() => {
                    setReminderSent(true);
                  }}
                />
                <div className="text-stone-400 text-[11px]  mt-1">
                  Vula will send a reminder email to the user to upload their DD
                  docs
                </div>
              </div> */}
              <div className="mx-2 bg-white rounded-lg shadow p-4 w">
                <Button
                  text="Draft Email"
                  action={() => {
                    // mailto email prepopulated with the link and the user email
                    const email = selectedProductApplication?.sections
                      .find(
                        // go through each section and find the question where the label is "business_email"
                        section =>
                          section.questions.find(
                            question => question.label === 'business_email',
                          ),
                      )
                      ?.questions.find(
                        question => question.label === 'business_email',
                      )?.answer;
                    if (!email) {
                      console.error('No email found');
                    }
                    const subject = 'Reminder to upload your DD docs';
                    const body = `Hello ${selectedProductApplication?.company.name}, This is a reminder that we are excited to learn more about your business and would like to move forwards with our deal. Please upload your DD docs using the following link: https://www.vula.vc/apply/${config.customisations?.partner_slug}/${selectedProductApplication?.slug} `;

                    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
                      subject,
                    )}&body=${encodeURIComponent(body)}`;

                    window.open(mailtoLink);
                  }}
                />
                <div className="text-stone-400 text-[11px]  mt-4">
                  Vula will open your default email client with a draft email
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderLinkGeneration = () => {
    return (
      <div className=" flex flex-row">
        <div className=" p-4 w-2/3 flex flex-col justify-between">
          <div>
            <div className=" text-lg ">Invite to send DD docs</div>
            <div className="text-sm text-stone-500">
              This will send them an email with a link to a form where they can
              upload their DD docs.
            </div>
          </div>
          <div className="flex flex-row justify-between ">
            <Button
              text="Send Link"
              size="small"
              action={async () => {
                setLinkCreated(true);
                await inviteCompanyToNextStage();
              }}
            />
          </div>
        </div>
        <div className="bg-stone-50 w-1/3">
          {/* a mock of an email (white bg with shadow) with some copy to invite the user to apply */}
          <div className="h-full mx-4 mt-4 px-8 pt-8 pb-1 bg-white rounded-lg shadow-lg">
            <div className="text-stone-500 text-xs">
              Hello {selectedProductApplication?.company.name},
            </div>
            <div className="text-stone-500 text-xs mt-2">
              We are excited to learn more about your business and would like to
              move forwards with our deal. Please upload your DD docs using the
              following link:
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className=" bg-white overflow-hidden rounded-lg drop-shadow-lg border border-stone-100 border-solid border-1 m-4">
      {linkCreated ? renderAlreadyCreatedLink() : renderLinkGeneration()}
    </div>
  );
}
