import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../../components/utils/Button';
import Spinner from '../../components/utils/Spinner';
import { capitalizeFirstLetter } from '../../methods/UIHelpers';
import { track } from '../mixpanel';

interface Props {
  type: 'signup' | 'login';
  buttonType?: 'secondary' | 'plain';
  skipWarning?: boolean;
}
export default function LoginButton(props: Props) {
  const { loginWithRedirect } = useAuth0();
  const [spinnerVis, showSpinnerVis] = useState(false);

  if (spinnerVis) {
    return <Spinner />;
  }

  const signIn = () => {
    track(props.type);
    showSpinnerVis(true);
    loginWithRedirect({
      screen_hint: props.type,
      redirectUri: window.location.origin + '/onlogin' + window.location.search,
    });
  };

  if (props.buttonType === 'plain') {
    return (
      <button
        className="hover:underline no-underline px-4"
        onClick={() => signIn()}
      >
        {capitalizeFirstLetter(props.type)}
      </button>
    );
  } else {
    return (
      <Button
        buttonType={props.buttonType}
        text={capitalizeFirstLetter(props.type)}
        action={() => signIn()}
      ></Button>
    );
  }
}
