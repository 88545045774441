import React from 'react';

interface Props {
  size?: 'xs' | 'sm' | 'm' | 'lg';
}

export default function VulaLogo(props: Props) {
  const size =
    props.size === 'lg'
      ? ' w-16 sm:w-28 h-16 sm:h-28'
      : props.size === 'xs'
      ? ' w-3 sm:w-5 h-3 sm:h-5'
      : props.size === 'sm'
      ? ' w-8 sm:w-10 h-8 sm:h-10'
      : ' w-10 sm:w-12 h-10 sm:h-12';

  return (
    <div className="flex justify-center items-center">
      <img
        src="/vula-sun.png"
        alt="logo of vula"
        className={'hover:fill-current ' + size}
      />
    </div>
  );
}
