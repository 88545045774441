import React from 'react';
import GenericChatInterface from './GenericChatInterface';
import { FinProductContext } from '../../../contexts/FinProductContext';

export default function AskFunder() {
  const { selectedProductApplication, isLoadingApplication } =
    React.useContext(FinProductContext);
  const partner = selectedProductApplication?.owner;
  const company = selectedProductApplication?.company;
  if (!partner) return null;
  if (!company) return null;

  return (
    <div className="flex flex-col justify-between p-2 overflow-scroll  no-scrollbar ">
      {/* a circle with an image url in it in the center of the page */}
      <div className="h-fit flex flex-col items-center justify-center p-2 rounded-xl drop-shadow-lg bg-white">
        {isLoadingApplication ? (
          <div className="animate-pulse bg-stone-200 opacity-20 h-[50px] w-[50px] rounded-full"></div>
        ) : (
          <div className="relative">
            <img
              className="h-[50px] p-2 drop-shadow rounded-full bg-white"
              src={'/vula-sun.png'}
            />
            {company.logo?.length ? (
              <img
                className="h-[25px] p-1 drop-shadow rounded-full bg-white absolute -bottom-1 -right-4"
                src={company.logo}
              />
            ) : null}
          </div>
        )}

        {isLoadingApplication ? (
          <div className="animate-pulse bg-stone-200 opacity-20 my-2 rounded h-[25px] w-[50px]"></div>
        ) : (
          <div className="text-center font-bold my-2">Ask Vula</div>
        )}

        {isLoadingApplication ? (
          <div className="w-[80%] animate-pulse bg-stone-200 opacity-20 h-[25px] rounded"></div>
        ) : (
          <div className="text-xs w-[80%]">
            Vula makes all previous case data easily queryable, and also
            understands {company.name}'s business.
          </div>
        )}
      </div>

      <div className="min-h-full"></div>

      <div className="h-[60vh] no-scrollbar">
        <GenericChatInterface
          partner_id={partner.id}
          company_id={company.id}
          botIcon={partner.logo}
          defaultMessages={[
            'Have we invested in similar companies to this?',
            'Should we invest in this company?',
          ]}
          isLoading={isLoadingApplication}
        />
      </div>
    </div>
  );
}
