import React, { useEffect, useRef } from 'react';
import ArrowIcon from '../Icons/ArrowIcon';
import PencilIcon from '../Icons/PencilIcon';
import StarIcon from '../Icons/StarIcon';
import VulaLogo from '../utils/VulaLogo';
import { routes } from '../../pages/routes';
import { ConfigContext } from '../../contexts/ConfigContext';
import TableOfCompanies from '../CompanyNavigatorView/TableOfCompanies';
export default function HomeSearchBar() {
  const { config } = React.useContext(ConfigContext);
  const [isFocused, setIsFocused] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  // define your ref
  const searchComponentRef = useRef<HTMLDivElement | null>(null);

  const filters = [
    { name: 'Recent', id: 'filters-recent' },
    { name: 'My tasks', id: 'filters-my-tasks' },
    { name: 'My programmes', id: 'filters-my-programmes' },
    { name: 'Team members', id: 'filters-team-members' },
    { name: 'Search archive', id: 'search-archive' },
  ];

  const myTasks = [
    {
      title: 'Review Goody Thokoman application',
      id: 'review-goody-thokoman-application',
      link: routes.product + '/21321hjbasdbhjsadhjbasdbhjdsa324324',
    },
    {
      title: 'Review Pakinatha Brais application',
      id: 'review-pakinatha-brais-application',
      link: routes.product + '/21321hjbasdbhjsadhjbasdbhjdsa324324',
    },
  ];

  const commonActions = [
    {
      icon: <PencilIcon />,
      title: 'Create a new programme',
      id: 'actions-create-a-new-programme',
      link: routes.create_new_programme,
      visible:
        !!config.features?.searchBar?.components?.create_new_programme?.visible,
    },
    {
      icon: <StarIcon />,
      title: 'Review analytics',
      link: routes.application_overview,
      id: 'actions-review-analytics',
      visible:
        !!config.features?.searchBar?.components?.review_analytics?.visible,
    },
    // {
    //   icon: <NewTabIcon />,
    //   title: 'Launch IC view',
    //   id: 'actions-launch-ic-view',
    //   visible: config.features?.searchBar?.components?.launch_ic_view.visible ?? true,
    // },
    {
      icon: <VulaLogo size="xs" />,
      title: 'Ask Vula',
      id: 'actions-ask-vula',
      visible: !!config.features?.searchBar?.components?.ask_vula?.visible,
    },
  ];

  const [filteredFilters, setFilteredFilters] = React.useState(filters);
  const [filteredMyTasks, setFilteredMyTasks] = React.useState(myTasks);
  const [filteredActions, setFilteredActions] = React.useState<
    {
      icon: JSX.Element;
      title: string;
      id: string;
      link?: string;
      visible?: boolean;
    }[]
  >(commonActions);

  // Filter the filters and actions based on the search text
  useEffect(() => {
    setFilteredFilters(
      filters.filter(filter =>
        filter.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
    setFilteredActions(
      commonActions.filter(action =>
        action.title.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
    setFilteredMyTasks(
      // always allow Ask Vula to show (but this happens in code not in filters)
      myTasks.filter(task =>
        task.title.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [searchText]);

  // Add the event listener for the click outside
  useEffect(() => {
    // Define the click handler
    // It checks if the click was outside of the component
    const handleClickOutside = (event: MouseEvent) => {
      if (event && event.target) {
        if (
          searchComponentRef.current &&
          !searchComponentRef.current.contains(event.target as Node) // Typecast event.target as Node
        ) {
          setIsFocused(false); // If click was outside, close the search view
        }
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="min-h-[80px] relative max-w-[1600px]">
      <div
        className="w-full m-auto absolute z-20 "
        // add the ref to the component
        ref={searchComponentRef}
      >
        <div
          className={
            'transition-all duration-500  w-full ' +
            (isFocused
              ? ' bg-stone-100 shadow-[0_100px_600px_0px_rgba(0,0,0,0.8)] w-full h-full z-10 rounded-t-[2rem] rounded-b-3xl '
              : ' h-0')
          }
        >
          {/* search bar */}
          <div className="p-4">
            <div
              className={
                ' w-full rounded-full  bg-white p-4 px-8 flex items-center' +
                (isFocused ? ' z-50 ' : ' shadow z-0')
              }
            >
              <input
                type="text"
                placeholder="Search for companies, review applications, start new programmes, ask a question..."
                className="w-full bg-transparent outline-none"
                onFocus={() => setIsFocused(true)}
                value={searchText}
                onChange={event => setSearchText(event.target.value)}
              />
              <ArrowIcon
                pointingDirection="right"
                fill={!isFocused ? 'gray' : 'black'}
              />
            </div>
          </div>

          {/* search view */}
          <div
            className={
              'p-4 transition-all duration-500 flex flex-col justify-between h-full border border-t-1 border-x-0 border-b-0 border-stone-200 border-solid  ' +
              (isFocused ? ' block opacity-1 ' : ' hidden opacity-0')
            }
          >
            {/* filters */}
            {filteredFilters.length ? (
              <div className="flex items-center text-xs">
                <p className="pl-2 mr-4">Filters:</p>
                {filteredFilters.map((filter, index) => (
                  <div
                    className={
                      'group mr-4 hover:bg-stone-50 rounded-full transition-all duration-500   flex flex-col justify-between items-start text-left px-4 p-2' +
                      // if index is 0, add a border
                      (index === 0
                        ? ' border border-1 border-solid border-stone-700 bg-stone-200 '
                        : ' bg-white ')
                    }
                    key={filter.id}
                  >
                    <button
                      className="flex items-center "
                      onClick={() => console.log('hi')}
                    >
                      <p className=" ">{filter.name}</p>
                    </button>
                  </div>
                ))}
              </div>
            ) : null}

            {config?.features?.navigator?.visible && (
              <div className="max-h-[50vh] overflow-y-auto">
                <TableOfCompanies searchTerm={searchText} />
              </div>
            )}

            {/* Common actions */}
            {/* square button boxes with a title icon and subtitle */}
            {/* box1 should be create a new programme */}
            <div className="flex items-center pt-4 text-xs">
              {filteredActions.map(action => {
                if (action.visible === false) return null;
                return (
                  <div
                    className="group mr-4 bg-white rounded-xl transition-all duration-500   flex flex-col justify-between items-start text-left px-4 p-2"
                    key={action.id}
                  >
                    <button
                      className="flex items-center "
                      onClick={() => {
                        if (action.link) {
                          window.location.href = action.link;
                        }
                      }}
                    >
                      {action.icon}
                      <p className="pl-2 ">{action.title}</p>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
