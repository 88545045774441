import React, { useEffect } from 'react';
import ArrowIcon from '../../Icons/ArrowIcon';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaPartnerProductsAPI } from '../../../api/products';
import { FinProductContext } from '../../../contexts/FinProductContext';

interface Props {
  setShowUploads: (show: boolean) => void;
}
export default function ViewApplicationUploads(props: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const { selectedApplicationId: application_id, selectedProductApplication } =
    React.useContext(FinProductContext);
  const [data, setData] = React.useState<
    {
      id: string;
      label: string;
      content: string;
      filename: string;
    }[]
  >([]);

  // on load get the data
  useEffect(() => {
    // Fetch data from API
    (async () => {
      const token = await getAccessTokenSilently();
      const api = new VulaPartnerProductsAPI({ token });

      if (!selectedProductApplication?.id || !application_id) return;
      const response = await api.getAttachments(
        selectedProductApplication?.id,
        application_id,
      );
      setData(response.data);
    })();
  }, []);

  return (
    <div className="text-left w-full h-full">
      <div className="flex flex-col pt-4">
        {/* a back button */}
        <button
          onClick={() => props.setShowUploads(false)}
          className="flex flex-row items-center py-2 px-4 w-full border border-solid border-stone-200 rounded-lg"
        >
          <div className="font-light flex items-center text-sm">
            <ArrowIcon pointingDirection="left" />
            <p className="pl-4">Back</p>
          </div>
        </button>
        {data?.map(upload => {
          return (
            <div className="flex flex-row items-center rounded-xl bg-stone-50 shadow p-2 my-1 hover:scale-[0.99]">
              <a href={upload.content} target="_blank" rel="noreferrer">
                <div className="flex items-center justify-start">
                  <img
                    src="https://vula-assets.s3.eu-west-2.amazonaws.com/icons/file-check-alt.svg"
                    className="h-8 w-8 mr-2"
                  />
                  <p className="text-sm">{upload.filename}</p>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
