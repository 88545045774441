import React from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { routes } from '../../pages/routes';

interface Props {
  children: React.ReactNode;
}
const PrivateRoute = (props: Props) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <Navigate to={routes.launch} />;
  }

  return <>{props.children}</>;
};

export default withAuthenticationRequired(PrivateRoute, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Loading...</div>,
});
