import axios from 'axios';

import { apiUrl } from '../methods/env';

type Props = {
  token?: string;
};

export class VulaPartnerDocumentsAPI {
  private token?: string;

  constructor(props?: Props) {
    this.token = props?.token;
  }

  async getDocumentsAnalysis(company_id: string, document_label: string) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.get(
      `${apiUrl}/documents/${company_id}/${document_label}/analysis`,
      config,
    );
  }

  async updateDocumentAnalysis(analysis_id: string, value: string | number) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.patch(
      `${apiUrl}/documents/analysis/${analysis_id}`,
      { content: value.toString() },
      config,
    );
  }

  async createDocumentNote(document_id: string, content: string) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.post(
      `${apiUrl}/documents/${document_id}/note`,
      { content },
      config,
    );
  }
}
