import React, { useState } from 'react';

interface DocumentAgreementProps {
  placeholder?: string;
  initialValue?: string;
}

export const DocumentAgreement: React.FC<DocumentAgreementProps> = ({
  placeholder,
  initialValue,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [documentContent, setDocumentContent] = useState('');
  const [accepted, setAccepted] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = () => {
        setDocumentContent(reader.result as string);
      };
      reader.readAsText(event.target.files[0]);
    }
  };

  const handleAccept = () => {
    setAccepted(true);
  };

  if (editMode) {
    return (
      <div onClick={() => setEditMode(true)}>
        <div className="relative pt-4">
          <label className="absolute top-2 right-8 bg-stone-50 w-[80px] text-center text-xs font-bold">
            Question
          </label>
          <input
            id="question"
            type="text"
            placeholder="Enter your question here"
            defaultValue="Agree to terms and conditions"
            className="w-full p-2 pt-3 px-4 border border-solid border-1 font-bold border-stone-200 rounded-lg"
          />
        </div>
        <div className="relative pt-4">
          <label className="absolute top-2 right-8 bg-stone-50 w-[80px] text-center text-xs font-bold">
            Document
          </label>
          <input
            id="document-upload"
            type="file"
            onChange={handleFileChange}
            className="mt-2 w-full bg-stone-100 p-2 rounded-xl"
          />
          {file && <p>{file.name}</p>}
        </div>
        {documentContent && (
          <div className="mt-4">
            <h2 className="text-lg font-bold mb-2">Document Content:</h2>
            <pre className="bg-stone-100 p-4 rounded-lg whitespace-pre-wrap">
              {documentContent}
            </pre>
          </div>
        )}
        <button
          className="mt-4 bg-stone-500 text-white rounded-lg px-4 py-2"
          onClick={handleAccept}
          disabled={!documentContent}
        >
          I accept
        </button>
      </div>
    );
  }

  return (
    <div onClick={() => setEditMode(true)}>
      <div className="relative">
        <div className="text-4xl">📄</div>

        <div className="font-bold pl-2 py-2">{placeholder}</div>
        <div className="w-full p-2 border border-solid border-1 border-stone-200 rounded-lg">
          {accepted ? 'Accepted' : 'Not accepted'}
        </div>
      </div>
    </div>
  );
};
