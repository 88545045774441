import React from 'react';

import ToggleableApplicationSection from './ToggleableApplicationSection';

import { FinProductContext } from '../../contexts/FinProductContext';

export default function ApplicationQuestions() {
  const { selectedProductApplication } = React.useContext(FinProductContext);
  return (
    <div className="my-4">
      {selectedProductApplication?.sections?.map(section => (
        <div key={section.id}>
          {section.type === 'aip' || section.type === 'welcome' ? null : (
            <ToggleableApplicationSection section={section} />
          )}
        </div>
      ))}
    </div>
  );
}
