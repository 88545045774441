import React from 'react';

interface Props {
  title: string;
  image?: string;
  description: string;
  selected?: boolean;
}

export default function ViewCards(props: Props) {
  const { title, image, description } = props;
  const [selected, setSelected] = React.useState(props.selected || false);

  //   a rounded corner card with a title, image and description which on click can be selected (where a small tick appears on the bottom right corner of the card)
  return (
    <div className="p-4 hover:scale-[1.03] transition-all duration-[500]">
      <div
        className={`relative min-h-full relative bg-white rounded-xl p-4 border border-solid border-1 ${
          selected ? 'border-green-200' : '  border-stone-300'
        }`}
        onClick={() => setSelected(!selected)}
      >
        <div className="absolute bottom-2 right-2 bg-stone-100 h-8 w-8 rounded-full"></div>
        {selected ? (
          <div className="absolute bottom-2 right-2 text-center items-center flex justify-center text-green-500 h-8 w-8 ">
            ✔
          </div>
        ) : null}
        <div className="flex flex-col items-center justify-between">
          {image && (
            <div className="mt-2">
              <img
                src={image}
                alt={title}
                className="w-full h-48 object-cover rounded-xl"
              />
            </div>
          )}
          <div>
            <div className="text-lg font-bold">{title}</div>
            <div className="mt-2 text-sm">{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
