//from https://github.com/prutya/tutorial-multi-select-dropdown/blob/main/src/components/MultiSelectDropdown.jsx

// I thought this would save time but I am not sure.

import React, { useState, useEffect, useRef } from 'react';
interface Props {
  prompt: string;
  options: string[];
  formFieldName: string;
  onChange: (selectedOptions: string[]) => void;
}

export default function MultiSelectDropdown(props: Props) {
  const { prompt, options, formFieldName, onChange } = props;
  const [isJsEnabled, setIsJsEnabled] = useState<boolean>(false);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const optionsListRef = useRef(null);

  //   why?
  useEffect(() => {
    setIsJsEnabled(true);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const option = e.target.value;

    const selectedOptionSet = new Set<string>(selectedOptions);

    if (isChecked) {
      selectedOptionSet.add(option);
    } else {
      selectedOptionSet.delete(option);
    }

    const newSelectedOptions: string[] = Array.from(selectedOptionSet);

    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const isSelectAllEnabled = selectedOptions.length < options.length;

  const handleSelectAllClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // eslint-disable-next-line
    // @ts-ignore
    const optionsInputs = optionsListRef.current.querySelectorAll('input');

    optionsInputs.forEach((input: { checked: boolean }) => {
      input.checked = true;
    });

    setSelectedOptions([...options]);
    onChange([...options]);
  };

  const isClearSelectionEnabled = selectedOptions.length > 0;

  const handleClearSelectionClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();

    // eslint-disable-next-line
    // @ts-ignore
    const optionsInputs = optionsListRef.current.querySelectorAll('input');
    optionsInputs.forEach((input: { checked: boolean }) => {
      input.checked = false;
    });

    setSelectedOptions([]);
    onChange([]);
  };

  return (
    <div className="w-full mt-4">
      <label className="relative border border-solid border-1 border-stone-200 p-2 rounded-lg">
        <input type="checkbox" className="hidden peer" />

        <div className="w-fit cursor-pointer after:content-['▼'] after:text-xs after:ml-1 after:inline-flex after:items-center peer-checked:after:-rotate-180 after:transition-transform inline-flex border rounded px-5 py-2">
          {prompt}
          {isJsEnabled && selectedOptions.length > 0 && (
            <span className="ml-1 text-blue-500">{`(${selectedOptions.length} selected)`}</span>
          )}
        </div>

        <div className="absolute z-10 bg-white border transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto w-full max-h-60 overflow-y-scroll">
          {isJsEnabled && (
            <ul>
              <li>
                <button
                  onClick={handleSelectAllClick}
                  disabled={!isSelectAllEnabled}
                  className="w-full text-left px-2 py-1 text-blue-600 disabled:opacity-50"
                >
                  {'Select All'}
                </button>
              </li>
              <li>
                <button
                  onClick={handleClearSelectionClick}
                  disabled={!isClearSelectionEnabled}
                  className="w-full text-left px-2 py-1 text-blue-600 disabled:opacity-50"
                >
                  {'Clear selection'}
                </button>
              </li>
            </ul>
          )}
          <ul ref={optionsListRef}>
            {options.map((option, i) => {
              return (
                <li key={option}>
                  <label
                    className={`flex whitespace-nowrap w-full cursor-pointer px-2 py-1 transition-colors hover:bg-blue-100 [&:has(input:checked)]:bg-blue-200`}
                  >
                    <input
                      type="checkbox"
                      name={formFieldName}
                      value={option}
                      className="cursor-pointer"
                      onChange={handleChange}
                    />
                    <span className="ml-1">{option}</span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </label>
    </div>
  );
}
