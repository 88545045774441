export const convertToReadableNumber = (displayed: string | number) => {
  // if there are commas, remove them
  displayed = displayed.toString().replace(/,/g, '');
  // takes numbers and converts them to a readable format
  // eg 1230000 -> 1.23M, 1230 -> 1.23k, 1.23 -> 1.23, 1230000000 -> 1.23B, 1230000000000 -> 1.23T, 0.00123 -> 1.23m etc

  const absValue = Math.abs(Number(displayed));

  let readableNumber = displayed;
  if (absValue >= 1e12) {
    readableNumber = (Number(displayed) / 1e12).toFixed(2) + 'T';
  } else if (absValue >= 1e9) {
    readableNumber = (Number(displayed) / 1e9).toFixed(2) + 'B';
  } else if (absValue >= 1e6) {
    readableNumber = (Number(displayed) / 1e6).toFixed(2) + 'M';
  } else if (absValue >= 1e3) {
    readableNumber = (Number(displayed) / 1e3).toFixed(2) + 'k';
  } else {
    readableNumber = Number(displayed).toFixed(2);
  }

  // if .00 then remove
  if (readableNumber.includes('.00')) {
    readableNumber = readableNumber.replace('.00', '');
  }

  return readableNumber;
};

export const takeLetterUnitsAndConvertToNumber = (
  displayed: string,
): number => {
  // this function takes a string with a magnitude unit and converts it to a number
  // eg 1.23M -> 1230000, 1.23k -> 1230, 1.23 -> 1.23, 1.23B -> 1230000000, 1.23T -> 1230000000000, 1.23m -> 0.00123 etc

  const unitMap: { [key: string]: number } = {
    K: 1e3,
    M: 1e6,
    B: 1e9,
    T: 1e12,
    k: 1e3,
    m: 1e-3,
  };

  const match = displayed.match(/^([0-9.]+)([KMBTkm]?)$/);
  if (!match) {
    return parseFloat(displayed);
  }

  const value = parseFloat(match[1]);
  const unit = match[2] || '';

  if (unitMap[unit] === undefined) {
    return value;
  }

  return value * unitMap[unit];
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const modifyToAbsoluteUrl = (url: string) => {
  let company_url = '';
  if (checkIsValidUrl(url) === 'full') {
    company_url = url;
  } else if (checkIsValidUrl(url) === 'partial') {
    company_url = create_url_from_partial(url);
  }
  return company_url;
};

export const checkIsValidUrl = (url: string) => {
  // check it has a point
  if (url.includes('.')) {
    // check construct
    if (isValidUrl(url)) {
      return 'full';
    } else {
      if (isValidUrl(create_url_from_partial(url))) {
        return 'partial';
      }
    }
  }
  // modify to be true
  return false;
};

const isValidUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export const create_url_from_partial = (partial_url: string) => {
  return 'https://' + partial_url;
};

export const replaceHTMLEncoding = (str: string): string => {
  return str
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'");
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const formattedDate = date.toLocaleDateString('en-UK', options);

  // Split the date string into parts
  const [day, month, year] = formattedDate.split(' ');

  // Add the appropriate ordinal suffix to the day
  let suffix;
  if (day === '1' || day === '21' || day === '31') {
    suffix = 'st';
  } else if (day === '2' || day === '22') {
    suffix = 'nd';
  } else if (day === '3' || day === '23') {
    suffix = 'rd';
  } else {
    suffix = 'th';
  }

  // Return the formatted date string
  return `${day}${suffix} ${month} ${year}`;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export function timeoutWrapper<T>(
  originalFunction: () => Promise<T>,
  timeout: number,
  defaultValue: T,
) {
  return Promise.race([
    originalFunction(),
    new Promise(resolve => setTimeout(resolve, timeout, defaultValue)),
  ]);
}

// create a random number between 0 and a given max
export const getRandomNumber = (max: number) => {
  return Math.floor(Math.random() * max);
};
