import React from 'react';

interface Props {
  fill?: string;
}

export default function SearchIcon(props: Props) {
  return (
    <div style={{ fill: props.fill || 'black' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17px"
        height="17px"
        viewBox="0 0 76 76"
      >
        <path
          fillOpacity="1"
          strokeWidth="0.2"
          strokeLinejoin="round"
          d="M 42.5,22C 49.4036,22 55,27.5964 55,34.5C 55,41.4036 49.4036,47 42.5,47C 40.1356,47 37.9245,46.3435 36,45.2426L 26.9749,54.2678C 25.8033,55.4393 23.9038,55.4393 22.7322,54.2678C 21.5607,53.0962 21.5607,51.1967 22.7322,50.0251L 31.7971,40.961C 30.6565,39.0755 30,36.8644 30,34.5C 30,27.5964 35.5964,22 42.5,22 Z M 42.5,26C 37.8056,26 34,29.8056 34,34.5C 34,39.1944 37.8056,43 42.5,43C 47.1944,43 51,39.1944 51,34.5C 51,29.8056 47.1944,26 42.5,26 Z "
        />
      </svg>
    </div>
  );
}
