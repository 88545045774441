import React, { createContext, useState } from 'react';

interface QuestionSection {
  title: string;
  subtitle: string;
  questions: Question[];
}
interface Question {
  type: string;
  placeholder?: string;
  initialValue?: string;
}

interface ApplicationQuestionsState {
  sections: QuestionSection[];
  // other state properties
}

export const ApplicationQuestionsContext = createContext<
  ApplicationQuestionsState | undefined
>(undefined);

const ApplicationQuestionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [ApplicationQuestions, setApplicationQuestions] =
    useState<ApplicationQuestionsState>({
      sections: [
        {
          title: 'Personal Information',
          subtitle: 'Questions about the applicant',
          questions: [
            {
              type: 'text',
              initialValue: 'What is your name?',
              placeholder: 'Full Name',
            },
            {
              type: 'date',
              initialValue: 'What is your date of birth',
              placeholder: '01/01/1990',
            },
            {
              type: 'multi',
              initialValue: 'What are your verticals?',
              placeholder: 'AgriTech, FinTech, HealthTech',
            },
            {
              type: 'select',
              initialValue: 'What is your gender?',
              placeholder: 'Male, Female, Other',
            },
            {
              type: 'textarea',
              initialValue: 'Write a short description of your business',
              placeholder: 'Description',
            },
            {
              type: 'doc_agreement',
              initialValue: 'Please read and agree to the terms and conditions',
              placeholder: 'I agree to the terms and conditions',
            },
          ],
        },
        {
          title: 'Contact Information',
          subtitle: 'Questions about the applicant',
          questions: [
            {
              type: 'text',
              initialValue: 'What is your email address?',
              placeholder: 'Email',
            },
            {
              type: 'number',
              initialValue: 'What is your phone number?',
              placeholder: 'Phone Number',
            },
            {
              type: 'file',
              initialValue: 'Upload your CV',
              placeholder: 'CV',
            },
          ],
        },
      ],
      // initialize other state properties
    });

  return (
    <ApplicationQuestionsContext.Provider
      value={{
        sections: ApplicationQuestions.sections,
        // other state properties
      }}
    >
      {children}
    </ApplicationQuestionsContext.Provider>
  );
};

export default ApplicationQuestionsProvider;
