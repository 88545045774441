import React, { useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosProgressEvent } from 'axios';
import Dropzone from 'react-dropzone';

import { LoggedInContext } from '../../contexts/LoggedInContext';
// import { VulaCompanyAPI } from '../../api/company';

interface Props {
  label: string;
  afterUpload: (url: string) => void;
  instructions?: string;
  width?: string;
}

export default function UploadZone(props: Props) {
  //   const {} = useContext(LoggedInContext);
  //   const { getPitchData } = useContext(EditPitchContext);
  //   const [error, setError] = useState(false);
  const [clickedUpload, setClickedUpload] = useState(false);

  const [percentageUploaded, setPercentageUploaded] = useState(0);

  //   const { getAccessTokenSilently } = useAuth0();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onDrop = async (files: T[]) => {
    setPercentageUploaded(100);
    // get presigned url
    // const token = await getAccessTokenSilently();
    // // const api = new VulaCompanyAPI({ token });
    // try {
    //   // Get presigned S3 url
    //   const type = files[0].type;

    //   const presignRes = await api.getPresignedUploadURL(company_slug, type);
    //   //get mime type
    //   const getFileMimeType = (title: string) => {
    //     if (title.includes('.pdf')) {
    //       return 'application/pdf';
    //     } else if (title.includes('.mp4')) {
    //       return 'video/mp4';
    //     } else if (
    //       title.includes('.png') ||
    //       title.includes('.jpeg') ||
    //       title.includes('.jpg')
    //     ) {
    //       return 'image/png';
    //     } else if (title.includes('.gif')) {
    //       return 'image/gif';
    //     } else if (title.includes('.webp')) {
    //       return 'image/webp';
    //     } else {
    //       return 'application/octet-stream';
    //     }
    //   };
    //   const mimeType = getFileMimeType(files[0].name);
    //   const options = {
    //     onUploadProgress: (p: AxiosProgressEvent) => {
    //       if (p.total) {
    //         const percentageUploaded = Math.round((p.loaded * 100) / p.total);
    //         setPercentageUploaded(percentageUploaded);
    //       }
    //     },
    //     headers: {
    //       Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    //       Key: presignRes.data.filename,
    //       'Content-Type': mimeType,
    //     },
    //   };
    //   await axios
    //     .put(presignRes.data.url, files[0], options)
    //     .then(async () => {
    //       // Add the attachment to the backend it has saved
    //       const s3Location = presignRes.data.url
    //         .split('.com/')[1]
    //         .split('?')[0];
    //       await api
    //         .postUploaded(company_slug, s3Location, props.label, mimeType)
    //         .then(async response => {
    //           // show the user their image
    //           // need to get presigned url to allow viewing
    //           props.afterUpload(response.data.url);
    //           await getPitchData();
    //         })
    //         .catch(error => {
    //           console.log(error);
    //         });
    //     })
    //     .catch(error => {
    //       setError(true);
    //       console.log(error);
    //     });
    // } catch (error) {
    //   setError(true);
    //   console.log(error);
    //   return;
    // }
  };

  return (
    <div
      className={
        props.width
          ? props.width
          : 'w-full min-w-[200px] sm:min-w-[330px] sm:p-2 p-1'
      }
    >
      <Dropzone
        onDrop={files => onDrop(files)}
        onFileDialogOpen={() => setClickedUpload(true)}
        onFileDialogCancel={() => setClickedUpload(false)}
        accept={{
          'image/*': ['.jpeg', '.png'],
          'video/mp4': ['.mp4', '.MP4'],
          'application/pdf': ['.pdf'],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className=" cursor-pointer w-full h-full min-h-[100px] sm:min-h-[150px] border border-1 border-dashed border-stone-400 bg-stone-100 rounded-xl text-stone-400 flex justify-center items-center"
          >
            <input className="" {...getInputProps()} />
            <div className="sm:p-4 p-8 w-full h-full flex flex-col justify-center items-center">
              <p>
                {
                  // error
                  //   ? 'Something went wrong!'
                  //   :
                  percentageUploaded === 100
                    ? 'Upload completed'
                    : percentageUploaded > 0
                    ? percentageUploaded.toString() + '% uploaded'
                    : clickedUpload
                    ? 'Opening your files'
                    : props.instructions
                    ? props.instructions
                    : 'Drop the file here, or click to select the file'
                }
              </p>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
}
