import React from 'react';
import TextInput from './TextInput';
import DateInput from './DateInput';
import { NumberInput } from './NumberInput';
import { FileUpload } from './FileUpload';
import { MultiSelectInput } from './MultiSelectInput';
import { SingleSelect } from './SingleSelect';
import { TextArea } from './TextAreaInput';
import { DocumentAgreement } from './DocumentAgreement';
interface Props {
  type: string;
  placeholder?: string;
  initialValue?: string;
}
export default function InputField(props: Props) {
  //  a switch case to render the input field based on the type prop

  switch (props.type) {
    case 'text':
      return (
        <TextInput
          placeholder={props.placeholder}
          initialValue={props.initialValue}
        />
      );
    case 'date':
      return (
        <DateInput
          placeholder={props.placeholder}
          initialValue={props.initialValue}
        />
      );
    case 'number':
      return (
        <NumberInput
          placeholder={props.placeholder}
          initialValue={props.initialValue}
        />
      );

    case 'file':
      return (
        <FileUpload
          label="file"
          placeholder={props.placeholder}
          initialValue={props.initialValue}
        />
      );

    case 'select':
      return (
        <SingleSelect
          placeholder={props.placeholder}
          initialValue={props.initialValue}
        />
      );

    case 'multi':
      return (
        <MultiSelectInput
          placeholder={props.placeholder}
          initialValue={props.initialValue}
        />
      );

    case 'textarea':
      return (
        <TextArea
          placeholder={props.placeholder}
          initialValue={props.initialValue}
        />
      );

    case 'doc_agreement':
      return (
        <DocumentAgreement
          placeholder={props.placeholder}
          initialValue={props.initialValue}
        />
      );

    default:
      return (
        <input
          type="text"
          placeholder={props.placeholder}
          defaultValue={props.initialValue}
          className="w-full p-2 border border-stone-200 rounded-lg"
        />
      );
  }
}
