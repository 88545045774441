import React, { useState } from 'react';
import SearchIcon from '../Icons/SearchIcon';

interface Props {
  title: string;
  filterType: 'search' | 'select' | 'multi-select';
  filterChange: (value: string | undefined) => void;
}

export default function CompanyTableColumnHeader(props: Props) {
  const [showSearch, setShowSearch] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
    null,
  );
  const [loading, setLoading] = useState(false); // New loading state

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Clear the previous timeout if the user is still typing
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    setLoading(true); // Start showing visual feedback

    // Set a new timeout to trigger the search after a delay
    const newTimer = setTimeout(() => {
      props.filterChange(value); // Trigger the actual search after delay
      setLoading(false); // Stop showing visual feedback once search is done
    }, 500); // Adjust the delay in milliseconds as needed

    setDebounceTimer(newTimer);
  };

  return (
    <div className="flex justify-between items-center h-full">
      <div className="font-bold text-xs">{props.title}</div>
      {/* search icon */}
      {showSearch ? (
        <div className="rounded bg-stone-50 px-2 text-xs flex justify-between items-center ">
          <input
            placeholder="Search"
            autoFocus
            onChange={handleSearchChange}
            value={searchTerm}
          />
          {/* Show spinner if loading */}
          {loading && (
            <div className="loader bg-stone-100 fill-black ml-2 border border-solid  border-t-2 border-gray-400 rounded-full w-2 h-2 animate-spin"></div>
          )}

          <button
            className="hover:bg-stone-50 px-2 rounded text-xs"
            onClick={() => {
              // Clear the search term and hide the search bar
              setSearchTerm('');
              props.filterChange(undefined);
              setShowSearch(false);
            }}
          >
            <SearchIcon />
          </button>
        </div>
      ) : (
        <button
          className=" hover:bg-stone-50 px-2 rounded text-xs"
          onClick={() => setShowSearch(true)}
        >
          <SearchIcon />
        </button>
      )}
    </div>
  );
}
