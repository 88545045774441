import React from 'react';
import GridBackground from '../components/utils/GridBackground';
import { convertToReadableNumber } from '../methods/UIHelpers';

export default function MockProgrammeDash() {
  const topRow = [
    {
      title: 'Total Applications',
      value: 1834,
    },
    {
      title: 'Total Disbursed',
      value: 9000000,
      units: 'R',
      subtitle: '321 deals',
    },
    {
      title: 'Total Recieved',
      value: 4000000,
      units: 'R',
    },
    {
      title: 'NPL %',
      value: 7,
      subtitle: '5 deals',
    },
  ];

  const companiesTable = [
    {
      logo: '',
      name: 'Company 1',
    },
    {
      logo: '',
      name: 'Company 1',
    },
    {
      logo: '',
      name: 'Company 1',
    },
    {
      logo: '',
      name: 'Company 1',
    },
    {
      logo: '',
      name: 'Company 1',
    },
    {
      logo: '',
      name: 'Company 1',
    },
  ];

  return (
    <GridBackground fullHeight={true}>
      <div className="flex">
        <div
          className="w-3/4 overflow-scroll no-scrollbar h-[100vh]  
        "
        >
          {/* first row of cards should show total applied count, total disbursed in Rands, total recieved (back from loans), and NPL % and total R */}
          <div className="grid grid-cols-4 gap-4 p-4 ">
            {topRow.map((card, index) => {
              return (
                <div
                  key={index}
                  className="bg-white p-4 rounded-md shadow-md flex items-center justify-center"
                >
                  <div className="">
                    <div className="text-sm text-gray-500">{card.title}</div>
                    <div className="text-2xl font-bold">
                      {convertToReadableNumber(card.value)}
                      {card.units}
                    </div>
                    <div className="text-sm text-gray-500">{card.subtitle}</div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* Second row should be a mermaid diagram of a ring/pie chart showing profit target and a sentence stating if projected to be on track, and a bar chart that shows the utilisation of funds */}
          <div className="flex justify-between p-4">
            <div className="w-full bg-white p-4 rounded-md shadow-md mr-4">
              {/* title */}
              <h2 className="text-2xl font-bold text-gray-500 text-center">
                Profit Target
              </h2>
              {/* using an image for now */}
              <img
                src="https://i.pinimg.com/originals/c2/12/e2/c212e29750b5da6998ad1f0927566646.png"
                alt="mermaid diagram"
              />
            </div>
            <div className="w-full bg-white p-4 rounded-md shadow-md">
              <h2 className="text-2xl font-bold text-gray-500 text-center">
                Utilisation of Funds
              </h2>
              <img
                className="w-full h-full object-contain"
                src="https://wac-cdn.atlassian.com/dam/jcr:fe81d114-5259-49c8-8c99-affc2bc665fc/stacked-bar-example-1.png?cdnVersion=1719"
                alt="mermaid diagram"
              />
            </div>
          </div>

          {/* row three should be one long line chart */}
          <div className="bg-white p-12 rounded-md shadow-md mt-4 m-4">
            <h2 className="text-2xl font-bold text-gray-500 text-center">
              Application Timeline
            </h2>
            <img
              className="w-full p-12 object-contain"
              src="https://learn.microsoft.com/en-us/power-bi/visuals/media/power-bi-line-charts/power-bi-line.png"
              alt="mermaid diagram"
            />
          </div>
        </div>

        <div className="w-1/4  h-[100vh]    pt-2 pr-2    ">
          <div className=" shadow-md  h-full">
            <div className="border border-b-1 border-t-0 border-x-0 border-stone-200 border-solid flex">
              <div className=" p-2 text-xl font-bold text-gray-500 text-center w-full bg-white rounded-t-lg shadow">
                Portfolio
              </div>
              <div className="bg-stone-50 p-2 text-xl font-bold text-gray-300 text-center w-full  rounded-t-lg shadow">
                Ask Vula
              </div>
            </div>
            {/* main portfolio tab view */}
            <div className=" relative p-4 bg-white h-full">
              {/* title */}
              <div className="flex justify-start items-center">
                <h1
                  className="text-2xl   font-bold text-gray-500 text-center  whitespace-nowrap 
              "
                >
                  Portfolio companies
                </h1>
                <h4 className="pl-2 text-xl font-bold text-gray-300 text-center ">
                  (321)
                </h4>
              </div>
              {/* table of companies with circle logo images, their name and a button to view in detail */}
              <div className="grid grid-cols-1 gap-2 mt-4">
                {companiesTable.map((company, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between p-2 bg-stone-50 rounded-md"
                    >
                      <div className="flex items-center">
                        <div className="w-8 h-8 bg-gray-200 rounded-full mr-2"></div>
                        <div>{company.name}</div>
                      </div>
                      <button className="bg-stone-500 text-white rounded-md p-2">
                        View
                      </button>
                    </div>
                  );
                })}
              </div>
              {/* overlay deep dive panel on the selected company that half overlays the content above it */}
              <div className="absolute bottom-0 left-0 right-0 bg-white rounded-xl  p-4 border border-1 border-stone-200 border-solid shadow h-[70vh]">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <div className="w-8 h-8 bg-gray-200 rounded-full mr-2"></div>
                    <div className="text-xl font-bold">Company 1</div>
                  </div>
                  <button className="bg-stone-500 text-white rounded-md p-2">
                    View
                  </button>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <div className="text-sm text-gray-500">Total Disbursed</div>
                  <div className="text-sm text-gray-500">R 1 000 000</div>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <div className="text-sm text-gray-500">Total Recieved</div>
                  <div className="text-sm text-gray-500">R 500 000</div>
                </div>

                {/* a table of payment histories */}
                <div className="overflow-y-auto mt-4 h-[40vh]">
                  <table className="w-full">
                    <thead className="bg-stone-50">
                      <tr>
                        <th className="p-2">Date</th>
                        <th className="p-2">Amount</th>
                        <th className="p-2">Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td className="p-2">2021-10-01</td>
                        <td className="p-2">R 100 000</td>
                        <td className="p-2">Paid</td>
                      </tr>
                      <tr>
                        <td className="p-2">2021-10-01</td>
                        <td className="p-2">R 100 000</td>
                        <td className="p-2">Paid</td>
                      </tr>
                      <tr>
                        <td className="p-2">2021-10-01</td>
                        <td className="p-2">R 100 000</td>
                        <td className="p-2">Paid</td>
                      </tr>
                      <tr>
                        <td className="p-2">2021-10-01</td>
                        <td className="p-2">R 100 000</td>
                        <td className="p-2">Paid</td>
                      </tr>
                      <tr>
                        <td className="p-2">2021-10-01</td>
                        <td className="p-2">R 100 000</td>
                        <td className="p-2">Paid</td>
                      </tr>
                      <tr>
                        <td className="p-2">2021-10-01</td>
                        <td className="p-2">R 100 000</td>
                        <td className="p-2">Paid</td>
                      </tr>
                      <tr>
                        <td className="p-2">2021-10-01</td>
                        <td className="p-2">R 100 000</td>
                        <td className="p-2">Paid</td>
                      </tr>
                      <tr>
                        <td className="p-2">2021-10-01</td>
                        <td className="p-2">R 100 000</td>
                        <td className="p-2">Disbursement</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GridBackground>
  );
}
