import React, { useContext, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import axios, { AxiosProgressEvent } from 'axios';

import { useAuth0 } from '@auth0/auth0-react';

import Spinner from './Spinner';
import { VulaPartnersAPI } from '../../api/partners';
import { VulaPartnerProductsAPI } from '../../api/products';
import { FinProductContext } from '../../contexts/FinProductContext';

interface Props {
  label: string;
  afterUpload?: (uploaded_filenames: string[]) => void;
  fileTypes?: string[];
  alreadyUploadedFiles?: {
    url: string;
    filename: string;
  }[];
}

// A component that allows users to drag and drop or select multiple files to upload and it will show a progress bar for each file
// and once uploaded it will show the file to the user as an icon and the file name
export default function UploadMultiple(props: Props) {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const { selectedProductApplication, selectedApplicationId } =
    useContext(FinProductContext);

  const [token, setToken] = useState<string | undefined>(undefined);
  // uploading states
  const [error, setError] = useState(false);
  const [clickedUpload, setClickedUpload] = useState(false);
  const [percentageUploaded, setPercentageUploaded] = useState(0);
  const [rejectReason, setRejectReason] = useState<string>('');
  const [showUploadingSpinner, setShowUploadingSpinner] = useState(false);
  const [uploadedFileNames, setUploadedFileNames] = useState<
    {
      url?: string;
      filename: string;
    }[]
  >(props.alreadyUploadedFiles ? props.alreadyUploadedFiles : []);
  const [index, setIndex] = useState(
    props.alreadyUploadedFiles ? props.alreadyUploadedFiles.length : 0,
  );

  // on load get the token
  React.useEffect(() => {
    if (isLoading) return;

    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (e) {
        console.log(e);
      }
    };
    getToken();
  }, [getAccessTokenSilently, isLoading]);

  const getFileMimeType = (title: string) => {
    if (title.includes('.pdf')) {
      return 'application/pdf';
    } else if (title.includes('.mp4')) {
      return 'video/mp4';
    } else if (
      title.includes('.png') ||
      title.includes('.jpeg') ||
      title.includes('.jpg')
    ) {
      return 'image/png';
    } else if (title.includes('.gif')) {
      return 'image/gif';
    } else if (title.includes('.webp')) {
      return 'image/webp';
    } else {
      return 'application/octet-stream';
    }
  };

  const uploadFile = async (file: File, type: string, index: number) => {
    try {
      // get presigned url
      const api = new VulaPartnersAPI({ token });
      const presignedResUrl = await api.getPresignedUrl(type);

      const mimeType = getFileMimeType(file.name);
      const options = {
        onUploadProgress: (p: AxiosProgressEvent) => {
          if (p.total) {
            const percentageUploaded = Math.round((p.loaded * 100) / p.total);
            setPercentageUploaded(percentageUploaded);
          }
        },
        headers: {
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          Key: presignedResUrl.data.url,
          'Content-Type': mimeType,
        },
      };

      // save to s3
      await axios.put(presignedResUrl.data.url, file, options).catch(error => {
        setError(true);
        console.log(error);
      });

      // save file reference to db
      const api2 = new VulaPartnerProductsAPI({ token });
      await api2
        .saveAttachmentToDb({
          s3_url: presignedResUrl.data.url.split('?')[0],
          label: props.label + '_' + index,
          type: mimeType,
          user_filename: file.name,
          product_id: selectedProductApplication?.id || '',
          application_id: selectedApplicationId || '',
        })
        .then(res => {
          const newUploadedFileNames = uploadedFileNames.concat({
            filename: file.name,
            url: res.data.url,
          });
          setUploadedFileNames(newUploadedFileNames);
          setIndex(index + 1);
          props.afterUpload &&
            props.afterUpload(newUploadedFileNames.map(file => file.filename));
        });
    } catch (error) {
      setError(true);
      console.log(error);
      return;
    }
  };

  const onDrop = async (files: File[]) => {
    setClickedUpload(true);

    if (files.length === 0) return;
    try {
      // sequentially upload files but await them all
      for (let i = index; i < files.length; i++) {
        setShowUploadingSpinner(true);
        // upload to s3
        await uploadFile(files[i], files[i].type, i);

        // if another file is to come, wait a second between uploads for lambda to catch up
        if (i !== files.length - 1) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
        setShowUploadingSpinner(false);
      }

      // reset error
      setError(false);
      setClickedUpload(false);
    } catch (error) {
      setError(true);
      console.log(error);
      return;
    }
  };

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    // get the error message
    const error = rejectedFiles[0].errors[0].message;
    const filename = rejectedFiles[0].file.name;

    setRejectReason(
      error + ' for ' + filename + '. Please try again with a different file.',
    );
  };

  const defineFileTypesAllowed = () => {
    // default should be:
    if (!props.fileTypes) {
      return {
        'image/*': ['.jpeg', '.png'],
        'application/pdf': ['.pdf'],
        'text/*': ['.txt'],
      };
    }

    let dropZoneInput: { [key: string]: string[] } = {};
    // if fileTypes are passed in, then use that to define the file types object
    if (props.fileTypes) {
      props.fileTypes.forEach(fileType => {
        if (fileType === 'image') {
          dropZoneInput['image/*'] = ['.jpeg', '.png'];
        } else if (fileType === 'pdf') {
          dropZoneInput['application/pdf'] = ['.pdf'];
        } else if (fileType === 'text') {
          dropZoneInput['text/*'] = ['.txt'];
        } else if (fileType === 'video') {
          dropZoneInput['video/mp4'] = ['.mp4'];
        }
      });
    }

    return dropZoneInput;
  };

  return (
    <div className="min-w-[200px] sm:min-w-[330px] sm:p-2 p-1">
      {/* render uploaded files in a grid */}
      {uploadedFileNames.length > 0 && (
        <div className="flex flex-wrap">
          {uploadedFileNames.map((file, i) => (
            <a
              href={file?.url ? file.url : '#'}
              target="_blank"
              rel="noreferrer"
              key={i + file.filename}
              className="flex flex-row items-center bg-stone-50 hover:bg-stone-100 p-2 my-1 mr-2 rounded-lg"
            >
              <div className="p-2">
                <img
                  src="https://img.icons8.com/ios/50/000000/file.png"
                  alt="file"
                  className="w-6 h-6"
                />
              </div>
              <div className="p-2">{file.filename}</div>
            </a>
          ))}
        </div>
      )}

      {/* render upload zone */}
      <Dropzone
        onDrop={files => onDrop(files)}
        onFileDialogOpen={() => setClickedUpload(true)}
        onFileDialogCancel={() => setClickedUpload(false)}
        accept={defineFileTypesAllowed()}
        maxSize={15 * 1024 * 1024}
        minSize={0}
        onDropRejected={reject => {
          onDropRejected(reject);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={
              ' cursor-pointer w-full h-full border border-1 border-dashed border-stone-300 bg-stone-50 rounded-xl text-stone-400 flex flex-col justify-center items-center transition-all ease-in-out duration-300 ' +
              (uploadedFileNames.length > 0
                ? 'min-h-[50px]'
                : 'min-h-[100px] sm:min-h-[150px]')
            }
          >
            <div>
              <input className="" {...getInputProps()} />
              <div className="sm:p-4 p-8 w-full h-full flex flex-col justify-center items-center">
                <p>
                  {showUploadingSpinner ? (
                    <Spinner />
                  ) : rejectReason ? (
                    rejectReason
                  ) : error ? (
                    'Something went wrong!'
                  ) : percentageUploaded === 100 ? (
                    'Upload'
                  ) : percentageUploaded > 0 ? (
                    percentageUploaded.toString() + '% uploaded'
                  ) : clickedUpload ? (
                    'Opening your files'
                  ) : (
                    'Drop files here, or click to select files (max 1Mb)'
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
}
