import React, { createContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaPartnerProductsAPI } from '../api/products';
import { VulaPartnersAPI } from '../api/partners';

// type of state
export interface FinProductContextProps {
  applications: applicationsSummary[];
  selectedProductApplication: SelectedProductApplication | undefined;
  selectedApplicationId: string | null;
  setSelectedApplicationId: (id: string) => void;
  uploadReviewId: string | null;
  setUploadReviewId: (id: string | null) => void;
  upsertPartnerNotes: (notes: string) => void;
}

export interface applicationsSummary {
  id: string;
  status: string;
  company: {
    name: string;
    slug: string;
    website: string;
    logo: string;
  };
}

interface SelectedProductApplication {
  company: {
    id: string;
    name: string;
    logo: string;
    slug: string;
    website: string;
  };
  partner_notes?: string;
  owner: {
    id: string;
    name: string;
    logo: string;
    url: string;
  };
  questionsAskedOfAIP: {
    question: string;
    answer: string;
    timestamp: string;
  }[];
  aip_agreement_url: string;
  name: string;
  slug: string;
  image: string;
  id: string;
  sections: section[];
}

export interface section {
  id: string;
  type: string;
  title: string;
  subtitle: string;
  stage: string;
  order: number;
  questions: question[];
}

export interface question {
  id: string;
  type: string;
  question: string;
  generation_prompt: string | null;
  additional_info: string | null;
  enable_vula: boolean;
  label: string;
  corpus_id?: string;
  corpus_label: string;
  corpus_type: string;
  corpus_subtype: string;
  order: number;
  required: boolean;
  answer: string | null;
  repeating_label: string | null;
  repeating_index: string | null;
}

// type of state
export interface FinProductContextProps {
  applications: {
    id: string;
    status: string;
    company: {
      name: string;
      slug: string;
      website: string;
      logo: string;
    };
  }[];
  selectedProductApplication: SelectedProductApplication | undefined;
  isLoadingApplication: boolean;
  selectedApplicationId: string | null;
  setSelectedApplicationId: (id: string) => void;
  uploadReviewId: string | null;
  setUploadReviewId: (id: string | null) => void;
  upsertPartnerNotes: (notes: string) => void;
  updateApplicationStatus: (status: string) => void;
  addToPartnerCorpus: ({
    content,
    corpus_type,
    corpus_subtype,
    corpus_label,
    relationship_id,
  }: {
    content: string;
    corpus_type: string;
    corpus_subtype: string;
    corpus_label: string;
    relationship_id: string;
  }) => void;
}

// Initialise with Context engine
export const FinProductContext = createContext<FinProductContextProps>({
  applications: [],
  selectedProductApplication: undefined,
  isLoadingApplication: false,
  selectedApplicationId: null,
  setSelectedApplicationId: () => void 0,
  uploadReviewId: null,
  setUploadReviewId: () => void 0,
  upsertPartnerNotes: () => void 0,
  updateApplicationStatus: () => void 0,
  addToPartnerCorpus: () => void 0,
});

const FinProductProvider = ({ children }: { children: React.ReactNode }) => {
  const { getAccessTokenSilently, isLoading } = useAuth0();

  // The selected product id
  const [selectedProductId, setSelectedProductId] = React.useState<string>(
    window.location.pathname.split('/')[2],
  );
  // The selected application id
  const [selectedApplicationId, setSelectedApplicationId] = React.useState<
    string | null
  >(window.location.pathname.split('/')[3]);
  // The applications
  const [applications, setApplications] = React.useState([]);
  // The selected attachment id
  const [uploadReviewId, setUploadReviewId] = React.useState<string | null>(
    window.location.pathname.split('/')[4],
  );

  // on change
  useEffect(() => {
    // example url /product/:productId/ or /product/:productId/:applicationId or /product/:productId/:applicationId/:question_id
    setSelectedProductId(window.location.pathname.split('/')[2]);
    setSelectedApplicationId(window.location.pathname.split('/')[3]);
    setUploadReviewId(window.location.pathname.split('/')[4]);
  }, []);

  const [selectedProductApplication, setSelectedProductApplication] =
    React.useState<SelectedProductApplication | undefined>(undefined);
  const [isLoadingApplication, setIsLoadingApplication] = React.useState(true);

  // on load
  useEffect(() => {
    // find the product definition and applications statuses
    (async () => await getPartnerProductsFromDb())();
  }, [isLoading]);

  const getPartnerProductsFromDb = async () => {
    const token = await getAccessTokenSilently();
    const api = new VulaPartnerProductsAPI({ token });
    const products = await api.getPartnerProduct(selectedProductId);
    setApplications(products.data.applications);
    setSelectedApplicationId(
      selectedApplicationId || products.data.applications[0]?.id,
    );
  };

  const upsertPartnerNotes = async (notes: string) => {
    if (!selectedApplicationId || !selectedProductId) return;
    const token = await getAccessTokenSilently();
    const api = new VulaPartnerProductsAPI({ token });
    await api.upsertPartnerNotes(
      selectedProductId,
      selectedApplicationId,
      notes,
    );
  };

  // when a new application is selected, get the application details
  useEffect(() => {
    (async () => await getApplicationDetails())();
  }, [selectedApplicationId]);

  const getApplicationDetails = async () => {
    setIsLoadingApplication(true);
    if (!selectedApplicationId || !selectedProductId) return;
    const token = await getAccessTokenSilently();
    const api = new VulaPartnerProductsAPI({ token });

    const application = await api.getApplicationDetails(
      selectedProductId,
      selectedApplicationId,
    );
    setSelectedProductApplication(application.data);

    // if moving to a new application, clear the upload review
    if (window.location.pathname.split('/')[3] !== selectedApplicationId) {
      setUploadReviewId(null);
    }

    window.history.pushState(
      null,
      '',
      `/product/${selectedProductId}/${selectedApplicationId}`,
    );
    setIsLoadingApplication(false);
  };

  // when a new attachment is selected, update the url
  useEffect(() => {
    // if no attachment id, clear the url
    if (!uploadReviewId) {
      window.history.pushState(
        null,
        '',
        `/product/${selectedProductId}/${selectedApplicationId}`,
      );
      return;
    }

    // update the url
    window.history.pushState(
      null,
      '',
      `/product/${selectedProductId}/${selectedApplicationId}/${uploadReviewId}`,
    );
  }, [uploadReviewId]);

  const updateApplicationStatus = async (status: string) => {
    if (!selectedApplicationId || !selectedProductId) return;
    const token = await getAccessTokenSilently();
    const api = new VulaPartnerProductsAPI({ token });
    await api.updateApplicationStatus(
      selectedProductId,
      selectedApplicationId,
      status,
    );
    await getPartnerProductsFromDb();
    await getApplicationDetails();
  };

  const addToPartnerCorpus = async ({
    content,
    corpus_type,
    corpus_subtype,
    corpus_label,
    relationship_id,
  }: {
    content: string;
    corpus_type: string;
    corpus_subtype: string;
    corpus_label: string;
    relationship_id: string;
  }) => {
    if (!selectedApplicationId || !selectedProductId) return;
    const token = await getAccessTokenSilently();
    const api = new VulaPartnersAPI({ token });
    await api.addToPartnerCorpus({
      content,
      corpus_type,
      corpus_subtype,
      corpus_label,
      relationship_id,
    });
    await getPartnerProductsFromDb();
    await getApplicationDetails();
  };

  return (
    <FinProductContext.Provider
      value={{
        applications: applications,
        selectedProductApplication,
        isLoadingApplication,
        selectedApplicationId,
        setSelectedApplicationId,
        uploadReviewId,
        setUploadReviewId,
        upsertPartnerNotes,
        updateApplicationStatus,
        addToPartnerCorpus,
      }}
    >
      {selectedProductId ? children : null}
    </FinProductContext.Provider>
  );
};

export default FinProductProvider;
