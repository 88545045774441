import axios from 'axios';

import { apiUrl } from '../methods/env';

type Props = {
  token?: string;
};

export class VulaPartnerProductsAPI {
  private token?: string;

  constructor(props?: Props) {
    this.token = props?.token;
  }

  async getPartnerProducts() {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.get(`${apiUrl}/partners/products`, config);
  }

  async getPartnerProduct(id: string) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.get(`${apiUrl}/partners/products/${id}`, config);
  }

  async getApplicationDetails(product_id: string, application_id: string) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.get(
      `${apiUrl}/partners/products/${product_id}/${application_id}`,
      config,
    );
  }

  async upsertPartnerNotes(
    product_id: string,
    application_id: string,
    notes: string,
  ) {
    // todo: does this update?  I think it does but it isn't going any version control
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.post(
      `${apiUrl}/partners/products/${product_id}/${application_id}/notes`,
      { notes },
      config,
    );
  }

  async updateApplicationStatus(
    product_id: string,
    application_id: string,
    status: string,
  ) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.post(
      `${apiUrl}/partners/products/${product_id}/${application_id}/status`,
      { status },
      config,
    );
  }

  async saveAttachmentToDb({
    s3_url,
    label,
    type,
    user_filename,
    product_id,
    application_id,
  }: {
    s3_url: string;
    label: string;
    type: string;
    user_filename: string;
    product_id: string;
    application_id: string;
  }) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.post(
      `${apiUrl}/partners/products/${product_id}/${application_id}/attachment`,
      { s3_url, label, type, user_filename },
      config,
    );
  }

  async getAttachments(product_id: string, application_id: string) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.get(
      `${apiUrl}/partners/products/${product_id}/${application_id}/attachments`,
      config,
    );
  }

  async inviteCompanyToNextStage({
    product_id,
    application_id,
    stage,
  }: {
    product_id: string;
    application_id: string;
    stage: string;
  }) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.post(
      `${apiUrl}/partners/products/${product_id}/${application_id}/invite`,
      {
        stage,
      },
      config,
    );
  }
}
