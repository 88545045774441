import React from 'react';

import AutomationCard from './AutomationCard';
import CaretIcon from '../Icons/CaretIcon';

interface Props {
  section: {
    section_title: string;
    section_description: string;
    special_background?: string;
    comingSoon?: true;

    automations: {
      id: string;
      title: string;
      longDescription: string;
      partner_1?: {
        id: string;
        name: string;
        logo: string;
        blurb: string;
      };
      partner_2?: {
        id: string;
        name: string;
        logo: string;
        blurb: string;
      };
    }[];
  };
}
export default function AutomationSection(props: Props) {
  const { section } = props;
  const [collapsed, setCollapsed] = React.useState(false);

  if (section.automations.length === 0) return null;

  return (
    <div className={' rounded-lg shadow-md mb-4 ' + 'bg-stone-50'}>
      <div
        className={
          'rounded-t-lg pt-4 flex justify-between pb-2 border border-b-1 border-t-0 border-x-0 border-stone-300 border-solid ' +
          (section.special_background
            ? section.special_background
            : 'bg-stone-50')
        }
      >
        <div className="pl-4">
          <div className="flex items-center">
            <button
              className="pl-4 pr-8 scale-[1.1]"
              onClick={() => setCollapsed(!collapsed)}
            >
              <CaretIcon isOpen={!collapsed} />
            </button>
            <div>
              <h2 className="text-2xl font-bold text-gray-900">
                {section.section_title}
              </h2>
              <p className="text-sm text-gray-700 my-2">
                {section.section_description}
              </p>
            </div>
          </div>
        </div>
        {collapsed ? null : (
          <div>
            {/* filter on country */}
            <select className="bg-stone-50 mr-4 rounded-lg p-2 border border-stone-200 border-solid border-1">
              <option>Filter by country</option>
              <option>Nigeria</option>
              <option>Ghana</option>
              <option>Togo</option>
              <option>Benin</option>
              <option>Cameroon</option>
              <option>Côte d’Ivoire</option>

              {/* <option>South Africa</option>
              <option>Eswatini</option>
              <option>Lesotho</option> */}

              {/* <option>Kenya</option>
              <option>Uganda</option>
              <option>Nigeria</option> */}
            </select>
          </div>
        )}
      </div>
      {collapsed ? null : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 py-4">
          {section.automations.map(automation => (
            <AutomationCard automation={automation} />
          ))}
        </div>
      )}
    </div>
  );
}
