import React from 'react';

interface Props {
  children: React.ReactNode;
  title: string;
  subtitle: string;
}

export default function QuestionsSection(props: Props) {
  return (
    <div className="bg-stone-50 shadow-xl rounded-xl p-4 m-2">
      <div className="text-lg font-bold">{props.title}</div>
      <div className="text-sm">{props.subtitle}</div>
      {/* border line */}
      <div className="border border-t-1 border-x-0 border-b-0 border-stone-200 border-solid border-1 my-2"></div>

      {props.children}

      {/* Button to add a new question from scratch */}

      <div className="flex justify-center mt-4">
        <button className="w-fit m-auto border border-solid border-1 border-stone-200 hover:bg-stone-100  p-2 px-4 rounded-lg mt-2">
          Add a new question
        </button>
        <button className="ml-4 w-fit m-auto border border-solid border-1 border-stone-200 hover:bg-stone-100   p-2 px-4 rounded-lg mt-2">
          Generate a new question
        </button>
      </div>
    </div>
  );
}
