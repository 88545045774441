import React from 'react';

import LandingHeader from '../components/Landing/LandingHeader';

import { track } from '../interfaces/mixpanel';

track('Landing Page Loaded', {
  referrer: document.referrer,
});

export default function LandingPage() {
  return (
    <div className="h-full min-h-[100vh] m-auto flex items-center flex-col bg-white ">
      <div className="max-w-[2000px] w-full">
        <LandingHeader />
      </div>
    </div>
  );
}
