import React from 'react';
import GridBackground from '../utils/GridBackground';
import ViewCards from './ViewCards';

export default function Views() {
  //   Pre-screening stage - done
  // • Under evaluation stage (DD phase) - same as above
  // • Investment preparation and CC pack - could make
  // • Credit committee
  // • Implementation stage (legal, CPs etc.)
  // • Monitoring stage - could make disbursement, correspondence and links to data, timeline of events and approvals, powerBI export
  // • Controls per stage

  //  show example stages
  // include a program dashboard view (meta analysis - how many in each stage, total disbursed, total applications, total accepted, average time in each stage, average time in the whole process, good loan vs bad loan percentage, etc.)
  // Also show these stages in the kanban, add average time under each step

  const viewCards = [
    {
      title: 'IC view',
      description:
        'After the analysts have done their work, the IC view allows the IC to see the applications and make a decision.',
      selected: true,
      image:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/mock_ic.png',
    },
    {
      title: 'Disbursement dashboard',
      description:
        'Follow the disbursement process for each application in the programme portfolio.',
      selected: true,
      image:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/mock_disbursement.png',
    },
    {
      title: 'Programme dashboard',
      description: "Get a meta analysis of the programme's performance.",
      selected: false,
      image:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/mock_programme_dash.png',
    },
  ];

  return (
    <GridBackground fullHeight={true}>
      <div className="p-2 text-left max-w-[1200px] m-auto overflow-scroll h-[95vh]">
        <div className="m-2 bg-white shadow-xl p-4 rounded-xl">
          <div className="text-3xl">Select views</div>
          <div className="mt-4">
            To define a new view,{' '}
            <a className="underline hover:underline" href="mailto:nic@vula.vc">
              please contact your Vula representative.
            </a>
          </div>
        </div>

        <div className="m-2 bg-white shadow-xl p-4 rounded-xl">
          <div className="grid grid-cols-2 gap-2 p-2">
            {viewCards.map(view => (
              <ViewCards
                title={view.title}
                description={view.description}
                image={view.image}
                selected={view.selected}
              />
            ))}
          </div>
        </div>
      </div>
    </GridBackground>
  );
}
