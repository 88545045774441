import React, { createContext, useEffect, useState } from 'react';
import { VulaPartnersAPI } from '../api/partners';
import { useAuth0 } from '@auth0/auth0-react';

interface ConfigContextProps {
  config: PartnerConfig;
}

interface PartnerConfig {
  customisations?: {
    partner_id?: string;
    partner_name?: string;
    partner_slug?: string;
    logo?: string;
  };
  features?: {
    searchBar?: {
      visible: boolean;
      components?: {
        create_new_programme: { visible: boolean };
        review_analytics: { visible: boolean };
        launch_ic_view: { visible: boolean };
        ask_vula: { visible: boolean };
      };
    };
    applicationManagement?: {
      visible: boolean;
    };
    applicationsOverview?: {
      visible: boolean;
    };
    navigator?: {
      visible: boolean;
      hack_filter_on: number;
    };
    rightHandSideTab?: {
      visible: boolean;
      components: {
        correspondence: { visible: boolean };
        bookInitialMeeting: { visible: boolean };
        visitWebsite: { visible: boolean };
        potentialCofounders: { visible: boolean };
        askInvestorX: { visible: boolean };
        viewUploads: { visible: boolean };
      };
    };
    companyProcessingStatuses?: { visible: boolean };
    demoNextSteps?: { visible: boolean };
  };
}

export const ConfigContext = createContext<ConfigContextProps>({
  config: {},
});

export const ConfigContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [config, setConfig] = useState(null);

  // on load get the config
  useEffect(() => {
    // Fetch configuration from API
    (async () => {
      const token = await getAccessTokenSilently();
      const api = new VulaPartnersAPI({ token });

      const response = await api.getPartnerConfig();
      setConfig(response.data.config);
    })();
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        config: config || { features: { searchBar: { visible: true } } },
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContextProvider;
