import React from 'react';
import DocumentRender from './DocumentRender';
import {
  FinProductContext,
  question,
} from '../../../contexts/FinProductContext';
import {
  convertToReadableNumber,
  takeLetterUnitsAndConvertToNumber,
} from '../../../methods/UIHelpers';
import Spinner from '../../utils/Spinner';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  selectedQuestion: question | null;
}

interface AnalysisData {
  label: string;
  value: string;
  units?: string | null;
  justification?: string | null;
  revised_by_user?: {
    email: string;
    id: string;
  } | null;
  attachment_id: string;
  partner_id: string;
  id: string;
}

export default function FAKEIDAnalysis(props: Props) {
  const { selectedQuestion } = props;
  const { selectedProductApplication } = React.useContext(FinProductContext);
  const { getAccessTokenSilently } = useAuth0();

  const [results, setResults] = React.useState<AnalysisData[]>([]);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // on load get the data from the api
  React.useEffect(() => {
    // when an new url is gathered, call the api for analysis
    if (selectedQuestion?.answer) {
      (async () => {
        try {
          setIsLoading(true);

          setResults([
            {
              id: '17323879-1234-5678-1234-567812345681',
              label: 'full_names',
              value: 'ROBYN',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345682',
              label: 'surname',
              value: 'FOX',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345683',
              label: 'registration_number',
              value: 'K2022/888212/07',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345678',
              label: 'id_number',
              value: '8404270200088',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345679',
              label: 'date_of_birth',
              value: '1984-04-27',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345680',
              label: 'initials',
              value: 'R',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345684',
              label: 'company_name',
              value: 'CLIQUE CONSULTING',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345685',
              label: 'business_start_date',
              value: '2022-12-22',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345686',
              label: 'company_status',
              value: 'In Business',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345687',
              label: 'appointed_date',
              value: '2022-12-22',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345688',
              label: 'resignation_date',
              value: 'NULL',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345689',
              label: 'director_status_code',
              value: 'A',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345690',
              label: 'director_status_description',
              value: 'Active',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345691',
              label: 'director_type_code',
              value: 'D',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345692',
              label: 'director_type_description',
              value: 'Director',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345700',
              label: 'business_address_line1',
              value: '2 MARNE AVE',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345701',
              label: 'business_address_line2',
              value: 'CAPE TOWN',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345702',
              label: 'business_address_line3',
              value: 'CAPE TOWN',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345703',
              label: 'business_address_line4',
              value: 'WESTERN CAPE',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345704',
              label: 'business_address_post_code',
              value: '7708',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345705',
              label: 'postal_address_line1',
              value: '2 MARNE AVE',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345706',
              label: 'postal_address_line2',
              value: 'CAPE TOWN',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345707',
              label: 'postal_address_line3',
              value: 'CAPE TOWN',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345708',
              label: 'postal_address_line4',
              value: 'WESTERN CAPE',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345709',
              label: 'postal_address_post_code',
              value: '7708',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345710',
              label: 'registered_address_line1',
              value: '2 MARNE AVE',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345711',
              label: 'registered_address_line2',
              value: 'CAPE TOWN',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345712',
              label: 'registered_address_line3',
              value: 'CAPE TOWN',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345713',
              label: 'registered_address_line4',
              value: 'WESTERN CAPE',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345714',
              label: 'registered_address_post_code',
              value: '7708',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345715',
              label: 'residential_address_line1',
              value: '2 MARNE AVE',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345716',
              label: 'residential_address_line2',
              value: 'CAPE TOWN',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345717',
              label: 'residential_address_line3',
              value: 'CAPE TOWN',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345718',
              label: 'residential_address_line4',
              value: 'WESTERN CAPE',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345719',
              label: 'residential_address_post_code',
              value: '7708',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345720',
              label: 'country_code',
              value: 'ZA',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345721',
              label: 'rsa_resident',
              value: 'Yes',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
            {
              id: '17323879-1234-5678-1234-567812345722',
              label: 'last_updated',
              value: '2023-01-04',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7f6tuyvibkj',
              partner_id: '2',
            },
          ]);

          setIsLoading(false);
        } catch (err) {
          console.log(err);
          setShowError(true);
          setIsLoading(false);
        }
      })();
    }
  }, []);

  const updateResults = (
    id: string,
    value: number | string,
    analysis_label: string,
  ) => {
    // save to db
    (async () => {
      try {
        const company_id = selectedProductApplication?.company.id;
        const doc_label = selectedQuestion?.label;
        console.log(
          'updateResults',
          company_id,
          doc_label,
          id,
          value,
          analysis_label,
        );
      } catch (err) {
        console.log(err);
      }
    })();

    // update the results array
    const newResults = results.map(result => {
      if (result.id === id) {
        result.value = value.toString();
      }
      return result;
    });
    setResults(newResults);
  };

  const splitAndCapitalise = (str: string) => {
    // if there is an & in the string, split it and capitalise each word
    if (str.includes('&')) {
      const split = str.split('&');
      const capitalised = split.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      str = capitalised.join('&');
    }
    // takes underscores and capitalises the first letter of each word
    const split = str.split('_');
    const capitalised = split.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalised.join(' ');
  };

  const getVariableFromLabel = (label: string) => {
    // find the variable from the results array
    const variable = results.find(result => result.label === label);
    if (!variable) return null;
    return variable;
  };

  return (
    <div className="flex bg-stone-50 p-4 rounded-xl">
      {showError ? (
        <div className="text-5xl font-bold mt-20">
          Error reading your pdf. Please try again.
        </div>
      ) : isLoading ? (
        <div className="flex items-center mt-20">
          <Spinner />
          <div className="text-5xl font-bold ">
            Vula is analysing the document...
          </div>
        </div>
      ) : results ? (
        <div className=" flex justify-between overflow-scroll no-scrollbar h-[80vh] rounded-lg shadow bg-white">
          {/* i dont like using 80vh but we need to define some window height and I am being lazy by not querying the browser size - sorry */}
          <div className=" p-4">
            <div className="text-5xl font-bold pt-4 ">ID Document</div>
            <div className="text font-bold py-4">
              The document has been pre-analysed by Vula. <br />
              Please review the results below.
            </div>
            <div className="mx-8 flex items-center justify-between text-shadow bg-center bg-[url(https://vula-assets.s3.eu-west-2.amazonaws.com/hopeful.webp)] rounded-xl p-3">
              <img
                src="https://www.bizportal.gov.za/images/logo.png"
                alt="CIPC logo"
                className="h-6 mr-auto"
              />
              <div className="text-white font-bold text-xs">
                Supported by CIPC SA
              </div>
            </div>
            {/* notes */}
            <div className="py-4 ">
              <textarea
                className="w-full h-32 p-4 border border-solid border-stone-200 bg-white rounded-lg"
                placeholder="Add a comment here..."
                defaultValue={getVariableFromLabel('investor_notes')?.value}
                onBlur={e => {
                  updateResults(
                    getVariableFromLabel('investor_notes')?.id || '',
                    e.currentTarget.value,
                    'investor_notes',
                  );
                }}
              ></textarea>
            </div>
            {/* tables */}
            <table className="table-auto text-left text-sm pt-12 w-full">
              <tbody>
                {/* Table details - the extracted variables */}
                {getVariableFromLabel('document_currency') && (
                  <tr>
                    <td className=" bg-stone-50 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2"></td>
                    <td className=" bg-stone-50 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 text-xs">
                      {getVariableFromLabel('document_currency')?.value}{' '}
                      {getVariableFromLabel('document_currency')?.units + 's'}
                    </td>
                  </tr>
                )}
                {/* for each key of the results object */}
                {results.map(item => {
                  // We dont want to show the document currency or the investor notes as we display it above
                  if (
                    item.label === 'document_currency' ||
                    item.label === 'investor_notes'
                  ) {
                    return null;
                  }
                  return (
                    <>
                      <tr key={item.id} className="w-full">
                        <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2  font-bold">
                          {splitAndCapitalise(item.label)}
                        </td>
                        <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2  font-bold">
                          <input
                            type="text"
                            onBlur={e => {
                              updateResults(
                                item.id,
                                item.units
                                  ? takeLetterUnitsAndConvertToNumber(
                                      e.currentTarget.value,
                                    )
                                  : e.currentTarget.value,
                                item.label,
                              );
                            }}
                            defaultValue={
                              item.units
                                ? convertToReadableNumber(item.value)
                                : item.value
                            }
                          />
                        </td>
                      </tr>
                      {item.justification && (
                        <tr
                          key={item.id + '_justification'}
                          className="bg-stone-50 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 text-xs w-full"
                        >
                          <td colSpan={2} className="w-full p-1">
                            {item?.revised_by_user?.email
                              ? `Modified by ${item.revised_by_user.email}`
                              : item.justification}
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      <div className={' h-auto w-full p-4 '}>
        <DocumentRender answer={selectedQuestion?.answer} />
      </div>
    </div>
  );
}
