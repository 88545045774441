import axios from 'axios';

import { apiUrl } from '../methods/env';

type Props = {
  token?: string;
};

export class VulaChatAPI {
  private token?: string;

  constructor(props?: Props) {
    this.token = props?.token;
  }

  async askVulaChat(company_id: string, partner_id: string, question: string) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.post(
      `${apiUrl}/chat/${partner_id}/${company_id}`,
      {
        question,
      },
      config,
    );
  }
}
