import React from 'react';

import CheckIcon from '../Icons/CheckIcon';
import IncompletedCircle from '../Icons/IncompletedCircle';
import { ConfigContext } from '../../contexts/ConfigContext';

export default function CompanyProcessStatus() {
  const { config } = React.useContext(ConfigContext);

  if (!config?.features?.companyProcessingStatuses?.visible) return null;

  const processStatus = [
    {
      name: 'Info',
      status: 'completed',
      date: '2021-09-30',
    },
    {
      name: 'KYC',
      status: 'completed',
      date: '2021-09-30',
    },
    {
      name: 'AIP',
      status: 'completed',
      date: '2021-09-30',
    },
    {
      name: 'Deal',
      status: 'pending',
      date: '2021-09-30',
    },
  ];

  const processStatusMap: { [key: string]: () => React.ReactNode } = {
    info: () => <CheckIcon />,
    kyc: () => <CheckIcon />,
    aip: () => <CheckIcon />,
    deal: () => <IncompletedCircle />,
  };
  return (
    <div className="flex flex-row items-center">
      <div className="flex flex-row justify-center text-center">
        {processStatus.map(status => {
          if (processStatusMap[status.name?.toLowerCase()]) {
            return (
              <div className="flex flex-col items-center pt-4 pr-2">
                <div
                  className={
                    'text-xs font-light ' +
                    (status.status === 'completed'
                      ? 'fill-green-500'
                      : 'fill-gray-500')
                  }
                >
                  {
                    // eslint-disable-next-line
                    //@ts-ignore
                    processStatusMap[status.name?.toLowerCase()]()
                  }
                </div>
                <div className="text-xs font-light pt-2">{status.name}</div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
