import React from 'react';
import ArrowIcon from '../components/Icons/ArrowIcon';
import { useNavigate } from 'react-router-dom';
import { routes } from './routes';
import DetailWindow from '../components/CompanyNavigatorView/DetailWindow';
import TableOfCompanies from '../components/CompanyNavigatorView/TableOfCompanies';
import { CompanyData, sf_data } from '../data/sf';

export default function Search() {
  // ... paste the JSON data here
  const allData = sf_data as CompanyData[];

  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState('');
  const [selectedRow, setSelectedRow] = React.useState<
    CompanyData | undefined
  >();

  // on load get the search query from the search param in the url (search?s=)
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('s');
    if (searchQuery) {
      setSearchText(searchQuery);
    }

    // remove the search query from the url once consumed
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  return (
    <div>
      {/* search bar */}
      <div className="p-4  w-full m-auto flex justify-between ">
        {/* back to home */}
        <button
          className=" flex justify-center flex-row items-center pl-4"
          onClick={() => navigate(routes.home)}
        >
          <ArrowIcon />
          <div className="px-2 text-lg font-extrabold ">Back</div>
        </button>
        {/* search bar */}
        <div
          className={
            'max-w-[800px] w-full rounded-full  bg-white p-3 px-8 flex items-center  drop-shadow-sm border border-stone-200 border-solid border-1'
          }
        >
          <input
            type="text"
            placeholder="Search for names, geographies, verticals and more..."
            className="w-full bg-transparent outline-none"
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
          />
          <ArrowIcon pointingDirection="right" fill={'black'} />
        </div>
        {/* ensure center search bar */}
        <div></div>
      </div>

      {/* table */}
      <div className="flex h-full">
        <div className={' px-4 ' + (selectedRow ? 'w-2/3' : 'w-full')}>
          <TableOfCompanies
            onRowSelect={setSelectedRow}
            searchTerm={searchText}
            selectedRowId={selectedRow?.id}
          />
        </div>

        {selectedRow ? (
          <DetailWindow
            key={selectedRow?.id}
            selectedRowId={selectedRow?.id}
            closeModal={() => setSelectedRow(undefined)}
          />
        ) : null}
      </div>
    </div>
  );
}
