import React from 'react';
import { FinProductContext, section } from '../../contexts/FinProductContext';
import ApplicationQuestion from './ApplicationQuestion';
import ApplicationRepeatingQuestion from './ApplicationRepeatingQuestion';
import Spinner from '../utils/Spinner';

interface Props {
  section: section;
}

export default function SectionQuestion(props: Props) {
  const { selectedProductApplication } = React.useContext(FinProductContext);
  const { section } = props;

  // find all the non repeating label questions
  const nonRepeatingLabelQuestions = section.questions.filter(
    question => !question?.repeating_label,
  );

  // if any mandatory questions are not answered then show that we are waiting for the sme to answer
  // spot if question.required is true and question.answer is empty
  const isWaitingForSME = nonRepeatingLabelQuestions.some(
    question => question.required && !question.answer,
  );

  if (isWaitingForSME) {
    return (
      <div
        id={selectedProductApplication?.id}
        className="px-1 flex items-center bg-stone-100 border border-red-400 rounded-md m-2 p-2 px-4"
      >
        <Spinner />
        <div className="p-2  ">
          Waiting for SME to answer the mandatory questions.
        </div>
      </div>
    );
  }

  return (
    <div id={selectedProductApplication?.id} className="px-1">
      {/* render non-repeating */}
      {nonRepeatingLabelQuestions.map((question, index) => (
        <ApplicationQuestion
          key={question.id}
          question={question}
          index={index}
        />
      ))}
      {/* render repeating */}
      <ApplicationRepeatingQuestion section={section} />
    </div>
  );
}
