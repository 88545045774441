import React from 'react';
import DocumentRender from './DocumentRender';
import {
  FinProductContext,
  question,
} from '../../../contexts/FinProductContext';
import {
  convertToReadableNumber,
  takeLetterUnitsAndConvertToNumber,
} from '../../../methods/UIHelpers';
import Spinner from '../../utils/Spinner';

interface Props {
  selectedQuestion: question | null;
}

interface AnalysisData {
  label: string;
  value: string;
  units?: string | null;
  justification?: string | null;
  revised_by_user?: {
    email: string;
    id: string;
  } | null;
  attachment_id: string;
  partner_id: string;
  id: string;
}

export default function FAKEPLAnalysis(props: Props) {
  const { selectedQuestion } = props;
  const { selectedProductApplication } = React.useContext(FinProductContext);

  const [results, setResults] = React.useState<AnalysisData[]>([]);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // on load get the data from the api
  React.useEffect(() => {
    // when an new url is gathered, call the api for analysis
    if (selectedQuestion?.answer) {
      (async () => {
        try {
          setIsLoading(true);

          setResults([
            {
              id: '1',
              label: 'investor_notes',
              value:
                'The company demonstrates robust income generation with a total income of R654,874.74. However, substantial expenses, particularly in payroll, overshadow the revenues leading to a net loss of R57,122.44. High liabilities (R1,154,369.60) compounded by significant loans to directors and other non-current liabilities create an unfavorable debt-equity ratio, reflecting a concerning financial structure.\r\n\nThe negative equity (-R244,989.08) is alarming as it signifies financial distress unless the company takes corrective measures, such as reducing payroll expenses, optimizing cost structure, or potentially restructuring debt to improve financial health. The focus should also be on improving Net Income while keeping non-productive expenses in check to transition towards profitability.',
              units: null,
              justification: null,
              revised_by_user: null,
              attachment_id: '7ftuyig',
              partner_id: '2',
            },
            {
              id: 'ASDASDA',
              label: 'document_currency',
              value: 'R',
              units: '1',
              justification:
                'The balance sheet lists amounts in a format common to USD and includes no other currency symbols or indicators.',
              revised_by_user: null,
              attachment_id: '7ftuyig',
              partner_id: '2',
            },
            {
              id: 'fuygiukhjnl',
              label: 'total_income',
              value: '654,874.74',
              units: 'R',
              justification:
                'Composed primarily of Sales/Service Revenue (R660,314.74) and reduced by Discounts Given (R5,440.00).',
              revised_by_user: null,
              attachment_id: '7ftuyig',
              partner_id: '2',
            },
            {
              id: 'abcd1234',
              label: 'total_cost_of_sales',
              value: '117,705.44',
              units: 'R',
              justification:
                'Major items include Subcontractors - COS (R110,204.55), which is significantly larger than other COS components like Flights, Meals, and Stationery.',
              revised_by_user: null,
              attachment_id: '7ftuyig',
              partner_id: '2',
            },
            {
              id: 'efgh5678',
              label: 'gross_profit',
              value: '537,169.30',
              units: 'R',
              justification:
                'This is derived from Total Income minus Total COS.',
              revised_by_user: null,
              attachment_id: '7ftuyig',
              partner_id: '2',
            },
            {
              id: 'ijkl9101',
              label: 'total_expenses',
              value: '594,296.85',
              units: 'R',
              justification:
                'Payroll Expense (R525,054.69) is the predominant expense, followed by Legal and Professional Fees (R26,452.39).',
              revised_by_user: null,
              attachment_id: '7ftuyig',
              partner_id: '2',
            },
            {
              id: 'mnop1121',
              label: 'net_earnings',
              value: '-57,122.44',
              units: 'R',
              justification:
                'The company exhibits a net loss, attributed largely to high Payroll and Legal & Professional Fees, potentially suggesting inefficiencies or overstaffing costs.',
              revised_by_user: null,
              attachment_id: '7ftuyig',
              partner_id: '2',
            },
          ]);

          setIsLoading(false);
        } catch (err) {
          console.log(err);
          setShowError(true);
          setIsLoading(false);
        }
      })();
    }
  }, []);

  const updateResults = (
    id: string,
    value: number | string,
    analysis_label: string,
  ) => {
    // save to db
    (async () => {
      try {
        const company_id = selectedProductApplication?.company.id;
        const doc_label = selectedQuestion?.label;
        console.log(
          'updateResults',
          company_id,
          doc_label,
          id,
          value,
          analysis_label,
        );
      } catch (err) {
        console.log(err);
      }
    })();

    // update the results array
    const newResults = results.map(result => {
      if (result.id === id) {
        result.value = value.toString();
      }
      return result;
    });
    setResults(newResults);
  };

  const splitAndCapitalise = (str: string) => {
    // if there is an & in the string, split it and capitalise each word
    if (str.includes('&')) {
      const split = str.split('&');
      const capitalised = split.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      str = capitalised.join('&');
    }
    // takes underscores and capitalises the first letter of each word
    const split = str.split('_');
    const capitalised = split.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalised.join(' ');
  };

  const getVariableFromLabel = (label: string) => {
    // find the variable from the results array
    const variable = results.find(result => result.label === label);
    if (!variable) return null;
    return variable;
  };

  return (
    <div className="flex bg-stone-50 p-4 rounded-xl">
      {showError ? (
        <div className="text-5xl font-bold mt-20">
          Error reading your pdf. Please try again.
        </div>
      ) : isLoading ? (
        <div className="flex items-center mt-20">
          <Spinner />
          <div className="text-5xl font-bold ">
            Vula is analysing the document...
          </div>
        </div>
      ) : results ? (
        <div className="w-1/2 flex justify-between overflow-scroll no-scrollbar h-[80vh]">
          {/* i dont like using 80vh but we need to define some window height and I am being lazy by not querying the browser size - sorry */}
          <div className=" p-4">
            <div className="text-5xl font-bold ">P&L Statement Analysis</div>
            <div className="text font-bold py-4">
              The document has been pre-analysed by Vula. <br />
              Please review the results below.
            </div>
            {/* notes */}
            <div className="py-4 ">
              <textarea
                className="w-full h-24 p-4 border border-solid border-stone-200 bg-white rounded-lg"
                placeholder="Add a comment here..."
                defaultValue={getVariableFromLabel('investor_notes')?.value}
                onBlur={e => {
                  updateResults(
                    getVariableFromLabel('investor_notes')?.id || '',
                    e.currentTarget.value,
                    'investor_notes',
                  );
                }}
              ></textarea>
            </div>
            {/* tables */}
            <table className="table-auto text-left text-sm pt-12">
              <tbody>
                {/* Table details - the extracted variables */}
                {getVariableFromLabel('document_currency') && (
                  <tr>
                    <td className=" bg-stone-50 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2"></td>
                    <td className=" bg-stone-50 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 text-xs">
                      {getVariableFromLabel('document_currency')?.value}{' '}
                      {getVariableFromLabel('document_currency')?.units + 's'}
                    </td>
                  </tr>
                )}
                {/* for each key of the results object */}
                {results.map(item => {
                  // We dont want to show the document currency or the investor notes as we display it above
                  if (
                    item.label === 'document_currency' ||
                    item.label === 'investor_notes'
                  ) {
                    return null;
                  }
                  return (
                    <>
                      <tr key={item.id}>
                        <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 w-[60%]] font-bold">
                          {splitAndCapitalise(item.label)}
                        </td>
                        <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 w-[20%] font-bold">
                          <input
                            type="text"
                            onBlur={e => {
                              updateResults(
                                item.id,
                                item.units
                                  ? takeLetterUnitsAndConvertToNumber(
                                      e.currentTarget.value,
                                    )
                                  : e.currentTarget.value,
                                item.label,
                              );
                            }}
                            defaultValue={
                              item.units
                                ? convertToReadableNumber(item.value)
                                : item.value
                            }
                          />
                        </td>
                      </tr>
                      {item.justification && (
                        <tr
                          key={item.id + '_justification'}
                          className="bg-stone-50 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 text-xs w-full"
                        >
                          <td colSpan={2} className="w-full p-1">
                            {item?.revised_by_user?.email
                              ? `Modified by ${item.revised_by_user.email}`
                              : item.justification}
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      <div className={' h-auto  p-4  w-1/2 '}>
        <DocumentRender answer={selectedQuestion?.answer} />
      </div>
    </div>
  );
}
