import React from 'react';
import HomeNavBar from '../components/Home/HomeNavBar';
import Sankey from '../components/ApplicationOverview/Sankey';

export default function ApplicationsOverview() {
  return (
    <div className="h-full bg-stone-50 ">
      <HomeNavBar />
      <Sankey />
    </div>
  );
}
