import React from 'react';

import HomeNavBar from '../components/Home/HomeNavBar';
import InProgressCard from '../components/Home/InProgressCard';
import { track } from '../interfaces/mixpanel';

import { useAuth0 } from '@auth0/auth0-react';
import { VulaPartnerProductsAPI } from '../api/products';
import Spinner from '../components/utils/Spinner';
import HomePageContent from '../components/Home/HomePageContent';
import { ConfigContext } from '../contexts/ConfigContext';
import Hello from '../components/Home/Hello';

export interface PartnerProducts {
  id: string;
  product_name: string;
  aip_agreement_url: string;
  image?: string;
}

export default function Home() {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const { config } = React.useContext(ConfigContext);

  const [partnerProducts, setPartnerProducts] = React.useState<
    PartnerProducts[]
  >([]);
  const [isLoadingProducts, setLoadingProducts] = React.useState(true);

  // on load record view
  React.useEffect(() => {
    track('Home view');
    (async () => {
      await getPartnerProductsFromDb();
    })();
  }, [isLoading]);

  const getPartnerProductsFromDb = async () => {
    setLoadingProducts(true);
    const token = await getAccessTokenSilently();
    const api = new VulaPartnerProductsAPI({ token });

    const products = await api.getPartnerProducts();
    setPartnerProducts(products.data || []);
    setLoadingProducts(false);
  };

  return (
    <div className="pb-12">
      <HomeNavBar />
      <div className=" max-w-[1200px] sm:w-[90%] m-auto px-2 ">
        <div className="flex flex-col items-center">
          {isLoadingProducts ? (
            <Spinner />
          ) : (
            <>
              {/* hello and search bar */}
              <HomePageContent />
              {/* management tools */}
              <Hello />
              {/* Products */}
              <div className="max-w-[900px]  rounded-2xl bg-white shadow p-1 w-full m-auto">
                {partnerProducts.length === 0 ? (
                  <div className="flex flex-col items-center">
                    <h1 className="text-left text-xl sm:text-5xl font-extrabold py-4">
                      No Products Found
                    </h1>
                    <p className="text-left text-lg sm:text-2xl font-bold py-4">
                      Please contact your account manager to add products
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="text-lg font-extrabold py-4 text-left pl-12">
                      Products:
                    </div>
                    {partnerProducts.map(product => (
                      <div className="max-w-[900px]  w-full " key={product.id}>
                        <InProgressCard
                          key={product.id}
                          headline={product.product_name}
                          subtitle=""
                          onClick={() => {
                            window.location.href = `/product/${product.id}`;
                          }}
                          background={product.image}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
