import React from 'react';
import { FinProductContext } from '../../../contexts/FinProductContext';

export default function SendOffer() {
  const { selectedProductApplication } = React.useContext(FinProductContext);

  const [sent, setSent] = React.useState(false);

  if (sent) {
    return (
      <div className=" ">
        <div className="text-lg font-bold">Offer Sent</div>
        <div className="flex items-center">
          <div className="py-2 ">
            Vula has sent the offer to the company. You will be notified when
            they respond.
          </div>
          <img
            src="https://vula-assets.s3.eu-west-2.amazonaws.com/icons/sent.svg"
            className="w-32 h-32"
          />
        </div>
      </div>
    );
  }
  // allows the user to send the company an offer using the Vula platform
  // the ui asks the user to confirm they are sure
  // then asks the user to write a message to the company
  return (
    <div>
      <div className="flex items-center">
        <img
          src={
            'https://vula-assets.s3.eu-west-2.amazonaws.com/icons/signature-solid.svg'
          }
          className="w-8 h-8 mr-2"
        />
        <div className="text-lg font-bold">Send an offer</div>
      </div>
      <div className="py-2 ">
        Please write a message to the company welcoming them to the company and
        offering them the loan.
      </div>
      <div className="text-sm pb-2">
        This will be read by Vula and Vula will then search the company's data.
        If it cannot be found a request will be sent to the customer for them to
        provide.
      </div>
      <div className="text-sm ">
        <textarea
          className="h-40 border border-solid border-1 border-stone-200 rounded p-2 text-left w-full"
          defaultValue={
            `Hi ${selectedProductApplication?.company.name},\n\n` +
            `We are happy to offer you a loan of [[INSERT HERE]] at an interest rate of [[INSERT HERE]]%.\n\n` +
            `Please let us know if you have any questions.\n\n` +
            `Regards,\n\n`
          }
        />
      </div>
      <div className="flex flex-row justify-end pt-4 text-center">
        <button
          onClick={() => setSent(true)}
          className="bg-white border border-solid border-1 border-stone-200 rounded p-2 text-left w-32"
        >
          Send
        </button>
      </div>
    </div>
  );
}
