import React from 'react';

export default function Kanban() {
  const products: {
    id: string;
    product_name: string;
    applications: {
      id: string;
      status: string;
      updated_at?: number;
      company: {
        name: string;
        logo: string;
      };
      assigned_to: {
        name: string;
        image: string;
      };
    }[];
  }[] = [
    {
      id: '987YT6FYGHVJBIOUGYFT',
      product_name: 'Purchase Order Finance',
      applications: [
        {
          id: 'bda0b8ea-8ac5-4bb4-84f6-59bbc74e9e6e',
          status: 'Submitted',
          updated_at: Number(new Date()),
          company: {
            name: 'Fisher Wavy',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/092023/fisher_wavy.jpg?Jbw5tEeEomJdbxvqZwAXZuu7w_y3MIQC&itok=U3g-RmB0',
          },
          assigned_to: {
            name: 'Bongani',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Bongani.jpeg',
          },
        },
        {
          id: 'd5043d29-130e-4e99-9084-61f3abf48bcf',
          status: 'Lending',
          updated_at: Number(new Date()) - 3 * 24 * 60 * 60 * 1000,
          company: {
            name: 'Bizon Solutions',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/082023/bizo100.jpg?CfXnL_bURkErYaJT5ygRxooAYzWCzGvq&itok=8NtyM3ze',
          },
          assigned_to: {
            name: 'Connor',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Connor.jpeg',
          },
        },
        {
          id: '988f4e14-cfb8-4b8d-ae05-4e767a65bf85',
          status: 'Lending',
          updated_at: Number(new Date()) - 10 * 24 * 60 * 60 * 1000,
          company: {
            name: 'WB',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/042023/wholebranding_digital_signatures_logo_0.png?2lX_Gx4WEDYsaGtaYgyo7u5nL3LBBcaN&itok=pF0gnzgQ',
          },
          assigned_to: {
            name: 'Naseerah',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Naseerah.jpeg',
          },
        },
        {
          id: '5deb3f0b-b202-4afa-8eff-9ef11d387c1f',
          status: 'Submitted',
          updated_at: Number(new Date()) - 12 * 24 * 60 * 60 * 1000,
          company: {
            name: 'Bomart',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/052020/bomart_final_logo-01_0.jpg?jW_oHO_L_HUPHJH7h5UC3UQOgBRLLYH8&itok=blot3hSw',
          },
          assigned_to: {
            name: 'Ntokozo',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Ntokozo.jpeg',
          },
        },
        {
          id: '021ad397-7028-45e1-a3e8-24e83e464713',
          status: 'Submitted',
          updated_at: Number(new Date()) - 4 * 24 * 60 * 60 * 1000,
          company: {
            name: 'DWS Energy',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/062023/dws_energy_hor_logo.png?li4Z..xzYBMQuQFHNhBrH809b_C4bt2B&itok=hOB3AMm-',
          },
          assigned_to: {
            name: 'Rayna',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Rayna.jpeg',
          },
        },
        {
          id: 'f6a5374a-ade4-4c9d-baa7-9f958c328457',
          status: 'First consultation',
          updated_at: Number(new Date()) - 5 * 24 * 60 * 60 * 1000,
          company: {
            name: 'Uno Innovation',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/072022/download.png?ZlR6fnz7EUCwUPmNCNLVsMUVecTACbM4&itok=xLaAVL1R',
          },
          assigned_to: {
            name: 'Sinesipo',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Sinesipo.jpeg',
          },
        },
        {
          id: '4869a379-6729-4e60-9dd3-7f7a8f85ee81',
          status: 'Reviewing',
          updated_at: Number(new Date()) - 2 * 24 * 60 * 60 * 1000,
          company: {
            name: 'Meter Partners',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/062020/mp.jpg?NPaXfRiJWKk1xY_ivClP4BIB8lIOPV4Q&itok=daL_z5GR',
          },
          assigned_to: {
            name: 'Xhoba',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Xhoba.jpeg',
          },
        },
        {
          id: 'b3702b7b-d514-4114-ab93-63d7bf69d1ed',
          status: 'Final consultation',
          updated_at: Number(new Date()) - 10 * 24 * 60 * 60 * 1000,
          company: {
            name: 'Clock Cleaners',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/102023/logo_cakap.png?E2Gk2v_tNzlGwUjT920tkjrLDYKdRrOv&itok=5agfx0nm',
          },
          assigned_to: {
            name: 'Bongani',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Bongani.jpeg',
          },
        },
        {
          id: 'd85dac3b-5783-47f2-8a0b-075db9caae82',
          status: 'Lending',
          updated_at: Number(new Date()) - 1 * 24 * 60 * 60 * 1000,
          company: {
            name: 'RMI Exporters',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/102023/rmi_1.png?l.4SGgSgXb7vBrDvxbnvT1LzIsnXGDl4&itok=-nS_OKs_',
          },
          assigned_to: {
            name: 'Connor',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Connor.jpeg',
          },
        },
      ],
    },
    {
      id: '187YT6FYGHVJBIOUGYFT',
      product_name: 'Asset Finance',
      applications: [
        {
          id: '97a5fea9-6f70-47e9-9d97-2e62edb2bee1',
          status: 'Submitted',
          updated_at: Number(new Date()),
          company: {
            name: 'Turner Construction',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/112019/turner-trans.png?E71AnBW_jrqP95HypFaoZA0To5JlrXj2&itok=4HWO7nw5',
          },
          assigned_to: {
            name: 'Connor',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Connor.jpeg',
          },
        },
        {
          id: '21e6da5c-c62c-4f3d-93ad-9ec75d1a7596',
          status: 'Submitted',
          updated_at: Number(new Date()) - 20 * 24 * 60 * 60 * 1000,
          company: {
            name: 'BBG',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/122019/screen_shot_2019-12-16_at_9.36.22_am.png?S9EafRbafrtUDZOGHwctuieh3m4_8w.Z&itok=geHCrrjP',
          },
          assigned_to: {
            name: 'Ntokozo',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Ntokozo.jpeg',
          },
        },
        {
          id: '894ec658-4c75-4cab-a50a-4ab912b8c5f6',
          status: 'Submitted',
          updated_at: Number(new Date()),
          company: {
            name: 'Feratil',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/012020/iho.png?y0OzzWIJ28deTosVv7npgtM_txMXVX94&itok=Mt-tJVZD',
          },
          assigned_to: {
            name: 'Rayna',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Rayna.jpeg',
          },
        },
        {
          id: '01ee3cf0-921e-44bb-b330-993634d98b0a',
          status: 'Lending',
          updated_at: Number(new Date()),

          company: {
            name: 'Galalelo Pool Repair',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/072015/rysunek1.jpg?itok=pSlhNaZH',
          },
          assigned_to: {
            name: 'Sinesipo',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Sinesipo.jpeg',
          },
        },
        {
          id: '0d1062f8-1b8b-4e9d-b759-0ac753a4d6dc',
          status: 'Lending',
          updated_at: Number(new Date()) - 10 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Max Logistics',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/042023/immax_logo_red.png?4R5phkB7Crd4XqKgZcGngBuKGBu9o1BN&itok=ME7Ez08x',
          },
          assigned_to: {
            name: 'Bongani',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Bongani.jpeg',
          },
        },
        {
          id: 'd0aa14e3-7497-4e24-b8b6-edf445abae33',
          status: 'Lending',
          updated_at: Number(new Date()) - 3 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Corobrik',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/092022/icon.jpg?VjKFsqqAOOtca2OkV2K8ufh0ddWEEyjX&itok=MXSr7hBy',
          },
          assigned_to: {
            name: 'Xhoba',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Xhoba.jpeg',
          },
        },
        {
          id: '6cdc3d2c-de53-4e15-9c3b-9d36c9c0ff9c',
          status: 'Reviewing',
          updated_at: Number(new Date()) - 2 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Teknosa Fibre',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/112021/teknosa-automotive-spares-logo.png?QTQRkLkGv04m8IxfahZXWrsawzK.czi8&itok=KztwwBNZ',
          },
          assigned_to: {
            name: 'Naseerah',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Naseerah.jpeg',
          },
        },
        {
          id: 'f99254d0-77cf-4dab-87bf-e47e1dcf5ed4',
          status: 'Final consultation',
          updated_at: Number(new Date()) - 20 * 24 * 60 * 60 * 1000,

          company: {
            name: 'LM Attorneys',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/102020/logo_copy1x.png?ii5lfAWEBM532Pw.5_a2tFOHEtiSCdCx&itok=A8as8stJ',
          },
          assigned_to: {
            name: 'Connor',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Connor.jpeg',
          },
        },
        {
          id: '5efd2506-1135-4b52-9a12-ae3c2d9f1f1a',
          status: 'Lending',
          updated_at: Number(new Date()),

          company: {
            name: 'African Tiger Logistics',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/042019/africa_lion_map.jpg?OgUwaxyBwQPoOzq0pKN129aC4SEzInJ4&itok=Z3zTBpBU',
          },
          assigned_to: {
            name: 'Rayna',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Rayna.jpeg',
          },
        },
        {
          id: '77ebf59e-7893-4aa9-beb2-cd8a16d8a2e0',
          status: 'Submitted',
          updated_at: Number(new Date()),

          company: {
            name: 'iKhokha',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/082019/ikhokha_logo.png?CGqBL2bbnlVYk8_1ZL16HNz5wSCkhMzA&itok=RCV_lugR',
          },
          assigned_to: {
            name: 'Ntokozo',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Ntokozo.jpeg',
          },
        },
      ],
    },
    {
      id: '287YT6FYGHVJBIOUGYFT',
      product_name: 'Property Finance',

      applications: [
        {
          id: '30c5c1c5-63be-4d99-8184-3311639096c1',
          status: 'Lending',
          updated_at: Number(new Date()),

          company: {
            name: 'Atomic Design Studio',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/102014/logo-only_0.png?itok=n_9qb7hH',
          },
          assigned_to: {
            name: 'Xhoba',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Xhoba.jpeg',
          },
        },
        {
          id: '1752ea81-7725-43f5-83f8-08979d4ef050',
          status: 'Reviewing',
          updated_at: Number(new Date()),

          company: {
            name: 'ACDC Express',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/102014/acdc_express.jpg?itok=0yOqMbbm',
          },
          assigned_to: {
            name: 'Naseerah',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Naseerah.jpeg',
          },
        },
        {
          id: '43560e92-eb7a-43d4-9d4e-0d13046e5bf1',
          status: 'Submitted',
          updated_at: Number(new Date()),

          company: {
            name: 'Legacy Hotels',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/072014/legacy_hotels_and_resorts.png?itok=AJD4VCLp',
          },
          assigned_to: {
            name: 'Bongani',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Bongani.jpeg',
          },
        },
        {
          id: '2737573a-35c2-465d-80a5-29d9f6784d7b',
          status: 'Submitted',
          updated_at: Number(new Date()) - 20 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Coffee Break',
            logo: 'https://coffeevendingmachines.co.za/wp-content/uploads/2019/04/coffee-break-vending-logo.png',
          },
          assigned_to: {
            name: 'Rayna',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Rayna.jpeg',
          },
        },
        {
          id: 'fa808efa-8fd9-4df3-b8f7-6f2dc70a9ae3',
          status: 'Lending',
          updated_at: Number(new Date()) - 2 * 24 * 60 * 60 * 1000,

          company: {
            name: 'GC Photography',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/122016/untitled-1_35.jpg?itok=7qS2vr62',
          },
          assigned_to: {
            name: 'Sinesipo',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Sinesipo.jpeg',
          },
        },
        {
          id: '30bfdc7f-8bf5-44a3-9350-3f68b9e72b47',
          status: 'Submitted',
          updated_at: Number(new Date()) - 20 * 24 * 60 * 60 * 1000,

          company: {
            name: 'JAG Catering supplies',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/052016/jag_catering_equipment_0.png?itok=h7C6oT99',
          },
          assigned_to: {
            name: 'Ntokozo',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Ntokozo.jpeg',
          },
        },
        {
          id: '9ff61f14-7db7-4c36-a019-7c781e3cfb69',
          status: 'Reviewing',
          updated_at: Number(new Date()) - 20 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Bogart Clothing',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/102013/bogart.jpg?itok=dIamfMyy',
          },
          assigned_to: {
            name: 'Connor',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Connor.jpeg',
          },
        },
        {
          id: '3b5a11e0-169a-4f12-b4b6-1a5ca06becc6',
          status: 'Submitted',
          updated_at: Number(new Date()) - 20 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Xpanda',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/022012/xpanda.jpg?itok=3kMsk_gu',
          },
          assigned_to: {
            name: 'Xhoba',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Xhoba.jpeg',
          },
        },
        {
          id: '5c4e140a-8c7e-475e-8756-60aa7801806b',
          status: 'Submitted',
          updated_at: Number(new Date()) - 10 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Dynamic Vision',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/032011/dynamic_vision.gif?itok=Z5S9J4Wp',
          },
          assigned_to: {
            name: 'Rayna',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Rayna.jpeg',
          },
        },
        {
          id: '4b1c9910-a23c-409d-9e0d-35c827ae2d52',
          status: 'Lending',
          updated_at: Number(new Date()) - 2 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Cerebra Technologies',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/122010/logo_cerebra_trace_landscape.png?itok=imJ3uwuv',
          },
          assigned_to: {
            name: 'Sinesipo',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Sinesipo.jpeg',
          },
        },
        {
          id: '2d6fca9d-1373-406e-af32-ecaba90c06c3',
          status: 'First consultation',
          updated_at: Number(new Date()) - 20 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Quamta Asset Managers',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/092010/quamta_word_marque_0.jpeg?itok=uBsvs8VH',
          },
          assigned_to: {
            name: 'Connor',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Connor.jpeg',
          },
        },
        {
          id: 'b1f9ac0a-3e97-4eb5-ab1a-34dea1715b9e',
          status: 'Submitted',
          updated_at: Number(new Date()) - 2 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Waltons',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/062010/Waltons.gif?itok=NRHt0gvz',
          },
          assigned_to: {
            name: 'Bongani',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Bongani.jpeg',
          },
        },
        {
          id: 'fd37c852-8857-4b94-ad62-27e32eecc7ef',
          status: 'Lending',
          updated_at: Number(new Date()) - 1 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Hasso Gyms',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/0025/2582/brand.gif?itok=XZzTWQ7V',
          },
          assigned_to: {
            name: 'Naseerah',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Naseerah.jpeg',
          },
        },
        {
          id: 'd8279960-39d0-43bc-8742-af5f7cc39640',
          status: 'Final consultation',
          updated_at: Number(new Date()) - 2 * 24 * 60 * 60 * 1000,

          company: {
            name: 'Vega Brand School',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/0023/5250/brand.gif?itok=zDQgjARS',
          },
          assigned_to: {
            name: 'Ntokozo',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Ntokozo.jpeg',
          },
        },
        {
          id: '0b206184-c2cc-4282-b14a-74e6af565320',
          status: 'Reviewing',
          updated_at: Number(new Date()),

          company: {
            name: 'Safilo',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/0022/7358/brand.gif?itok=QljvOzQY',
          },
          assigned_to: {
            name: 'Rayna',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Rayna.jpeg',
          },
        },
        {
          id: 'd6d99f2a-b7c0-4596-9ce8-c1533ea1043b',
          status: 'Submitted',
          updated_at: Number(new Date()),

          company: {
            name: 'Formula 40 Manufacturers',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/0022/7149/brand.gif?itok=RSpim_ss',
          },
          assigned_to: {
            name: 'Bongani',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Bongani.jpeg',
          },
        },
        {
          id: '8c1c0576-cff5-423a-a59d-ffe0d0d98d25',
          status: 'Lending',
          updated_at: Number(new Date()),

          company: {
            name: 'Finegrafix Studios',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/0021/2346/brand.gif?itok=0eWaKtGg',
          },
          assigned_to: {
            name: 'Sinesipo',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Sinesipo.jpeg',
          },
        },
        {
          id: 'fca26771-ec8b-4a09-a4d5-262347acdb04',
          status: 'First consultation',
          updated_at: Number(new Date()),

          company: {
            name: 'Blitsdruk',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/0017/2942/brand.gif?itok=knLymTny',
          },
          assigned_to: {
            name: 'Connor',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Connor.jpeg',
          },
        },
        {
          id: 'd970f14b-1af0-4a1c-b92d-5062e1e37748',
          status: 'Georgia Blankets',
          updated_at: Number(new Date()),

          company: {
            name: 'Urban Garden',
            logo: 'https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/062012/tourism_logo_of_georgia-converted.png?itok=B6H96F_Z',
          },
          assigned_to: {
            name: 'Ntokozo',
            image:
              'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Ntokozo.jpeg',
          },
        },
      ],
    },
  ];

  const statuses = [
    'Submitted',
    'First consultation',
    'Final consultation',
    'Reviewing',
    'Lending',
  ];

  const programmes_statuses_dwell_time_averages = [
    {
      product_name: 'Purchase Order Finance',
      statuses: [
        {
          status: 'Submitted',
          average: 3,
        },
        {
          status: 'First consultation',
          average: 5,
        },
        {
          status: 'Final consultation',
          average: 10,
        },
        {
          status: 'Reviewing',
          average: 2,
        },
        {
          status: 'Lending',
          average: 50,
        },
      ],
    },
    {
      product_name: 'Asset Finance',
      statuses: [
        {
          status: 'Submitted',
          average: 5,
        },
        {
          status: 'First consultation',
          average: 3,
        },
        {
          status: 'Final consultation',
          average: 10,
        },
        {
          status: 'Reviewing',
          average: 2,
        },
        {
          status: 'Lending',
          average: 50,
        },
      ],
    },
    {
      product_name: 'Property Finance',
      statuses: [
        {
          status: 'Submitted',
          average: 5,
        },
        {
          status: 'First consultation',
          average: 3,
        },
        {
          status: 'Final consultation',
          average: 10,
        },
        {
          status: 'Reviewing',
          average: 2,
        },
        {
          status: 'Lending',
          average: 50,
        },
      ],
    },
  ];

  const [filterProducts, setFilterProducts] = React.useState(products);
  const [programmeFilter, setProgrammeFilter] = React.useState('all');

  const [individualFilter, setIndividualFilter] = React.useState('all');

  React.useEffect(() => {
    let newProducts = products;
    if (programmeFilter !== 'all') {
      newProducts = newProducts.filter(
        product => product.product_name === programmeFilter,
      );
    }

    if (individualFilter !== 'all') {
      newProducts = newProducts.map(product => {
        return {
          ...product,
          applications: product.applications.filter(
            application => application.assigned_to.name === individualFilter,
          ),
        };
      });
    }

    setFilterProducts(newProducts);
  }, [individualFilter, programmeFilter]);

  return (
    <div className="h-full min-h-[80vh] p-2 ">
      {/* this will be a kanban view of all the applications on the system */}
      {/* we should show the status as columns having the most completed stage on the right */}
      {/* There should be a clear division/divider between the products */}

      {/* filters */}
      <div className=" grid grid-cols-3 gap-4 p-4 max-w-[1000px] m-auto">
        {/* programme */}
        <select
          className="rounded-full border border-1 border-solid border-stone-300 p-1 px-4"
          onChange={e => setProgrammeFilter(e.target.value)}
        >
          <option value="all">Select Programme</option>
          {products.map(product => (
            <option value={product.product_name}>{product.product_name}</option>
          ))}
        </select>
        {/* team */}
        <select className="rounded-full border border-1 border-solid border-stone-300 p-1 px-4">
          <option value="all">Select Team</option>
          <option value="Team-1">Team 1</option>
          <option value="Team-2">Team 2</option>
          <option value="Team-3">Team 3</option>
        </select>
        {/* individuals */}
        <select
          className="rounded-full border border-1 border-solid border-stone-300 p-1 px-4"
          onChange={e => setIndividualFilter(e.target.value)}
        >
          <option value="all">Select Individual</option>
          {
            // dedupe the assigned to names and show them in the dropdown
            products
              .map(product => product.applications)
              .flat()
              .map(application => application.assigned_to.name)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map(name => (
                <option value={name}>{name}</option>
              ))
          }
        </select>
      </div>

      <div className="flex flex-col">
        {filterProducts.map(product => (
          <div className="flex flex-col pt-20">
            <h1 className="text-2xl font-bold bg-stone-50 rounded">
              {product.product_name}
            </h1>
            <div className="flex flex-row">
              {statuses.map(status => (
                <div className="flex flex-col w-full border border-1 border-solid border-stone-300 bg-stone-200 rounded drop-shadow m-2">
                  <h1 className="text-lg font-bold bg-stone-50 rounded p-2">
                    {status}
                    <h4 className="text-xs text-gray-500 p-2 scale-[0.8]">
                      {
                        programmes_statuses_dwell_time_averages
                          .filter(
                            programme =>
                              programme.product_name === product.product_name,
                          )[0]
                          .statuses.filter(
                            programme_status =>
                              programme_status.status === status,
                          )[0].average
                      }{' '}
                      days average dwell time
                    </h4>
                  </h1>
                  {product.applications
                    .filter(application => application.status === status)
                    .map(application => (
                      // add a nice kanban card design
                      <button
                        className={
                          'hover:bg-stone-100 rounded-md p-4 m-2 drop-shadow text-left flex justify-between items-center ' +
                          // if date is over 10 days old, make the card a light amber color
                          (application.updated_at &&
                          (Number(new Date()) - application.updated_at) /
                            (1000 * 60 * 60 * 24) >
                            7
                            ? ' bg-amber-100'
                            : ' bg-stone-50')
                        }
                      >
                        <div className="flex flex-col">
                          <h1 className="text-lg ">
                            {application.company.name}
                          </h1>
                          <div className="flex items-center">
                            <img
                              src={application.assigned_to.image}
                              className="rounded-full h-6 w-6 mr-2"
                            />

                            <h1 className="text-sm ">
                              {application.assigned_to.name}
                            </h1>
                          </div>
                          {/* show days since updated if over 5 days */}
                          {application.updated_at &&
                          (Number(new Date()) - application.updated_at) /
                            (1000 * 60 * 60 * 24) >
                            2 ? (
                            <p className="text-xs text-gray-500 pt-4">
                              {Math.floor(
                                (Number(new Date()) - application.updated_at) /
                                  (1000 * 60 * 60 * 24),
                              )}{' '}
                              days since updated
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="rounded-full flex items-center h-10 w-10">
                          <img
                            // have the rhs fade into the logo of the company
                            className=" "
                            src={application.company.logo}
                          />
                        </div>
                      </button>
                    ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
