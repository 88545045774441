import React, { useState } from 'react';
import InboxCard from './InboxCard';
import {
  applicationsSummary,
  FinProductContext,
} from '../../contexts/FinProductContext';
import ClosedCaretIcon from '../Icons/CaretIcon';

interface Props {
  type: string;
  companyMatches: applicationsSummary[] | undefined;
  isSearching?: boolean; // so expand all when searching
}

export default function GrantInboxDivider(props: Props) {
  const { selectedApplicationId } = React.useContext(FinProductContext);
  const [animate, setAnimate] = useState(false);
  const [isOpen, setIsOpen] = useState(
    props.type === 'archived'
      ? false
      : props.type === 'rejected'
      ? false
      : true,
  );

  // if isSearching is true, open all dividers
  React.useEffect(() => {
    if (props.isSearching) {
      setIsOpen(true);
    }
  }, [props.isSearching]);

  // if selectedMatch is in this divider, open it
  React.useEffect(() => {
    if (
      props.companyMatches?.findIndex(
        match => match.id === selectedApplicationId,
      ) !== -1
    ) {
      setIsOpen(true);
    } else {
      if (!props.isSearching) {
        setIsOpen(false);
      }
    }
  }, [selectedApplicationId, props.companyMatches]);

  // on load animate the divider
  React.useEffect(() => {
    // Enable animation after a short delay
    const timeout = setTimeout(() => {
      setAnimate(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  // Exponential delay calculation function with decreasing delays
  const calculateDelay = (index: number) => {
    // Calculate the delay for each card where index=0 delay=X, index=1 delay=X + X/2, index=2 delay=X + X/2 + X/4, etc.
    const X = 25;
    const initialDelay = 0;
    let cumulativeDelay = initialDelay; // set the initial value as less than 0 to allow for the first card to be displayed immediately
    for (let i = 0; i < index; i++) {
      cumulativeDelay += X / Math.pow(1.1, i);
    }
    return cumulativeDelay;
  };

  const prettifyTypeName = (type: string) => {
    // find all capital letters in the word and add a space before them
    const prettyType = type
      ?.replaceAll('_', ' ')
      ?.replaceAll(/([A-Z])/g, ' $1');
    // uppercase the first letter of each word
    const prettyTypeCapitalized = prettyType
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return prettyTypeCapitalized;
  };

  return (
    <div className="w-full ">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={
          'bg-white h-14 p-4 w-full flex flex-row justify-between hover:bg-gray-100 border-b border border-gray-100 border-solid border-1'
        }
      >
        {/* title and number */}
        <div className="items-center flex flex-row ">
          <span className="flex justify-center items-center text-center m-auto text-xs mx-2 w-[20px] h-[20px] bg-gray-200 rounded-full">
            {props.companyMatches?.length ?? 0}
          </span>
          <span>{prettifyTypeName(props.type)}</span>
        </div>

        <ClosedCaretIcon isOpen={isOpen} />
      </button>

      {isOpen && (
        <div className="bg-stone-100 grid grid-cols-1">
          {props.companyMatches?.map((match, index) => (
            <div
              className="w-full"
              key={match.id}
              style={{
                transition: 'transform 0.5s ease',
                transform: `translateX(${animate ? 0 : '-100%'})`,
                transitionDelay: `${calculateDelay(index)}ms`,
              }}
            >
              <InboxCard key={match.id} match={match} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
