import React from 'react';
import { question } from '../../contexts/FinProductContext';
import TableQuestion from './Questions/TableQuestion';
import LongQuestion from './Questions/LongQuestion';
import DocumentUploadQuestion from './Questions/DocumentUploadQuestion';
import AskPartnerForUpload from './Questions/AskPartnerForUpload';
import StatusChangeButton from './Questions/StatusChangeButton';
import PrivateFormLinkActions from './Questions/PrivateFormLinkActions';
import Title from './Questions/Title';

interface Props {
  question: question;
  index: number;
}
export default function ApplicationQuestion(props: Props) {
  const { question, index } = props;

  // if of type table dont wrap in div for padding
  if (question.type === 'table' || question.type === 'editable_table') {
    return <TableQuestion question={question} />;
  }

  return (
    <div className={index === 0 ? '' : 'py-2'} key={question.id}>
      {question.type === 'long_question' ? (
        <LongQuestion question={question} />
      ) : question.type === 'upload' ? (
        <DocumentUploadQuestion question={question} />
      ) : question.type === 'partner_uploads' ? (
        <AskPartnerForUpload question={question} />
      ) : question.type === 'status_change_button' ? (
        <StatusChangeButton question={question} />
      ) : question.type === 'private_form_link' ? (
        <PrivateFormLinkActions question={question} />
      ) : question.type === 'title' ? (
        <Title question={question} />
      ) : question.type === 'warn_of_expiry' ||
        question.type == 'apply_with_ai' ? null : (
        <div>Please contact support.</div>
      )}
    </div>
  );
}
