import React from 'react';

interface Props {
  headline: string;

  background?: string;
  onClick?: () => void;
  icon?: string; // emoji
}

export default function SmallMenuCard(props: Props) {
  return (
    <button
      onClick={props.onClick}
      className="relative group bg-stone-100 hover:bg-white rounded-lg shadow transition-all duration-500 "
    >
      <div className=" flex justify-center items-center h-12">
        <div className="text-left w-full p-3">
          <h1 className="text-sm">
            {props.icon ? <span className="pr-2">{props.icon}</span> : null}
            {props.headline}
          </h1>
        </div>
      </div>
      {/* on hover show tooltip */}
    </button>
  );
}
