import React from 'react';
import { FinProductContext } from '../../../contexts/FinProductContext';
import ArrowIcon from '../../Icons/ArrowIcon';
import { demoSMEFunder } from '../../../data/demoSMEFunder';
import { ConfigContext } from '../../../contexts/ConfigContext';
import UploadZone from '../../utils/UploadZone';
import ViewApplicationUploads from './ViewApplicationUploads';

interface ActiveStatusTabButton {
  title: string;
  logo: string;
  onClick?: () => void;
}

export default function ActiveStatusTab() {
  const { config } = React.useContext(ConfigContext);
  const { selectedProductApplication } = React.useContext(FinProductContext);
  const [showCorrespondence, setShowCorrespondence] = React.useState(false);
  const [showUploads, setShowUploads] = React.useState(false);

  const buttons = [
    config?.features?.rightHandSideTab?.components?.correspondence?.visible && {
      title: 'View correspondence',
      logo: 'https://vula-assets.s3.eu-west-2.amazonaws.com/icons/email.svg',
      onClick: () => {
        setShowCorrespondence(true);
      },
    },
    config?.features?.rightHandSideTab?.components?.bookInitialMeeting
      .visible && {
      title: 'Book initial meeting',
      logo: 'https://vula-assets.s3.eu-west-2.amazonaws.com/icons/calendar-success.svg',
      onClick: () => {
        // book tab on gcal
        window.open(
          'https://calendar.google.com/calendar/u/0/r/eventedit?text=' +
            selectedProductApplication?.company.name +
            ' <> ' +
            selectedProductApplication?.owner.name +
            ': ' +
            selectedProductApplication?.name,
          '_blank',
        );
      },
    },
    config?.features?.rightHandSideTab?.components?.visitWebsite?.visible &&
      selectedProductApplication?.company.website && {
        title: 'Visit website',
        logo: 'https://vula-assets.s3.eu-west-2.amazonaws.com/icons/globe.svg',
        onClick: () => {
          // open new tab
          window.open(selectedProductApplication?.company.website, '_blank');
        },
      },
    config?.features?.rightHandSideTab?.components?.potentialCofounders
      ?.visible && {
      title: 'Potential co-funders',
      logo: 'https://vula-assets.s3.eu-west-2.amazonaws.com/icons/person.svg',
    },
    config?.features?.rightHandSideTab?.components?.viewUploads?.visible && {
      title: 'View uploads',
      logo: 'https://vula-assets.s3.eu-west-2.amazonaws.com/icons/files.svg',
      onClick: () => {
        setShowUploads(true);
      },
    },
  ].filter(Boolean) as ActiveStatusTabButton[];

  const renderButton = (button: ActiveStatusTabButton) => {
    return (
      <button
        key={button.title}
        className="hover:bg-stone-100 flex flex-row items-center px-4 w-full border border-solid border-stone-200 rounded-lg mb-1"
        onClick={() => button.onClick?.()}
      >
        <img
          src={button.logo}
          className=" rounded mr-2 object-contain"
          style={{ width: '25px', height: '25px' }}
        />
        <div className="text-left py-4">
          <div className="font-light text-sm">{button.title}</div>
        </div>
      </button>
    );
  };

  const renderCorrespondenceView = () => {
    const emails = [
      {
        title: demoSMEFunder.name + ': Follow up',
        content: 'Hello, I am following up on the application...',
        date: '2022-12-12',
        sender: 'Bongani',
        senderImage:
          'https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/Bongani.jpeg',
      },
      {
        title: demoSMEFunder.name + ': Automatic reminder',
        content: 'View this email in your browser...',
        date: '2022-12-12',
        sender: demoSMEFunder.name,
        senderImage: demoSMEFunder.logo,
      },
      {
        title: 'Application received',
        content: 'View this email in your browser...',
        date: '2022-12-12',
        sender: demoSMEFunder.name,
        senderImage: demoSMEFunder.logo,
      },
    ];
    return (
      <div className="text-left w-full h-full">
        <div className="flex flex-col pt-4">
          {/* a back button */}
          <button
            onClick={() => setShowCorrespondence(false)}
            className="flex flex-row items-center py-2 px-4 w-full border border-solid border-stone-200 rounded-lg"
          >
            <div className="font-light flex items-center text-sm">
              <ArrowIcon pointingDirection="left" />
              <p className="pl-4">Back</p>
            </div>
          </button>
          {emails.map(email => {
            return (
              <div className="flex flex-row items-center rounded-xl bg-white shadow p-2 my-2">
                <img
                  className="h-12 w-12 rounded-full mr-2"
                  src={email.senderImage}
                  alt={email.sender}
                />
                <div>
                  <p>{email.title}</p>
                  <p className="text-sm">{email.content}</p>
                </div>
              </div>
            );
          })}
        </div>
        <p className="text-xs scale-[0.8] text-stone-300">
          Powered by Microsoft Graph
        </p>
      </div>
    );
  };

  return (
    <div className=" grid grid-cols-1 gap-1  overflow-scroll no-scrollbar  p-4    ">
      {showCorrespondence ? (
        renderCorrespondenceView()
      ) : showUploads ? (
        <ViewApplicationUploads setShowUploads={setShowUploads} />
      ) : (
        <div className="flex flex-col justify-between h-[70vh]">
          <div>{buttons.map(button => renderButton(button))}</div>
          <div className="">
            <UploadZone
              label={'asddsa'}
              afterUpload={() => {
                console.log('uploaded');
              }}
              instructions="Upload data here at any time."
              width="w-auto"
            />
          </div>
        </div>
      )}
    </div>
  );
}
