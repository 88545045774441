import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';

import { init as initialiseMixpanelTracking } from './interfaces/mixpanel';

import { routes } from './pages/routes';
import LandingPage from './pages/LandingPage';
import Home from './pages/Home';
import Auth0ProviderWithRedirectCallback from './interfaces/auth0/Auth0WithRedirect';
import ProtectedRoute from './interfaces/auth0/ProtectedRoute';
import { OnLogin } from './interfaces/auth0/OnLogin';
import LoggedInProvider from './contexts/LoggedInContext';
import Product from './pages/Product';
import FinProductProvider from './contexts/FinProductContext';
import ApplicationsOverview from './pages/ApplicationsOverview';
import ApplicationsManagement from './pages/ApplicationsManagement';
import CreateProgrammeHome from './components/CreateProgramme/CreateProgrammeHome';
import MockProgrammeDash from './pages/MockProgrammeDash';
import MockICView from './pages/MockICView';
import MockDisbursement from './pages/MockDisbursement';
import { ConfigContextProvider } from './contexts/ConfigContext';
import Search from './pages/Search';

initialiseMixpanelTracking();

function App() {
  return (
    <Auth0ProviderWithRedirectCallback>
      <LoggedInProvider>
        <ConfigContextProvider>
          <div className="App h-full min-h-[100vh] w-full min-w-[100vw] bg-stone-50 text-stone-900">
            <Routes>
              <Route path={routes.landing} element={<LandingPage />} />
              <Route path={routes.onlogin} element={<OnLogin />} />
              <Route
                path={routes.home}
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.product}
                element={
                  <ProtectedRoute>
                    <FinProductProvider>
                      <Product />
                    </FinProductProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.search}
                element={
                  <ProtectedRoute>
                    <Search />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.create_new_programme}
                element={
                  <ProtectedRoute>
                    <CreateProgrammeHome />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.application_overview}
                element={<ApplicationsOverview />}
              />
              <Route
                path={routes.application_management}
                element={<ApplicationsManagement />}
              />
              <Route
                path={routes.mock_programme_dashboard}
                element={<MockProgrammeDash />}
              />
              <Route path={routes.mock_ic_view} element={<MockICView />} />
              <Route
                path={routes.mock_disbursement_view}
                element={<MockDisbursement />}
              />
            </Routes>
          </div>
        </ConfigContextProvider>
      </LoggedInProvider>
    </Auth0ProviderWithRedirectCallback>
  );
}

export default App;
