import React from 'react';
import { question } from '../../../contexts/FinProductContext';
import { MarkdownText } from '../../utils/MarkdownText';

interface Props {
  question: question;
}

export default function Title({ question }: Props) {
  return (
    <div className="pt-4 ">
      <div className=" text-gray-800 border border-x-0 border-b-0 border-t-1 border-stone-200 border-solid p-4">
        <MarkdownText content={question.question} />
      </div>
    </div>
  );
}
