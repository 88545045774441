//

import React from 'react';
import NewTabIcon from '../Icons/NewTabIcon';

interface ExpandableDetailProps {
  title: string;
  cards: ExpandableDetailCardsProps[];
}

interface ExpandableDetailCardsProps {
  title: string;
  body?: string;
  img?: string;
  url?: string;
  date?: string;
}

export default function ExpandableDetail(props: ExpandableDetailProps) {
  const { title, cards } = props;
  const [expanded, setExpanded] = React.useState(true);
  if (cards.length === 0) return null;
  return (
    <div className="rounded-xl bg-stone-50 text-stone-800 my-2 overflow-hidden">
      <div
        className="p-2 px-4 bg-stone-100"
        onClick={() => setExpanded(!expanded)}
      >
        <b>{title}</b>
      </div>
      {expanded && (
        <div className="border border-b-0 border-x-0 border-t-1 border-solid border-stone-200"></div>
      )}
      <div className={'' + (expanded ? ' h-120 px-2 pb-2 ' : ' h-0 ')}>
        {!expanded
          ? null
          : cards.map((card, index) => (
              <div
                key={index}
                className="mt-2 flex justify-between items-center flex-row space-x-2 rounded-xl bg-white hover:bg-stone-100 shadow p-2 "
              >
                {card.img && (
                  <img src={card.img} alt={card.title} className="w-20 h-20" />
                )}
                <div className="flex flex-col">
                  {card.url ? (
                    <a
                      href={card.url}
                      className=""
                      target="_blank"
                      rel="noreferrer"
                    >
                      <b>{card.title}</b>
                    </a>
                  ) : (
                    <div>
                      <b>{card.title}</b>
                    </div>
                  )}
                  {card.body ? (
                    <div className="text-sm pt-2">
                      {
                        // parse the body for new lines
                        card.body.split('\n').map((item, key) => {
                          return (
                            <span key={key}>
                              {item}
                              <br />
                            </span>
                          );
                        })
                      }
                    </div>
                  ) : null}
                  {card.date ? (
                    <div
                      className=" text-xs text-stone-400 text-right 
                  "
                    >
                      {card.date}
                    </div>
                  ) : null}
                </div>
                {card.url ? (
                  <a
                    className="pr-2"
                    href={card.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <NewTabIcon />
                  </a>
                ) : null}
              </div>
            ))}
      </div>
    </div>
  );
}
