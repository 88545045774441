import React, { useContext } from 'react';
import QuestionsSection from './QuestionCards/QuestionsSection';
import GridBackground from '../../utils/GridBackground';
import { ApplicationQuestionsContext } from './ApplicationQuestionsContext';
import QuestionItem from './QuestionCards/QuestionItem';

export default function ApplicationQuestionsHome() {
  // get questions from context
  const ApplicationQuestionsContextContent = useContext(
    ApplicationQuestionsContext,
  );

  return (
    <GridBackground fullHeight={true}>
      <div className="p-2 text-left max-w-[800px] m-auto overflow-scroll h-[100vh]">
        <div className="m-2 bg-stone-50 shadow-xl p-4 rounded-xl">
          <div className="text-3xl">Application Questions</div>
          <div className="mt-4">
            Add the questions that will be asked in the application form.
          </div>
        </div>
        {
          // render the sections and their questions inside from context
          ApplicationQuestionsContextContent?.sections.map(section => (
            <QuestionsSection
              title={section.title}
              subtitle={section.subtitle}
              key={section.title}
            >
              {section.questions.map(question => (
                <>
                  <QuestionItem
                    type={question.type}
                    placeholder={question.placeholder}
                    initialValue={question.initialValue}
                    key={question.placeholder}
                  />
                  {/* border line */}
                  <div className="border border-t-1 border-x-0 border-b-0 border-stone-200 border-solid border-1 my-2"></div>
                </>
              ))}
            </QuestionsSection>
          ))
        }

        {/* Add new section button */}
        <div className="flex justify-center mt-4">
          <button className="bg-stone-50 p-2 px-4 rounded-lg shadow-lg hover:shadow-xl">
            Add new section
          </button>
        </div>
      </div>
    </GridBackground>
  );
}
