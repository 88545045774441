import React from 'react';
import Button from '../utils/Button';
import InProgressCard from '../Home/InProgressCard';
import UploadZone from '../utils/UploadZone';
import Spinner from '../utils/Spinner';

interface Props {
  nextStep: () => void;
}
export default function InitialData(props: Props) {
  const [showOptions, setShowOptions] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const mockCalculatingTime = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      props.nextStep();
    }, 2000);
  };

  return (
    <div className="p-4  text-left ">
      <div className="mb-12 ">
        <div className="p-4">
          <h1 className="text-5xl font-bold ">Create a new programme</h1>
          <div className="text-left text-lg pt-4 max-w-[500px]">
            Either give Vula an idea of what you want and let it draft the
            sections for you, copy another programme and make changes, or start
            from scratch.
          </div>
        </div>

        {showOptions ? (
          <>
            <div className="max-w-[900px] pt-6 ">
              <InProgressCard
                headline="Generate a new programme with Vula"
                subtitle="Give Vula details and docs to draft a programme for you"
                onClick={() => {
                  setShowOptions(false);
                }}
                background="https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/BP_add_new_prod.jpeg"
              />
            </div>
            <div className="max-w-[900px] grid grid-cols-2 gap-2 w-full ">
              <InProgressCard
                headline="Duplicate from existing"
                subtitle="Copy an existing programme and make changes manually"
                onClick={() => {
                  mockCalculatingTime();
                }}
                background="https://vula-assets.s3.eu-west-2.amazonaws.com/temp_demo/BP_applications_overview.jpeg"
              />
              <InProgressCard
                headline="Start from scratch"
                subtitle="Create a new programme from scratch without Vula's help"
                onClick={() => {
                  mockCalculatingTime();
                }}
                background=""
              />
            </div>
          </>
        ) : (
          <div className="p-4">
            <div className="mt-4">
              1. Select a previous programme as a reference
            </div>
            <select className="w-full mt-4 bg-stone-100 rounded-xl px-4  py-2 text-stone-900">
              <option value="0">Select a previous programme</option>
              <option value="1">Programme 1</option>
              <option value="2">Programme 2</option>
              <option value="3">Programme 3</option>
            </select>

            {/* write a blurb about the programme and its intention */}
            <div className="mt-4">
              2. Write a blurb about the programme and its intention
            </div>
            <textarea
              className="
        mt-4 w-full bg-stone-100 rounded-xl px-4 py-2 text-stone-900
        "
              placeholder="What is this programme about? How is it different to previous programmes?"
            ></textarea>

            {/* Upload docs */}
            <div className="mt-4">3. Upload any supporting documents</div>
            <UploadZone label="" afterUpload={() => console.log('upload')} />

            {/* Generate button */}
            <div className="mt-4 ">
              {loading ? (
                <Spinner />
              ) : (
                <Button
                  text="Generate Programme"
                  action={mockCalculatingTime}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
