import React from 'react';
import CheckIcon from '../Icons/CheckIcon';
import IncompletedCircle from '../Icons/IncompletedCircle';

interface Props {
  headline: string;
  subtitle: string;
  background?: string;
  progress?: Progress[];
  description?: React.ReactNode;
  onClick?: () => void;
  specialButton?: React.ReactNode;
}

interface Progress {
  step: string;
  status: 'completed' | 'in-progress' | 'todo';
}

export default function InProgressCard(props: Props) {
  const wideMode = props.progress ? true : props.description ? true : false;
  const background = props.background
    ? {
        background: 'url(' + props.background + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'gray',
        backgroundBlendMode: 'multiply',
        filter: 'blur(1px)',
      }
    : { backgroundColor: 'black' };

  return (
    <div
      className={
        'overflow-hidden relative overflow-hidden  rounded-2xl bg-white border border-solid border-1 border-gray-200 h-[100px] sm:h-[175px] flex  max-w-[1200px] w-full' +
        (wideMode ? ' col-span-3' : '')
      }
      onClick={props.onClick}
    >
      {props.specialButton ? (
        <div className="hidden sm:block absolute top-0 right-0">
          {props.specialButton}
        </div>
      ) : null}
      <button
        className={
          'hover:shadow-xl hover:scale-[1.02] transition-all duration-300 max-w-[1200px] w-full ' +
          (wideMode ? ' flex flex-row' : '')
        }
      >
        <div
          className={
            'h-full flex justify-center items-center object-fit' +
            (wideMode
              ? ' w-full sm:w-1/2 rounded-l-2xl'
              : ' w-full rounded-2xl')
          }
          style={background}
        ></div>
        <div
          className={
            'absolute top-0 px-12 h-full flex flex-col justify-center items-center' +
            (wideMode ? ' w-full sm:w-1/2' : ' w-full')
          }
        >
          <div className="text-2xl sm:text-3xl font-extrabold text-white">
            {props.headline}
          </div>
          <div className="  font-extrabold text-white">{props.subtitle}</div>
        </div>

        {wideMode ? (
          <div className="hidden sm:block w-1/2 text-left p-4">
            {props.progress ? (
              <ul>
                {props.progress.map(prog => {
                  if (prog.status === 'completed') {
                    return (
                      <li
                        key={prog.step}
                        className="hidden sm:flex flex flex-row"
                      >
                        <CheckIcon />
                        <div className="px-2	">{prog.step}</div>
                      </li>
                    );
                  } else {
                    return (
                      <li key={prog.step} className="flex flex-row">
                        <IncompletedCircle />
                        <span className="px-2">{prog.step}</span>
                      </li>
                    );
                  }
                })}
              </ul>
            ) : props.description ? (
              props.description
            ) : null}
          </div>
        ) : null}
      </button>
    </div>
  );
}
