import React from 'react';
import { question } from '../../../contexts/FinProductContext';
import UploadMultiZone from '../../utils/UploadMultiZone';
import { MarkdownText } from '../../utils/MarkdownText';

interface Props {
  question: question;
}
export default function AskPartnerForUpload(props: Props) {
  return (
    <div>
      {/* show title from question */}
      <div className="pt-2 pl-2">
        <MarkdownText content={props.question.question} />
      </div>
      {/* show description from question */}
      <UploadMultiZone
        label={props.question.corpus_label}
        fileTypes={['pdf']}
        alreadyUploadedFiles={
          props.question.answer ? JSON.parse(props.question.answer) : []
        }
      />
    </div>
  );
}
