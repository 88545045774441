import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { VulaChatAPI } from '../../../api/chat';
import { FinProductContext } from '../../../contexts/FinProductContext';
import Spinner from '../../utils/Spinner';

export default function Reject() {
  const { getAccessTokenSilently } = useAuth0();
  const { selectedProductApplication } = React.useContext(FinProductContext);
  const userInputRef = React.useRef<HTMLTextAreaElement>(null);
  const assistedFeedbackRef = React.useRef<HTMLTextAreaElement>(null);

  const [isGenerating, setIsGenerating] = React.useState(false);
  const [assistedFeedback, setAssistedFeedback] = React.useState<string>('');
  const [isSent, setIsSent] = React.useState(false);

  const generateFriendlyFeedback = async () => {
    setIsGenerating(true);
    const company_id = selectedProductApplication?.company.id;
    const partner_id = selectedProductApplication?.owner.id;
    if (userInputRef.current?.value.length && company_id && partner_id) {
      const token = await getAccessTokenSilently();
      const api = new VulaChatAPI({ token });

      const response = await api.askVulaChat(
        company_id,
        partner_id,
        "Please rewrite the reasons for rejection below for the customer to see - the intention is to be advice to help them in the future. Be extremely friendly, encouraging and helpful so they will benefit and try to avoid using the word 'rejected'. Here is the raw feedback that you should rewrite: " +
          userInputRef.current?.value,
      );

      const assistedFeedback = response.data?.answer;

      setAssistedFeedback(assistedFeedback);
    }
    setIsGenerating(false);
  };

  if (isSent) {
    return (
      <div className=" ">
        <div className="text-lg font-bold">Feedback Sent</div>
        <div className="flex items-center">
          <div className="py-2 ">
            Your records have been updated and the customer has been notified.
            Thank you for giving feedback - it is very valuable for enabling the
            SME ecosystem.
          </div>
          <img
            src="https://vula-assets.s3.eu-west-2.amazonaws.com/icons/sent.svg"
            className="w-32 h-32"
          />
        </div>
      </div>
    );
  }

  return (
    <div className=" ">
      <div className="text-lg font-bold">Rejection records</div>
      <div className="text-sm ">
        Please add the reasons why you are rejecting the application below and
        any additional notes you would like to add. This will never be shown to
        the customer.
      </div>
      <div className="text-sm ">
        <textarea
          ref={userInputRef}
          className="border border-solid border-1 border-stone-200 rounded p-2 text-left w-full"
        />
      </div>
      <div className="text-sm pt-4">
        Vula AI will rewrite the reasons for rejection below for the customer to
        see - the intention is to be advice to help them in the future.
      </div>
      {assistedFeedback.length ? (
        <>
          <div className="text-sm ">
            <textarea
              ref={assistedFeedbackRef}
              className="h-full border border-solid border-1 border-stone-200 rounded p-2 text-left w-full"
              // make sure state is updated when the user edits or when the api returns
              onChange={e => setAssistedFeedback(e.target.value)}
              value={assistedFeedback}
            />
          </div>

          <div className="flex flex-row justify-end pt-4 text-center">
            <button
              onClick={() => {
                setIsSent(true);
              }}
              className="bg-white border border-solid border-1 border-stone-200 rounded p-2 text-left w-32"
            >
              Send
            </button>
          </div>
        </>
      ) : (
        <div className="flex flex-row justify-start pt-4 text-center">
          {isGenerating ? (
            <Spinner />
          ) : (
            <button
              onClick={generateFriendlyFeedback}
              className="bg-white border border-solid border-1 border-stone-200 rounded p-2 text-left w-32 mr-4"
            >
              Generate
            </button>
          )}
        </div>
      )}
    </div>
  );
}
