import React, { useState } from 'react';

interface MultiSelectInputProps {
  placeholder?: string;
  initialValue?: string;
}

export const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
  placeholder,
  initialValue,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [options, setOptions] = useState<string[]>(
    placeholder?.split(',') || [],
  );
  const [newOption, setNewOption] = useState('');

  const handleAddOption = () => {
    if (newOption.trim() !== '') {
      setOptions([...options, newOption.trim()]);
      setNewOption('');
    }
  };

  const handleRemoveOption = (index: number) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  if (editMode) {
    return (
      <div onClick={() => setEditMode(true)}>
        <div className="relative pt-4">
          <label className="absolute top-2 right-8 bg-stone-50 w-[80px] text-center text-xs font-bold">
            Question
          </label>
          <input
            id="question"
            type="text"
            placeholder="Enter your question here"
            defaultValue="Choose your options"
            className="w-full p-2 pt-3 px-4 border border-solid border-1 font-bold border-stone-200 rounded-lg"
          />
        </div>
        <div className="relative pt-4">
          <label className="absolute top-2 right-8 bg-stone-50 w-[80px] text-center text-xs font-bold">
            Options
          </label>
          <div className="mt-2 w-full bg-stone-100 p-2 rounded-xl">
            {options.map((option, index) => (
              <div key={index} className="flex  items-center mb-2">
                <button
                  className="text-red-500"
                  onClick={() => handleRemoveOption(index)}
                >
                  🗑️
                </button>
                <span className="pl-2">{option}</span>
                <div className="border border-t-1 border-x-0 border-b-0 border-stone-400 border-solid border-1 my-2"></div>
              </div>
            ))}
            <div className="flex">
              <input
                id="new-option"
                type="text"
                placeholder="Add a new option"
                value={newOption}
                onChange={e => setNewOption(e.target.value)}
                className="w-full p-2 border border-solid border-1 border-stone-200 rounded-lg"
              />
              <button
                className="ml-2 bg-stone-500 text-white rounded-lg px-4 py-2"
                onClick={handleAddOption}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div onClick={() => setEditMode(true)}>
      <div className="relative">
        <div className="font-bold pl-2 py-2">{initialValue}</div>
        <div className="w-full p-2 border border-solid border-1 border-stone-200 rounded-lg">
          {options.join(', ')}
        </div>
      </div>
    </div>
  );
};
