import React, { useContext } from 'react';
import { getCountryEmojiFromName } from '../../data/countries';

interface Props {
  parameter: {
    type: string;
    text: string;
  };
  removeParam?: (param: { type: string; text: string }) => void;
}

export default function ParamPill(props: Props) {
  const { text } = props.parameter;
  if (!props.parameter) {
    return null;
  }
  return (
    <div className="min-w-fit h-fit m-1 flex flex-row items-center border border-solid border-2 border-gray-300 hover:bg-gray-200 p-1 rounded-full">
      {/* if geography type show the emoji associated in the data file countries.ts*/}
      {props.parameter.type === 'geography' ? (
        <div className="pl-2">
          {getCountryEmojiFromName(props.parameter.text)}
          <span className="px-2">{props.parameter.text}</span>
        </div>
      ) : (
        <div className="px-2">{text}</div>
      )}

      <button
        onClick={async () => {
          if (props.removeParam) {
            props.removeParam(props.parameter);
          }
        }}
        className=" w-[25px] h-[25px] square rounded-full hover:bg-red-300 hover:text-white text-center"
      >
        &#215;
      </button>
    </div>
  );
}
