import React from 'react';
import Chevron from '../Icons/Chevron';
import SocialMediaIcons from './SocialMediaIcons';
import ExpandableDetail from './ExpandableDetail';
import { sf_data, CompanyData } from '../../data/sf';
import DetailWindowFlag from './DetailWindowFlag';
import AlertIcon from '../Icons/AlertIcon';

interface Props {
  selectedRowId: number | undefined;
  closeModal: () => void;
}
export default function DetailWindow(props: Props) {
  const { selectedRowId } = props;
  const [selectedRow, setSelectedRow] = React.useState<CompanyData | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (selectedRowId) {
      //  flatten all children from their parents and search for the id
      const allData = sf_data as CompanyData[];
      const flattenedData = allData.reduce((acc: CompanyData[], item) => {
        acc.push(item);
        if (item.children) {
          acc.push(...item.children);
        }
        return acc;
      }, [] as CompanyData[]);
      const selected = flattenedData.find(item => item.id === selectedRowId);
      setSelectedRow(selected);
    }
  }, [selectedRowId]);

  return (
    <div className="py-2 pr-4  w-1/3 min-h-full ">
      <div className=" h-full  rounded-3xl border-solid border-stone-200 text-white text-left shadow bg-center bg-[url(https://vula-assets.s3.eu-west-2.amazonaws.com/hopeful.webp)]">
        <div className="animate fade-in animate-fade-in">
          {/* header */}

          <div className="p-4 flex justify-between items-center">
            <button
              className="hover:bg-stone-600 transition-all duration-500 rounded-lg p-1 w-fit"
              onClick={props.closeModal}
            >
              <Chevron color="white" />
            </button>
            <DetailWindowFlag selectedRow={selectedRow} />
          </div>

          {selectedRow ? ( // check the type and render all fields of type
            // must be child
            <div
              className="px-4 overflow-y-scroll"
              style={{
                height: window.innerHeight - 200,
              }}
            >
              {/* logo and overview */}
              <div className="flex flex-1 space-x-4 justify-start  p-2  text-stone-100 rounded-lg ">
                <div className=" rounded-lg flex space-y-8 flex-1 flex-col justify-between h-full">
                  <div className="text-xs px-3 py-1 rounded-full bg-stone-700 text-white w-fit mt-1 oneline whitespace-nowrap ">
                    {selectedRow.type}
                  </div>
                  <img
                    src={selectedRow.logoURL}
                    className="drop-shadow-xl  rounded-lg overflow-hidden "
                    alt={`${selectedRow.name} logo`}
                    style={{
                      // add an outline using filter
                      maxHeight: '120px',
                      maxWidth: '180px',
                      objectFit: 'contain',
                    }}
                    onError={e => {
                      // If image fails to load, replace with the name of the organization
                      e.currentTarget.src = `https://via.placeholder.com/80x40.png?text=${selectedRow.name}`;
                    }}
                  />
                </div>

                <div className="text-left rounded-xl bg-stone-100 shadow-lg w-full p-4 text-stone-800">
                  <h1 className="text-xl font-bold pt-12">
                    {selectedRow.name}
                  </h1>

                  <SocialMediaIcons
                    name={selectedRow.name}
                    linkedIn={selectedRow.linkedinURL}
                    website={selectedRow.website}
                    onepageId={selectedRow.onepageId}
                    crunchbaseURL={selectedRow.crunchbaseURL}
                  />
                  <div className="text-sm">HQ: {selectedRow.hq}</div>
                  <div className="text-sm">
                    {selectedRow?.relationship
                      ? 'Relationship: ' + selectedRow.relationship
                      : ''}
                  </div>
                </div>
              </div>
              {selectedRow.description ? (
                <div className="text-sm p-4 rounded-lg bg-stone-600 bg-opacity-50">
                  {selectedRow.description}
                </div>
              ) : null}

              {/* if an error show a little warning */}
              {selectedRow.error ? (
                <div className="text-sm p-4 my-4 rounded-lg bg-stone-600 bg-opacity-50 flex items-center ">
                  <AlertIcon color="white" />{' '}
                  <div className="pl-4">{selectedRow.error}</div>
                </div>
              ) : null}

              {/* impact areas */}
              <ExpandableDetail
                title="Impact Areas"
                cards={selectedRow.impactAreas.map(impact => ({
                  title: impact,
                }))}
              />
              <ExpandableDetail
                title="Countries of Operation"
                cards={selectedRow.countriesOfOperation.map(c => ({
                  title: c,
                }))}
              />
              {selectedRow?.verticals?.length ? (
                <ExpandableDetail
                  title="Verticals"
                  cards={selectedRow.verticals.split(',').map(c => ({
                    title: c,
                  }))}
                />
              ) : null}

              {/* deals */}
              <ExpandableDetail
                title="Deals"
                cards={selectedRow.deals.map(deal => ({
                  title: deal.name + ' : ' + deal.status,
                  body: deal?.dealSummary || undefined,
                  url:
                    'https://app.onepagecrm.com/pipeline/kanban/deals/' +
                    deal.dealId,
                }))}
              />
              {/* attachments */}
              <ExpandableDetail
                title="Attachments"
                cards={selectedRow.attachments.map(attachment => ({
                  title: attachment.filename,
                  url: attachment.url,
                }))}
              />
              {/* NOTES */}
              <ExpandableDetail
                title="Notes"
                cards={selectedRow.notes.map(note => ({
                  title: note.author,
                  body: note.text,
                  date: note.date,
                }))}
              />
              <ExpandableDetail
                title="Contacts"
                cards={selectedRow.contacts.map(contact => ({
                  title: contact.name,
                  body: contact.jobTitle + ' : ' + contact.linkedinURL,
                  url: contact.linkedinURL
                    ? contact.linkedinURL
                    : 'https://app.onepagecrm.com/show_contact/' +
                      contact.contactId,
                }))}
              />

              {/* children */}
              {selectedRow.children && selectedRow.children.length ? (
                <ExpandableDetail
                  title="Related Companies"
                  cards={selectedRow.children.map(child => ({
                    title: child.name,
                    body: child.description,
                    img: child.logoURL,
                  }))}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
